import { Column, Container, Img, Row, RichText } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import PostcodeContactCard from "@molecules/postcodeContactCard"
import { IPostcodeContactCardProps } from "@molecules/postcodeContactCard/_postcodeContactCard.interface"
import PostcodeSearch from "@molecules/postcodeSearch"
import CarouselWrapper from "@organisms/carouselWrapper"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { getApi } from "@utils/baseApi"
import { getBlockData } from "@helpers/dataFunctions"
import { IPostcodeSearchFunctionProps } from "./_postcodeSearchFunction.interface"
import { PostCodeParams } from "@molecules/postcodeSearch/_postcodeSearch.interface"
import NUMBERS from "@helpers/constants/numbers"

const PostcodeSearchFunction = ({ url }: IPostcodeSearchFunctionProps) => {
  const [searchResult, setSearchResult] = useState<IPostcodeContactCardProps[]>([])
  const [searchTriggered, setSearchTriggered] = useState<boolean>(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>(null)
  const [defaultPostcodeTypography, setDefaultPostcodeTypography] = useState<any>({})
  const [postCodeParams, setPostCodeParams] = useState<PostCodeParams>({
    lookupTargetID: null,
    specialityTargetID: null,
  })
  const [postCodeText, setPostCodeText] = useState<string>("")
  const [errorValidation, setErrorValidation] = useState<boolean>(false)

  const applicationConfiguration = useContext(ApplicationContext)
  const { postcodeSearchData } = applicationConfiguration.applicationConfiguration

  const isMobile = useMediaQuery("(max-width: 767px)")

  useLayoutEffect(() => {
    const fetchParamsForPostCodeDetails = async () => {
      const detailsResponse = await getBlockData(url as any)
      const lookupTargetID =
        detailsResponse?.data?.relationships?.field_lookup_type?.data?.meta
          ?.drupal_internal__target_id
      const specialityTargetID =
        detailsResponse?.data?.relationships?.field_speciality?.data?.meta
          ?.drupal_internal__target_id
      setPostCodeParams({
        lookupTargetID,
        specialityTargetID,
      })
    }

    if (url) {
      fetchParamsForPostCodeDetails()
    }
  }, [url])

  useEffect(() => {
    const renderContactImage = async () => {
      const contactImageData = postcodeSearchData
      setDefaultPostcodeTypography({
        postcodesearch_image_src:
          contactImageData?.postcodesearch_default_placeholder_image_url ?? null,
        postcodesearch_image_alt: contactImageData?.postcode_image_alt ?? null,
      })
    }

    if (postcodeSearchData) {
      renderContactImage()
    }
  }, [postcodeSearchData])

  const handleTextSearch = async (text: string) => {
    const regex = /^[0-9]+$/;
    try {
      if (postCodeText === text) {
        return
      } else if((text.length != NUMBERS.FIVE) || !regex.test(text)){
        setErrorValidation(true);
        setPostCodeText(text);
        setSearchResult([]);
        return
      }
      setErrorValidation(false)
      setPostCodeText(text)
      const { lookupTargetID } = postCodeParams
      const { specialityTargetID } = postCodeParams
      
      let postcodeDetailUrl = `/restapi/postcode-details/${text}/${lookupTargetID}/`
      if (specialityTargetID) {
        postcodeDetailUrl += `${specialityTargetID}`
      } else {
        postcodeDetailUrl += "null"
      }

      const response = await getApi(postcodeDetailUrl)

      const detailedData = response?.detailed_data || []
      setSearchResult(detailedData)
      setSearchTriggered(true)
    } catch (error) {
      setSearchTriggered(true)
      console.error("Error fetching data from API:", error)
      setSearchResult([])
    }
  }

  const formatText = (item: any) => {
    let label = ""
    if (item?.city) {
      label = item.city
    }
    if (item?.country) {
      label = label.length ? `${label}, ${item.country}` : item.country
    }
    if (item?.address) {
      label = label.length ? `${label}, ${item.address}` : item.address
    }
    return label
  }

const content: JSX.Element | null = useMemo(() => {
  if (searchResult.length > 0) {
    let updatedSearchResult = [...searchResult]
    if (selectedSuggestion) {
      const matchingCityIndex = updatedSearchResult.findIndex(
        (item) => item.city === selectedSuggestion.city,
      )
      if (matchingCityIndex !== -1) {
        const matchingCityItem = updatedSearchResult.splice(matchingCityIndex, 1)[0] 
        updatedSearchResult.unshift(matchingCityItem) 
      }
    }
    return (
      <CarouselWrapper slideToShow={1} slideToShowTablet={1}>
        {updatedSearchResult.map((item: any, index: number) => (
          <PostcodeContactCard
            key={index}
            display_name={item?.display_name}
            phone_number={item?.phone_number}
            email={item?.email}
            address={formatText(item)}
            image={{
              src: item?.image_url,
              alt: item?.image_alt,
            }}
          />
        ))}
      </CarouselWrapper>
    )
  } else {
    return (
      <div className="postcode-search-function-image">
        {errorValidation && <h4 className="postcode-contact-card-error">
          <RichText content={applicationConfiguration?.applicationConfiguration?.siteConfig?.postcodesearch_error_msg?.value} />
        </h4>}
        <Img
          type="default"
          layout="intrinsic"
          alt={defaultPostcodeTypography?.postcodesearch_image_alt}
          src={defaultPostcodeTypography?.postcodesearch_image_src}
          width={702}
          height={336}
        />
      </div>
    )
  }
}, [searchResult, defaultPostcodeTypography, selectedSuggestion, errorValidation])

  return (
    <Container fluid={isMobile || false}>
      <Row className="postcode-search-function">
        <Column desktop={6} tablet={12} mobile={12} className="postcode-search-function-component">
          <PostcodeSearch
            postCodeDefaultValues={postCodeParams}
            handleTextSearch={handleTextSearch}
            onSelectSuggestion={setSelectedSuggestion}
            {...postcodeSearchData}
          />
        </Column>
        <Column desktop={6} tablet={12} mobile={12}>
          {content}
        </Column>
      </Row>
    </Container>
  )
}

export default PostcodeSearchFunction
