import { Button, Column, Container, Icon, Img, Taxonomy } from "@atoms"
import { useCallback } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { changeTab } from "store/actions/AcademyCourseActionCreators"
import { ICustomBanner } from "./_custom-banner.interface"

interface DispatchProps {
  changeTab: (data: any) => void
}

type StateTypes = {
  academyCourseFilterData: {
    tabChanged: boolean
  }
}

type Props = DispatchProps & StateTypes & ICustomBanner

const CustomBanner = (props: Props) => {
  const {
    customBannerImage,
    taxonomies,
    iconValue,
    customBannerTitle,
    ctaLabel,
    ctaLink,
    credit,
    alt,
    height,
    width,
    changeTab,
  } = props
  const user: any = useSelector((state: UserState) => state.user)
  const tabToggleChange: any = useSelector((state: any) => state?.academyCourseReducer)

  const dispatch: Dispatch<any> = useDispatch()
  const changeTabState: any = useCallback((value: boolean) => changeTab(value), [dispatch])
  
  const handleClick = () => {
    changeTabState({ tabChanged: true })
  }
  return (
    <div className={"customBanner"}>
      <div className="customBanner-wrapper">
        <div className="customBanner-image">
          <Img alt={alt} src={customBannerImage} type="hero_banner" width={width} height={height} />
        </div>

        <Container className="customBannerContent">
          <Column mobile={12} tablet={7} desktop={7} className="customBannerContent-col">
            <div className="customBannerContent-taxonomy">
              {iconValue && (
                <div className="customBannerContent-taxonomy-icon">
                  <Icon iconName={iconValue} />
                </div>
              )}
              {credit && <Taxonomy taxonomies={credit} />}
              <Taxonomy taxonomies={taxonomies} />
            </div>

            {customBannerTitle && (
              <h1 className="customBannerContent-title">{customBannerTitle}</h1>
            )}
            {user && user.isLoggedIn && ctaLabel && (
              <Button
                tabindex={0}
                isSecondary
                onClick={()=>handleClick()}
                className={`${
                  tabToggleChange?.tabSelected || tabToggleChange?.tabChanged || tabToggleChange?.btnClickTabChanged
                    ? "customBannerContent-cta"
                    : ""
                }`}>
                {ctaLabel}
              </Button>
            )}
          </Column>
        </Container>
      </div>
    </div>
  )
}

const mapDispatch = {
  changeTab: (data: any) => changeTab(data),
}

const mapState = (state: any) => ({
  academyCourseFilterData: state.academyCourseReducer,
})
export default connect(mapState, mapDispatch)(CustomBanner)
