import * as actionTypes from "../ActionTypes"

export const addProductFilter = (productFilter: any) => {
  const action: ProductFilterAction = {
    type: actionTypes.ADD_PRODUCT_FILTER,
    productFilter,
  }
  return performRequest(action)
}

export const setSearchTextVal = (searchText: string) => {
  const action: ProductFilterAction = {
    type: actionTypes.SET_SEARCH_TEXT,
    searchText,
  }

  return performRequest(action)
}

export const setOrderVal = (order: string) => {
  const action: ProductFilterAction = {
    type: actionTypes.SET_ORDER,
    order,
  }

  return performRequest(action)
}

export const setCurrentPage = (currentPage: number) => {
  const action: ProductFilterAction = {
    type: actionTypes.SET_PAGE_NUMBER,
    currentPage,
  }

  return performRequest(action)
}

const performRequest = (action: ProductFilterAction) => {
  return (dispatch: ProductFilterDispatchType) => {
    dispatch(action)
  }
}
