import React, { useEffect, useState } from "react"
import { SearchGroup, TitleDescription } from "@molecules"
import { Container, DatePicker, Icon } from "@atoms"
import moment from "moment"
import NewsSearchResult from "@organisms/newsSearchResult"
import NUMBERS from "@helpers/constants/numbers"
import getNewsSearchData from "@helpers/dataFunctions/getNewsSearchData"
import { DATE_FORMATS, DEFAULT_MIN_DATE } from "@helpers/constants/generic"
import Loader from "@atoms/loader"
import {
  IGroupedResults,
  INewsCardInfo,
  INewsFilter,
  INewsSearchConfig,
} from "./_newsFilterSearch.interface"
import { useRouter } from "next/router"

const NewsFilterSearch = ({ url }: INewsFilter) => {
  const [itemsPerPage, setItemsPerPage] = useState(0)
  const [newsSearchConfig, setNewsSearchConfig] = useState<INewsSearchConfig>()

  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [textSearch, setTextSearch] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [searchCount, setSearchCount] = useState(NUMBERS.ZERO)

  const [allNews, setAllNews] = useState<INewsCardInfo[]>()
  const [groupedNews, setGroupedNews] = useState<IGroupedResults[]>()
  const [prevSelectedDate, setPrevSelectedDate] = useState({ to: toDate, from: fromDate })
  const router = useRouter()
  let alias = router.asPath.replace("/", "")
  
  if(alias.indexOf("?") != -1) {
    alias = alias.split("?")[0]
  }
  useEffect(() => {
    const getIntialNewsData = async () => {
      // Only Executed on Load
      setIsLoading(true)
      const { newsCardData, newsSearchConfig, itemsPerPage, groupedResult } =
        await getNewsSearchData(`${url}?page=${alias}`)
      setAllNews(newsCardData)
      setGroupedNews(groupedResult)
      setNewsSearchConfig(newsSearchConfig)
      setItemsPerPage(itemsPerPage)
      setIsLoading(false)
    }

    getIntialNewsData()
  }, [url])

  const fetchSearchResults = async (searchText: string, minDate: string, maxDate: string) => {
    let url = "/views/news_search/news_search"
    const searchParams = []
    if (searchText) {
      searchParams.push(`views-filter[search-news]=${searchText}`)
    }
    if (minDate) {
      searchParams.push(
        `views-filter[field_news_published_date][min]=${moment(minDate)
          .locale("en")
          .format(DATE_FORMATS.YYYY_MM_DD)} 00:00:00`,
      )
      if (!maxDate) {
        searchParams.push(
          `views-filter[field_news_published_date][max]=${moment()
            .locale("en")
            .format(DATE_FORMATS.YYYY_MM_DD)} 23:59:59`,
        )
      }
    }
    if (maxDate) {
      if (!minDate) {
        searchParams.push(
          `views-filter[field_news_published_date][min]=${moment(DEFAULT_MIN_DATE)
            .locale("en")
            .format(DATE_FORMATS.YYYY_MM_DD)} 00:00:00`,
        )
      }
      searchParams.push(
        `views-filter[field_news_published_date][max]=${moment(maxDate)
          .locale("en")
          .format(DATE_FORMATS.YYYY_MM_DD)} 23:59:59`,
      )
    }
    if (searchParams.length) {
      url = `${url}?${searchParams.join("&")}&page=${alias}`
    } else {
      url = `${url}?page=${alias}`
    }
    try {
      const response = await getNewsSearchData(url)
      setAllNews(response.newsCardData)
      setGroupedNews(response.groupedResult)
    } catch (e) {
      console.log("Error: ", e)
      setIsLoading(false)
    }
  }

  const handleTextSearch = async (text: string, e?: React.MouseEvent<Element, MouseEvent>) => {
    if (e) {
      e.preventDefault()
    }
    setTextSearch(text)
    if (text) {
      setIsLoading(true)
      await fetchSearchResults(text, fromDate, toDate)
      setSearchCount(searchCount + NUMBERS.ONE)
      setIsLoading(false)
    }
  }

  const handleAdvancedSearch = async () => {
    if (prevSelectedDate.to === toDate && prevSelectedDate.from === fromDate) {
      return
    }
    setIsLoading(true)
    await fetchSearchResults(textSearch, fromDate, toDate)
    setIsLoading(false)
  }

  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: newsSearchConfig?.search_placeholder_text,
    },
    buttonText: newsSearchConfig?.search_cta_text,
    handleTextSearch,
  }

  const toggleSearchType = () => {
    if (isAdvanceSearch) {
      handleAdvancedSearch()
    } else {
      setPrevSelectedDate({ to: toDate, from: fromDate })
    }
    setIsAdvanceSearch(!isAdvanceSearch)
  }

  const renderAdvanceSearchBlock = (
    <div className="news-search-filter-row">
      <div className="news-search-filter-search-container news-search-filter-top">
        <div className="filter-search" onClick={toggleSearchType} aria-hidden="true">
          <span className="search-type">
            {isAdvanceSearch
              ? newsSearchConfig?.simple_search_label
              : newsSearchConfig?.advance_search_label}
          </span>
          <span>
            <Icon iconName="ArrowForward2" />
          </span>
        </div>
      </div>
      {isAdvanceSearch && (
        <div className="news-search-filter-search-container news-search-filter-top advance-search-modal">
          <div className="date-group">
            <DatePicker
              dataPlaceHolder={newsSearchConfig?.from_placeholder_text}
              setValue={setFromDate}
              value={fromDate}
              maxDate={toDate}
            />
            <DatePicker
              dataPlaceHolder={newsSearchConfig?.to_placeholder_text}
              setValue={setToDate}
              value={toDate}
              minDate={fromDate}
            />
          </div>
          <div className="search-background" onClick={() => toggleSearchType()} />
        </div>
      )}
    </div>
  )

  const handleSort = (data: string) => {
    if (data === newsSearchConfig?.new_to_old_text) {
      allNews?.sort(
        (a: INewsCardInfo, b: INewsCardInfo): number =>
          Date.parse(b.publishedDate) - Date.parse(a.publishedDate),
      )
      groupedNews?.forEach((item) => {
        item.results.sort(
          (a: INewsCardInfo, b: INewsCardInfo): number =>
            Date.parse(b.publishedDate) - Date.parse(a.publishedDate),
        )
        return item
      })
    } else {
      allNews?.sort(
        (a: INewsCardInfo, b: INewsCardInfo): number =>
          Date.parse(a.publishedDate) - Date.parse(b.publishedDate),
      )
      groupedNews?.forEach((item) => {
        item.results.sort(
          (a: INewsCardInfo, b: INewsCardInfo): number =>
            Date.parse(a.publishedDate) - Date.parse(b.publishedDate),
        )
        return item
      })
    }
    allNews && setAllNews([...allNews])
    groupedNews && setGroupedNews([...groupedNews])
    return data
  }

  const getNewsView = allNews?.length ? (
    <NewsSearchResult
      newsCardData={allNews}
      newsSearchConfig={newsSearchConfig}
      searchedPhrase={textSearch}
      searchCount={searchCount}
      itemsPerPage={itemsPerPage}
      groupedResult={groupedNews || []}
      handleSort={handleSort}
    />
  ) : (
    <Container>
      <TitleDescription
        title={newsSearchConfig?.no_results_found_title || ""}
        description={newsSearchConfig?.no_results_found_body}
        searchKey=""
      />
    </Container>
  )

  return (
    <>
      <SearchGroup
        inputDataContent={searchBarConfiguration.inputDataContent}
        buttonText={searchBarConfiguration.buttonText || ""}
        handleTextSearch={handleTextSearch}
        handleTextChange={(text) => setTextSearch(text)}
        isDisabled={true}
      />
      {renderAdvanceSearchBlock}
      {isLoading ? (
        <div className="news-filter-loader">
          <Loader display={isLoading} />
        </div>
      ) : (
        getNewsView
      )}
    </>
  )
}
export default NewsFilterSearch
