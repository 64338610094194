import { getApi, getApiDomainAndLang } from "@utils/baseApi"
import { filterURL } from "."
import _ from "lodash"
const getDependentNodes = (arr: any[], id: string) => {
  const deps: any[] = []
  const children = arr.filter((item) => item?.attributes?.parent?.includes(`:${id}`))
  for (const child of children) {
    deps.push(child, ...getDependentNodes(arr, child.id))
  }
  return deps
}

const getImmediateDependentNodes = (arr: any[], id: string) => {
  return arr.filter((item) => item?.parent === id) || []
}

const constructUrlWithDomain = (url: string, protocol: string, host: string) => {
  const urlSplit = url?.split("?")
  let newUrl = urlSplit?.[0]
  if (!newUrl?.startsWith("/")) newUrl = "/" + newUrl
  if (!newUrl?.endsWith("/")) newUrl = newUrl + "/"
  newUrl = host ? `${protocol}//${host}${newUrl}` : newUrl
  return urlSplit?.length > 1 ? newUrl + urlSplit?.[1] : newUrl
}

const getCountryAndBrandSwitcherData = async (siteConfig?: any) => {
  // site is not microsite, it is portal
  if (siteConfig?.is_microsite === 0) {
    return null
  }

  let rawMenuData = await getApi(
    "/jsonapi/menu_link_content/main?page%5Boffset%5D=0&page%5Blimit%5D=200&filter[enabled][value]=1",
  )
  rawMenuData = rawMenuData?.data

  const rootNodeId = "root"
  const l1FullData: any[] = []
  const l1SwitcherData: any[] = []
  const { protocol, host } = window.location
  const fullMenuData = rawMenuData?.map((item: any) => {
    const obj = {
      id: item?.id,
      uri: filterURL(item?.attributes?.link?.uri) ?? null,
      parent: item?.attributes?.parent ? item.attributes.parent.split(":")[1] : "",
      title: item?.attributes?.title || "",
    }
    if (!item?.attributes?.parent) {
      obj.parent = rootNodeId
      const newItem = _.cloneDeep(item)
      newItem.attributes.parent = `menu_link_content:${rootNodeId}`
      obj.uri = obj.uri?.startsWith("http") ? obj.uri : constructUrlWithDomain(obj.uri, protocol, host)
      l1FullData.push(newItem)
      l1SwitcherData.push(obj)
    }
    return obj
  })

  const l2SwitcherData = l1SwitcherData?.reduce((acc: any, curr: any) => {
    const dependents = getImmediateDependentNodes(fullMenuData, curr.id)
    return { ...acc, [curr.id]: dependents }
  }, {})
  if(siteConfig?.enable_multilingual_menu) {
    const { lang } = getApiDomainAndLang()
    rawMenuData = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.langcode === lang)
  }
  const menuTree: any = rawMenuData?.reduce((acc: any, curr: any) => {
    const dependents = getDependentNodes(rawMenuData, curr.id)
    if (!dependents?.length) {
      return acc
    }
    return { ...acc, [curr.id]: dependents }
  }, {})

  return {
    rootNodeId,
    menuTree: { ...menuTree, [rootNodeId]: l1FullData },
    l1SwitcherData,
    l2SwitcherData,
  }
}

export default getCountryAndBrandSwitcherData
