import React, { useCallback, useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { Column, Row, Seperator } from "@atoms"
import { connect, useDispatch } from "react-redux"
import {
  addUpdateAdvanceFilter,
  updateAdvanceSearchText,
  updateAllAdvanceFilters,
  updatePagination,
} from "store/actions/AdvanceFIlterActionCreators"
import { Dispatch } from "redux"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import debounce from "lodash.debounce"
import { getFormattedEduCardsData } from "@helpers/dataFunctions/getEducationCardsData"
import { Pagination } from "@molecules"
import Loader from "@atoms/loader"
import { useRouter } from "next/router"
import { getFormattedNewsCardsData } from "@helpers/dataFunctions/getNewsStoriesListData"
import { getFormattedEventCardsData } from "@helpers/dataFunctions/getEventVerticalCardData"
import { getFormattedTherapyCardsData } from "@helpers/dataFunctions/getTherapyCards"
import { getFormattedFlexibleCardData } from "@helpers/dataFunctions/getflexibleCardData"

import NUMBERS from "@helpers/constants/numbers"
import { IAdvancedFilterBlockProps,IEnhancedFilterProps } from "./_advancedFilterBlock.interface"

import TitleDescription from "../../molecules/title-description"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"
import { IEducationCardProps } from "../educationCard/_educationCard.interface"
import { IEventCardVerticalProps } from "../eventCardVertical/_eventCardVertical.interface"
import { ITherapyCardProps } from "../therapyCard/_therapyCard.interface"
import { INewsStoriesCardProps } from "../newsStoriesCard/_newsStoriesCard.interface"
import { IProductCardProps } from "../productCard/_productCard.interface"
import { updatePaywall3Roles, updatePaywallContent } from "store/actions/CommonActionCreators"
import IFlexibleCard from "@molecules/flexibleCard/_flexibleCard.interface"
import _ from "lodash"

const DynamicEducationCard = dynamic(() => import("../educationCard"), { ssr: false })
const DynamicEventCardVertical = dynamic(() => import("../eventCardVertical"), { ssr: false })
const DynamicTherapyCard = dynamic(() => import("../therapyCard"), { ssr: false })
const DynamicNewsStoriesCard = dynamic(() => import("../newsStoriesCard"), { ssr: false })
const DynamicProductCard = dynamic(() => import("../productCard"), { ssr: false })
const DynamicFlexibleCard = dynamic(() => import("../../molecules/flexibleCard"), { ssr: false })

interface DispatchProps {
  addUpdateAdvanceFilter: (data: any) => void
  updatePagination: (data: number) => void
  updateAdvanceSearchText: (data: string) => void
  updateAllAdvanceFilters: (data: any, page: number, str: string, block: string) => void
}

type StateTypes = {
  advancedFilterData: {
    blockType: string
    advanceFilters: any
    currentPage: number
    advanceSearchText: string
  }
  user: {
    isLoggedIn: boolean
  }
}
type Props = DispatchProps & StateTypes & IAdvancedFilterBlockProps
let advanceFilterData: IEnhancedFilterProps
//const [academyConfig , setAcademyConfig] = useState({advanceFilterData:''})
const AdvancedFilterBlock = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalResults, setTotalResults] = useState<number>(NUMBERS.ZERO)

  const {
    // eslint-disable-next-line no-shadow
    type,
    advancedFilterData,
    user,
    filterData
  } = props
  const { advanceFilters, currentPage, advanceSearchText, blockType } = advancedFilterData
  const isMobile = useMediaQuery("(max-width: 991px)")
  const [data, setData] = useState([])
  const [isUpdated, setIsUpdated] = useState(false)
  const [itemsToRender, setItemsToRender] = useState(0)
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>([])
  const [fieldPaywallRoles, setFieldPaywallRoles] = useState<any>([])
  const router = useRouter()
  const dispatch: Dispatch<any> = useDispatch()

  const updateAdvanceFilterState: any = useCallback(
    debounce((value: any) => props.addUpdateAdvanceFilter(value), 500),
    [dispatch],
  )
  const updateAdvanceFilterPagination: any = useCallback(
    (value: number) => props.updatePagination(value),
    [dispatch],
  )

  const updateAdvanceFilterSearchText: any = useCallback(
    (searchText: string) => props.updateAdvanceSearchText(searchText),
    [dispatch],
  )
  const updateAllAdvanceFilters: any = useCallback(
    (value: any, page: number, searchText: string, block: string) =>
      props.updateAllAdvanceFilters(value, page, searchText, block),
    [dispatch],
  )
  const updateAfPaywall3Roles: any = useCallback(
    (value: any) => dispatch(updatePaywall3Roles(value)),
    [dispatch],
  )
  const updateAfPaywallContent: any = useCallback(
    (value: any) => dispatch(updatePaywallContent(value)),
    [dispatch],
  )

  const getUrlParams = (selectedFilters: Array<IOption>, filterName: string, passAll = false) => {
    let url = ""
    if (selectedFilters.length && !passAll) {
      if (filterName === "created") {
        if (selectedFilters.length > 1) {
          selectedFilters.forEach((element: any, index: any) => {
            url += `&views-argument[${index}]=${element.value}`
          })
        } else if (selectedFilters.length === 1) {
          url += `&views-argument[0]=${selectedFilters[0].value}`
        } else {
          url += "&views-argument[0]=All"
        }
      } else if (selectedFilters.length > 1) {
        selectedFilters.forEach((element: any, index: any) => {
          url += `&views-filter[${filterName}][${index}]=${element.id}`
        })
      } else {
        url += `&views-filter[${filterName}][0]=${selectedFilters[0].id}`
      }
    } else if (filterName === "created") {
      url += "&views-argument[0]=All"
    } else {
      url += `&views-filter[${filterName}]=All`
    }
    return url
  }
  const checkIfValueExistsInQuery = (queryStr: string, value: string) => {
    const selectedIds = queryStr ? decodeURIComponent(queryStr).split(",") : []
    return selectedIds.includes(value)
  }

  useEffect(() => {
    const selectedCategories = router?.query
    const tempSele = selectedCategories
    const urlFilters = Object.keys(tempSele)

    if (urlFilters.length === 1 && urlFilters[0] === "slug") {
      const updatedSelectOptions = filterData?.advanceFilterData?.filtersOptions?.map((item) => ({
        ...item,
        selectOptions: {
          ...item.selectOptions,
          options: item.selectOptions.options?.map((val) => {
            // @ts-ignore
            return {
              ...val,
              isSelected: false,
            }
          }),
        },
      }))
      updateAllAdvanceFilters(updatedSelectOptions, 0, "", type)
    } else {
      const updatedSelectOptions = filterData?.advanceFilterData?.filtersOptions?.map((item) => ({
        ...item,
        selectOptions: {
          ...item.selectOptions,
          options: item.selectOptions.options?.map((val) => {
            // @ts-ignore
            return {
              ...val,
              isSelected:
                selectedCategories?.[item?.filterName] &&
                !!checkIfValueExistsInQuery(
                  // @ts-ignore
                  selectedCategories?.[item?.filterName],
                  val?.value?.toString(),
                ),
            }
          }),
        },
      }))

      updateAllAdvanceFilters(
        updatedSelectOptions,
        Number(selectedCategories?.page ?? 0),
        selectedCategories?.search || "",
        type,
      )
      // @ts-ignore
      setCurrentSearchText(selectedCategories?.search || "")
      // }
    }
    setIsUpdated(true)
  }, [])

  const frameUrl = ({ filterName, selectedOptions }: any) => {
    const currentFilter = advanceFilters.find((item: any) => item.filterName === filterName)
      .selectOptions.options
    return getUrlParams(
      selectedOptions,
      filterName,
      selectedOptions.length === currentFilter.length,
    )
  }

  const generateOutput = (href: string, origin: string, pathname: string): string => {
    // Combine pathname and fragment identifier to create the desired output
    let output = pathname + href?.substring(origin?.length + pathname?.length)

    // Find the indices of query and fragment identifiers
    const queryIndex = output?.indexOf("?")
    const fragmentIndex = output?.indexOf("#")

    // Remove query parameters if they exist
    if (queryIndex !== -1) {
      output = output?.substring(0, queryIndex)
    }

    // Keep the content till next slash if fragment identifier exists
    if (fragmentIndex !== -1) {
      const nextSlashIndex = output?.indexOf("/", fragmentIndex)
      if (nextSlashIndex !== -1) {
        output = output?.substring(0, nextSlashIndex + 1) // Include the next slash
      }
    }

    // Add a trailing slash if it doesn't exist
    if (!output?.endsWith("/")) {
      output += "/"
    }

    return output
  }

  useEffect(() => {
    // callAPI and update cardsData
    const fetchData = async () => {
      setIsLoading(true)
      const selectedFiltersList = advanceFilters?.map((item: any) => ({
        filterName: item?.filterName,
        selectedOptions: item?.selectOptions.options?.filter((val: any) => val?.isSelected),
      }))
      const urlValue =
        selectedFiltersList?.map((filter: any) => frameUrl(filter)).join("") ||
        "&views-argument[0]=All"
      const searchUrlStr = (param: string) =>
        advanceSearchText ? `&views-filter[${param}]=${advanceSearchText}` : ""
      if (type === "education") {
        const { educationCards, totalCount, itemsPerPage, fieldPaywallRoles } =
          await getFormattedEduCardsData(
            `/jsonapi/views/education_overview_page/education_overview_block?views-filter[langcode]=All${urlValue}${searchUrlStr(
              "combine",
            )}&page=${currentPage}`,
          )
        setData(educationCards)
        setTotalResults(totalCount)
        setItemsToRender(itemsPerPage)
        setIsLoading(false)
        setFieldPaywallRoles(fieldPaywallRoles)
      }
      if (type === "events") {
        const { eventCards, totalCount, itemsPerPage, fieldPaywallRoles } =
          await getFormattedEventCardsData(
            `/jsonapi/views/event_overview_list/event_vertical_cards?views-filter[langcode]=All${urlValue}${searchUrlStr(
              "combine",
            )}&page=${currentPage}`,
          )
        setData(eventCards)
        setTotalResults(totalCount)
        setItemsToRender(itemsPerPage)
        setIsLoading(false)
        setFieldPaywallRoles(fieldPaywallRoles)
      }
      if (type === "therapy-areas") {
        const { therapyCards, totalCount, itemsPerPage, fieldPaywallRoles } =
          await getFormattedTherapyCardsData(
            `/jsonapi/views/therapy_areas_overview/therapy_cards?views-filter[langcode]=All${urlValue}${searchUrlStr(
              "search",
            )}&page=${currentPage}`,
          )
        setData(therapyCards)
        setTotalResults(totalCount)
        setItemsToRender(itemsPerPage)
        setIsLoading(false)
        setFieldPaywallRoles(fieldPaywallRoles)
      }
      if (type === "news") {
        const { latestNewsStoriesCards, totalCount, itemsPerPage, fieldPaywallRoles } =
          await getFormattedNewsCardsData(
            `/jsonapi/views/latest_news_stories/o_nas?views-filter[langcode]=All${urlValue}${searchUrlStr(
              "combine",
            )}&page=${currentPage}`,
          )
        setData(latestNewsStoriesCards)
        setTotalResults(totalCount)
        setItemsToRender(itemsPerPage)
        setIsLoading(false)
        setFieldPaywallRoles(fieldPaywallRoles)
      }
      if (type === "sandoz_academy_block") {
        const { flexibleCards, totalCount, itemsPerPage, fieldPaywallRoles } =
          await getFormattedFlexibleCardData(
            `/jsonapi/views/sandoz_academy_code_listing_view/sandoz_academy_block?views-filter[langcode]=All${urlValue}${searchUrlStr(
              "combine",
            )}&page=${currentPage}`,
          )
        setData(flexibleCards)
        setTotalResults(totalCount)
        setItemsToRender(itemsPerPage)
        setIsLoading(false)
        setFieldPaywallRoles(fieldPaywallRoles)
      }
    }
    if (isUpdated && blockType === type) {
      fetchData().then(() => {
        const selectedFilterList = fetchAllSelectedFilters()
        const obj = {} as any
        Object.entries(selectedFilterList).forEach(([key, value]: any) => {
          obj[key] = value.length ? value.map((u: any) => u.value).join(",") : ""
        })
        const { protocol, host, href, origin, pathname } = window.location
        let urlQueryString = ""
        Object.entries(obj).forEach(([key, value]: any) => {
          urlQueryString += `&${key}=${encodeURIComponent(value)}`
        })
        let nagivationQuery = router?.query?.acc
          ? `acc=${router.query.acc}`
          : router?.query?.tab
          ? `tab=${router.query.tab}`
          : ""
        const newPathname = generateOutput(href, origin, pathname)
        const newUrl = `${protocol}//${host}${newPathname}?${urlQueryString.substring(
          1,
        )}&page=${currentPage}&search=${advanceSearchText || ""}&${nagivationQuery || ""}`
        window.history.replaceState({ path: newUrl }, "", newUrl)
      })
    }
  }, [advancedFilterData, isUpdated])

  useEffect(() => {
    if (!isLoading && fieldPaywallRoles.length && !user?.isLoggedIn) {
      updateAfPaywall3Roles(Array.from(new Set(fieldPaywallRoles)))
      updateAfPaywallContent(true)
      }
  }, [isLoading, fieldPaywallRoles, user])

  const handleSearch = (searchText: string) => {
    updateAdvanceFilterSearchText(searchText)
  }

  const handlePaginationClick = (currentOffset: number) => {
    updateAdvanceFilterPagination(currentOffset / itemsToRender)
  }

  const checkIfValueExists = (val: any, valArr: any) =>
    valArr.findIndex((item: any) => item.id === val.id) > -1

  const handleAdditionalFilters = (label: string, value: any) => {
    if (!isMobile) {
      const alteredFilter = advanceFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      updateAdvanceFilterState(alteredFilter)
    } else {
      const alteredFilter = localSelectedFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      setLocalSelectedFilters(alteredFilter)
    }
  }

  const fetchAllSelectedFilters = () => {
    const selectedValues = {} as any
    advanceFilters?.forEach((item: { filterName: string; selectOptions: { options: any[] } }) => {
      selectedValues[`${item.filterName}`] = item.selectOptions.options
        ?.filter((val) => val.isSelected)
        .map(({ isSelected, ...rest }: any) => rest)
    })
    return selectedValues
  }

  const applyAdditionalFilters = () => {
    updateAdvanceFilterState(localSelectedFilters)
  }

  const getCard = (
    cardData:
      | IEducationCardProps
      | IEventCardVerticalProps
      | ITherapyCardProps
      | INewsStoriesCardProps
      | IProductCardProps
      | IFlexibleCard,
    _key: number,
  ) => {
    if (type === "education") {
      return <DynamicEducationCard {...(cardData as IEducationCardProps)} key={_key} />
    }
    if (type === "events") {
      return <DynamicEventCardVertical {...(cardData as IEventCardVerticalProps)} key={_key} />
    }
    if (type === "therapy-areas") {
      return <DynamicTherapyCard {...(cardData as ITherapyCardProps)} key={_key} />
    }
    if (type === "news") {
      return <DynamicNewsStoriesCard {...(cardData as INewsStoriesCardProps)} key={_key} />
    }
    if (type === "product-list") {
      return (
        <DynamicProductCard
          {...(cardData as IProductCardProps)}
          key={_key}
          desktopColumns={4}
          tabletColumns={6}
        />
      )
    }
    if (type === "sandoz_academy_block") {
      return <DynamicFlexibleCard {...(cardData as IFlexibleCard)} key={_key}
      desktopColumns={4}
      tabletColumns={6}/>
    }
  }
  
  const cardsAndPagination = (
    <Row className="advanced-filter-card-row">
      {data?.length > 0 ? (
        <>
          {data?.map((cardData, _key: number) => getCard(cardData, _key) || <></>)}
          <Row className="pagination-wrapper">
            <Pagination
              itemsCount={totalResults}
              itemsPerPage={itemsToRender}
              onClick={handlePaginationClick}
              offSet={currentPage}
            />
          </Row>
        </>
      ) : (
        <TitleDescription
          title={filterData?.advanceFilterData?.filtersLabels?.noResultsFoundLabel}
          description={filterData?.advanceFilterData?.filtersLabels?.noResultsFoundDescription}
          searchKey={currentSearchText}
        />
      )}
    </Row>
  )
  const clearAllFilters = () => {
    const clearedFilters = filterData?.advanceFilterData?.filtersOptions?.map((item) => ({
      ...item,
      selectOptions: {
        ...item.selectOptions,
        options: item.selectOptions.options?.map((val) => {
          // @ts-ignore
          return {
            ...val,
            isSelected: false,
          }
        }),
      },
    }))
    updateAllAdvanceFilters(clearedFilters, 0, advanceSearchText || "", type)
  }
  const handleClick = (label: string, value: any) => {
    const alteredFilter = advanceFilters?.map(
      (item: { filterName: string; selectOptions: { options: any[] } }) => {
        if (item.filterName === label) {
          return {
            ...item,
            selectOptions: {
              ...item.selectOptions,
              options: item.selectOptions.options?.map((val) => {
                if (val.id === value?.id) {
                  return {
                    ...val,
                    isSelected: false,
                  }
                }
                return val
              }),
            },
          }
        }
        return item
      },
    )
    updateAdvanceFilterState(alteredFilter)
  }

  const fetLocalSelectedValue = () => {
    const selectedValues = {} as any
    localSelectedFilters?.forEach(
      (item: { filterName: string; selectOptions: { options: any[] } }) => {
        selectedValues[`${item.filterName}`] = item.selectOptions.options
          ?.filter((val) => val.isSelected)
          .map(({ isSelected, ...rest }: any) => rest)
      },
    )
    return selectedValues
  }
  const resetLocalFilterWithReducer = () => {
    setLocalSelectedFilters(advanceFilters)
  }
 
  return (
    <>
      {!isLoading ? (
        <Column
          className="advanced-filter-container scroll-top"
          mobile={12}
          tablet={12}
          desktop={12}>
          <EnhancedFilterSearch
            filtersOptions={filterData?.advanceFilterData?.filtersOptions}
            filterLabels={filterData?.advanceFilterData?.filtersLabels}
            isCareerSearch={filterData?.advanceFilterData?.filtersLabels?.enableToggle === 1}
            handleAdditionalFilters={handleAdditionalFilters}
            handleSearch={handleSearch}
            handleUnselectAll={() => {
              clearAllFilters()
            }}
            selectedFilters={isMobile ? fetLocalSelectedValue() : fetchAllSelectedFilters()}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={totalResults}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(advanceFilters, localSelectedFilters)}
          />
          <>{!isMobile && <Seperator className="data-separator" />}</>
            {cardsAndPagination}
        </Column>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdateAdvanceFilter: (data: any) => addUpdateAdvanceFilter(data),
  updatePagination: (data: number) => updatePagination(data),
  updateAdvanceSearchText: (data: string) => updateAdvanceSearchText(data),
  updateAllAdvanceFilters: (data: any, page: number, str: string, block: string) =>
    updateAllAdvanceFilters(data, page, str, block),
}

const mapState = (state: any) => ({
  advancedFilterData: state.advanceFilter,
  user: state.user,
})

export default connect(mapState, mapDispatch)(AdvancedFilterBlock)
