import getNestedObject from "@utils/nestedObjects"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import { Icon } from "@atoms"

export const getPoDetailFilterLabelData = (poDetailData:any) => {
    const CategoryLabelData = {
      searchLabel: getNestedObject(poDetailData, "po_details_search_field_label"),
      searchPlaceholder: getNestedObject(poDetailData, "po_details_search_field_placeholder_label"),
      searchCTALabel: getNestedObject(poDetailData, "po_details_search_cta_label"),
      tableColumns: getNestedObject(poDetailData, "items_table_column_labels"),
      resultsLabel: getNestedObject(poDetailData, "po_details_search_results_text"),
      noResultsLabel: getNestedObject(poDetailData, "po_details_search_no_results_text"),
      noResultsDesc: "",
      sortByLabel: getNestedObject(poDetailData, "po_details_search_sortby_label"),
      applyFiltersCTALabel: getNestedObject(poDetailData, "job_apply_filters_text"),
      showFiltersCTALabel: getNestedObject(poDetailData, "job_show_filters_text"),
      unSelectItemsLabel: getNestedObject(poDetailData, "search_unselect_items"),
      paginationCount: getNestedObject(poDetailData, "job_count_for_each_page"),
      exportToCTALabel:  getNestedObject(poDetailData, "export_to_excel_cta_label"),
      printCTALabel: getNestedObject(poDetailData, "print_cta_labe"),
      accountInformation: getNestedObject(poDetailData, "account_information"),
      billTo: getNestedObject(poDetailData, "bill_to"),
      shipTo: getNestedObject(poDetailData, "ship_to"),
      specialInstructions: getNestedObject(poDetailData, "special_instructions"),
      blockTitle: getNestedObject(poDetailData, "po_details_title"),
    }
    return CategoryLabelData
}

export const fetchPoDetailsTableData = (response: any) => {
  const tableData: searchFilterType[] = response?.data?.map((item:any) => {
    return {
      ndcNumber: {
        title: "",
        desc: item.ndc_number ?? "",
        type: "text",
        link: "",
      },
      customerMaterialNumber: {
        title: "",
        desc: item.customer_material_number ?? "",
        type: "text",
        link: "",
      },
      itemDescription: {
        title: "",
        desc: item.item_description ?? "",
        type: "text",
        link: "",
      },
      quantityOrdered: {
        title: "",
        desc: item.quantity_ordered ?? "",
        type: "text",
        link: "",
      },
      quantityShipped: {
        title: "",
        desc: item.quantity_shipped ?? "",
        type: "text",
        link: "",
      },
      trackingNumber: {
        title: "",
        desc: item.tracking_number ?? "",
        type: "richtext",
        link: "",
      },
      packingSlip: {
        title: item.packing_slip ?? "",
        desc: "",
        type: "download",
        link: item.packing_slip ?? "",
        linkIcon: <Icon iconName="downloadPdfIcon" />,
      },
      invoice: {
        title: item.invoice ?? "",
        desc: "",
        type: "download",
        link: item?.invoice ?? "",
        linkIcon: <Icon iconName="downloadPdfIcon" />,
      },
    }
  })

  return tableData
}

export const sortDataAsc = (data: any) =>
  data.sort((a: any, b: any) => a.label.localeCompare(b.label))

const getPurchaseOrderDetails = (data: any) => {
  return {}
}

export default getPurchaseOrderDetails
