import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Column, Icon } from "@atoms"
import { useMediaQuery, useOutsideClick } from "@helpers/hooks"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { useRouter } from "next/router"
import { IFloatingModule, IFloatingModuleData } from "./_floatingModule.interface"
import FloatingContent from "@atoms/floatingContent"
import { addISIBanner } from "store/actions/ISIBannerActionCreators"
import { connect } from "react-redux"

interface StateProps {
  isiBanner?: any
}

interface DispatchProps {
  addISIBanner: (isiBannerData: any) => void
}

type Props = StateProps & IFloatingModule & DispatchProps

const FloatingModule = ({ data, className, addISIBanner, isiBanner }: Props) => {
  const {
    title,
    bannerData,
    nodeTitle,
    expandCoverage,
    preExpandCoverage,
    nodeRibbionColor,
  }: IFloatingModuleData = data || {}
  const currentPath = window.location.href
  const [isExpanded, setIsExpanded] = useState(false)
  const [isManualExpand, setIsManualExpand] = useState(false)
  const [isOutsideViewAtTheBottom, setIsOutsideViewAtTheBottom] = useState(false)
  const router = useRouter()
  const [heightCms, setHeightCms] = useState(preExpandCoverage)
  const headerRef = useRef<any>(null)
  const isMobile = useMediaQuery("(max-width: 767px)")
  const { applicationTheme } = useContext(ApplicationThemeContext)

  const rangesRebranding = [
    { range: [48, 50], value: "eight" },
    { range: [45, 47], value: "seven" },
    { range: [42, 44], value: "six" },
    { range: [38, 41], value: "five" },
    { range: [35, 37], value: "four" },
    { range: [33, 34], value: "three" },
    { range: [30, 32], value: "two" },
    { range: [25, 29], value: "one" },
  ]

  const rangesDefault = [
    { range: [48, 50], value: "twelve" },
    { range: [45, 47], value: "eleven" },
    { range: [42, 44], value: "ten" },
    { range: [39, 41], value: "nine" },
    { range: [36, 38], value: "eight" },
    { range: [33, 35], value: "seven" },
    { range: [30, 32], value: "six" },
    { range: [28, 29], value: "five" },
    { range: [25, 27], value: "four" },
  ]

  const getClasses = () => {
    const classes: string[] = ["floating-module"]
    if (className) {
      classes.push(className)
    }
    return classes.join(" ")
  }

  const handleClickOutside = () => {
    setTimeout(() => {
      const path = window.location.href
      setIsManualExpand(false)
      if (!path.includes("#isi")) {
        setIsExpanded(false)
      } else {
        setIsExpanded(true)
      }
    }, 1)

  }

  const getAppTheme = (theme: string) => {
    const validThemes = [
      "rebranding",
      "rebranding-japan",
      "jubbonti",
      "wyost",
      "biosimilarsinbone",
      "vagidonna",
    ]
    return validThemes.includes(theme) ? "rebranding" : "default"
  }

  const getDisplayLines = () => {
    const appTheme = getAppTheme(applicationTheme ?? "default")
    const ranges = appTheme === "rebranding" ? rangesRebranding : rangesDefault
    const match = ranges.find(
      ({ range }) => preExpandCoverage >= range[0] && preExpandCoverage <= range[1],
    )
    return match ? match.value : ""
  }

  const pxTOvh = (value: any) => {
    let w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      y = w.innerHeight || e.clientHeight || g.clientHeight
    return (100 * value) / y
  }

  const handleIsExpanded = () => {
    setIsManualExpand(true)
    setHeightCms(pxTOvh(headerRef?.current?.clientHeight))
    if (isiBanner?.isiBannerData && isiBanner?.isiBannerData?.length > 0) {
      const updatedISIBanner = isiBanner?.isiBannerData?.map((banner: any) => {
        if (banner.nodeTitle === nodeTitle) {
          return { ...banner, bannerInteractionFlag: 1 }
        }
        return banner
      })
      addISIBanner(updatedISIBanner)
    }

    if (isExpanded && currentPath.includes("#isi")) {
      router.push(
        {
          pathname: window.location.pathname,
        },
        undefined,
        { shallow: true },
      )
    }
    setIsExpanded(!isExpanded)
  }
  const refExpanded = useOutsideClick(handleClickOutside)

  useEffect(() => {
    const html = document.querySelector("html")
    if (html && isExpanded) {
      html.classList.add("hiddenScroll")
    }
    if (html && !isExpanded) {
      html.classList.remove("hiddenScroll")
    }
  }, [isExpanded])

  useEffect(() => {
    if (isiBanner?.isiBannerData && isiBanner?.isiBannerData?.length > 0) {
      const currentBannerDetail =
        isiBanner?.isiBannerData?.find((banner: any) => banner.nodeTitle === nodeTitle) ?? null
      if (currentBannerDetail?.bannerInteractionFlag === 1) {
        setHeightCms(pxTOvh(headerRef?.current?.clientHeight))
      } else {
        setHeightCms(preExpandCoverage)
      }
    }
  }, [router?.query])

  const { ref, inView, entry } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (entry) {
      if (entry.boundingClientRect.bottom < 0) {
        setIsOutsideViewAtTheBottom(true)
      } else {
        setIsOutsideViewAtTheBottom(false)
      }
    }
  }, [inView])

  useEffect(() => {
    if (!isManualExpand) {
      const isExpandedVal = currentPath.includes("#isi")
      if (!isExpanded) {
        setIsExpanded(isExpandedVal)
      }
    }
  })

  const nonExpandedBanner = () => {
    const displayingArray = [] as any[]
    const indicationsData = bannerData?.find((item: any) => item?.type === "indications")
    const warningsBoxData = bannerData?.find((item: any) => item?.type === "warning_box")
    const safetyInformationData = bannerData?.find(
      (item: any) => item?.type === "important_safety_information",
    )
    if (indicationsData && warningsBoxData) {
      if (!isMobile) {
        displayingArray.push(indicationsData)
        displayingArray.push(warningsBoxData)
      } else {
        displayingArray.push(warningsBoxData)
        displayingArray.push(indicationsData)
      }
      return (
        <>
          <div className="text-2-columns floating-module-width">
            {displayingArray?.map((item: any, index: number) => {
              return (
                <>
                  {item?.title?.content && item?.description?.content && (
                    <Column
                      mobile={12}
                      tablet={6}
                      desktop={6}
                      className={isMobile ? "bottom-space" : ""}>
                      <FloatingContent
                        key={index}
                        {...item}
                        className={isMobile ? index === 0 ? "lines-desc-three" : "lines-desc-four" : ""}
                      />
                    </Column>
                  )}
                </>
              )
            })}
          </div>
        </>
      )
    } else if (safetyInformationData && warningsBoxData && !indicationsData) {
      if (!isMobile) {
        displayingArray.push(safetyInformationData)
        displayingArray.push(warningsBoxData)
      } else {
        displayingArray.push(warningsBoxData)
        displayingArray.push(safetyInformationData)
      }
      return (
        <>
          <div className="text-2-columns floating-module-width">
            {displayingArray?.map((item: any, index: number) => {
              return (
                <>
                  {item?.title?.content && item?.description?.content && (
                    <Column
                      mobile={12}
                      tablet={6}
                      desktop={6}
                      className={isMobile ? "bottom-space" : ""}>
                      <FloatingContent
                        key={index}
                        {...item}
                        className={isMobile ? index === 0 ? "lines-desc-three" : "lines-desc-four" : ""}
                      />
                    </Column>
                  )}
                </>
              )
            })}
          </div>
        </>
      )
    } else if (safetyInformationData && !warningsBoxData && indicationsData) {
      if (!isMobile) {
        displayingArray.push(safetyInformationData)
        displayingArray.push(indicationsData)
      } else {
        displayingArray.push(indicationsData)
        displayingArray.push(safetyInformationData)
      }
      return (
        <>
          <div className="text-2-columns floating-module-width">
            {displayingArray?.map((item: any, index: number) => {
              return (
                <>
                  {item?.title?.content && item?.description?.content && (
                    <Column
                      mobile={12}
                      tablet={6}
                      desktop={6}
                      className={isMobile ? "bottom-space" : ""}>
                      <FloatingContent
                        key={index}
                        {...item}
                        className={isMobile ? index === 0 ? "lines-desc-three" : "lines-desc-four" : ""}
                      />
                    </Column>
                  )}
                </>
              )
            })}
          </div>
        </>
      )
    } else if (
      (isMobile && safetyInformationData && !warningsBoxData && !indicationsData) ||
      (!safetyInformationData && !warningsBoxData && indicationsData) ||
      (!safetyInformationData && warningsBoxData && !indicationsData)
    ) {
      safetyInformationData && displayingArray.push(safetyInformationData)
      indicationsData && displayingArray.push(indicationsData)
      warningsBoxData && displayingArray.push(warningsBoxData)
      return (
        <>
          <div className="text-2-columns floating-module-width">
            {displayingArray?.[0]?.title?.content && displayingArray?.[0]?.description?.content && (
              <Column mobile={12} tablet={6} desktop={6} className="bottom-space">
                <FloatingContent {...displayingArray?.[0]} className={isMobile ? `lines-desc-${getDisplayLines()}` : ""} />
              </Column>
            )}
          </div>
        </>
      )
    }
    return bannerData?.map((item: any, index: number) => {
      return (
        <div className="text-2-columns floating-module-width" key={`banner-data-${index}`}>
          <FloatingContent {...item} />
        </div>
      )
    })
  }

  const expandedBanner = () => {
    return bannerData?.map((item: any, index: number) => {
      return (
        <FloatingContent
          key={index}
          title={item?.title}
          description={item?.description}
          border={item?.border}
          isAccordian={item?.isAccordian}
          megaTitle={item?.megaTitle}
        />
      )
    })
  }

  useLayoutEffect(() => {
    if (
      heightCms === pxTOvh(headerRef?.current?.clientHeight) &&
      !isExpanded &&
      nodeRibbionColor === "default"
    ) {
      headerRef?.current?.classList?.add("non-expanded")
    } else {
      headerRef?.current?.classList?.remove("non-expanded")
    }
  })

  return (
    <>
      {(inView && !isExpanded) || isOutsideViewAtTheBottom ? null : (
        <div
          ref={refExpanded}
          style={{ height: isExpanded ? `${expandCoverage}vh` : `${heightCms}vh` }}
          className={
            heightCms === pxTOvh(headerRef?.current?.clientHeight) && !isExpanded
              ? "floating-module sticky show p-0"
              : "floating-module sticky show"
          }>
          <div
            ref={headerRef}
            className={
              heightCms === pxTOvh(headerRef?.current?.clientHeight) &&
                !isExpanded &&
                nodeRibbionColor === "default"
                ? "header non-expanded"
                : "header"
            }>
            <div
              className={`${heightCms === pxTOvh(headerRef?.current?.clientHeight) &&
                !isExpanded &&
                nodeRibbionColor === "default" &&
                "non-expanded"
                } header-content floating-module-width ${isExpanded && "floating-module-width-expand"
                }`}>
              <div>{title}</div>
              <div className="header-icon-container">
                {isExpanded ? (
                  <Icon iconName="ChevronDown" className="header-icon" onClick={handleIsExpanded} />
                ) : (
                  <Icon iconName="ChevronUp" className="header-icon" onClick={handleIsExpanded} />
                )}
              </div>
            </div>
          </div>
          {/* --------ISI Banner New Approach---------- */}

          {!isExpanded ? (
            <div className="text text-sticky">{nonExpandedBanner()}</div>
          ) : (
            <div className="text text-sticky sticky expanded floating-module-width floating-module-width-expand">
              {expandedBanner()}
            </div>
          )}
        </div>
      )}
      <div className={getClasses()} ref={ref}>
        <div className="header">
          <div className="header-content floating-module-width floating-module-width--static">
            <div>{title}</div>
          </div>
        </div>
        <div className="text floating-module-width floating-module-width--static">
          {expandedBanner()}
        </div>
      </div>
    </>
  )
}

const mapDispatch = {
  addISIBanner: (isiBannerData: any) => addISIBanner(isiBannerData),
}

const mapState = (state: any) => ({
  isiBanner: state.isiBanner,
})

export default connect(mapState, mapDispatch)(FloatingModule)
