import React from "react"
import { Anchor, AnchorButton, Column, Img, RichText } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import getDirection from "@utils/direction"
import { trimText } from "../../../utils/stringparsing"
import { ITeaserCardProps } from "./_teaserCard.interface"

const TeaserCard = ({
  styledimage,
  imageSrc,
  imageAlt,
  title,
  paragraph,
  link,
  linkIsExternal,
  linkText,
  columnTablet,
  columnDesktop,
  cardAlignment,
  isImageClickable
}: ITeaserCardProps) => (
  <Column
    desktop={columnDesktop || 4}
    tablet={columnTablet || 4}
    mobile={12}
    className="teaser-card-wrapper">
    <div className={"teaser-card " + cardAlignment} dir={getDirection()}>
      <div className="card-body">
        {isImageClickable && link ? (
          <Anchor href={link} tabindex={0} noUnderline={true} isExternal={linkIsExternal}>
            <div className="teaser-card-image">
              {imageSrc && (
                <Img
                  type="card"
                  styledimage={styledimage}
                  src={imageSrc}
                  width={368}
                  height={272}
                  alt={imageAlt}
                />
              )}
            </div>
          </Anchor>
        ) : (
          <div className="teaser-card-image">
            {imageSrc && (
              <Img
                type="card"
                styledimage={styledimage}
                src={imageSrc}
                width={368}
                height={272}
                alt={imageAlt}
              />
            )}
          </div>
        )}
        <div className="teaser-card-info">
          {title && <h3 className="teaser-card-title">{trimText(title, CHARLIMIT.TITLE)}</h3>}
          {paragraph && (
            <RichText
              className="teaser-card-desc"
              content={paragraph}
            />
          )}
        </div>
      </div>
      {link && linkText && (
        <div className="teaser-card-action">
          <AnchorButton isSecondary={true} tabindex={0} href={link} isExternal={linkIsExternal}>
            {linkText}
          </AnchorButton>
        </div>
      )}
    </div>
  </Column>
)

export default TeaserCard
