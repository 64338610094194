import { useCallback, useContext, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getDomainLang } from "@utils/helper"
import { Dispatch } from "redux"
import { updateProductFilter } from "store/actions/CommonActionCreators"
import { Button, CheckBox, Column, Icon, Input, ProductDropDown, Row, TextButton } from "@atoms"
import Loader from "@atoms/loader"
import AdvanceSearchFilterBar from "../../molecules/advanceSearchOptions"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"
import { IAdvancedSearchFilterWithAccordionProps } from "./_advanceSearchFilterWithAccordion.interface"
import AccordionFlexEnhanced from "../accordionFlexEnhanced"
import SearchGroup from "../../molecules/search-group"
import ApplicationContext from "@utils/application-context/applicationContext"

const AdvanceSearchFilterWithAccordionEnhanced = (
  props: IAdvancedSearchFilterWithAccordionProps,
) => {
  const {
    searchFieldData,
    labelItemsSelected,
    handleSearch,
    filterData,
    applyFiltertext,
    handleAdditionalFilters,
    applyAdditionalFilters,
    searchArgs,
    currentSearchText = "",
    handleSearchText,
    handleCategorySelection,
    handleClearCategorySelection,
    handleAllFilterClear,
    searchFlag,
    totalProductCount,
    filterText,
    showMoreText,
    showLessText,
    mobileFilterData,
    additionalFilters,
    updateAdditionalFilters,
    getMobileFilterData,
    textSearchLabel,
    availableProductCountText,
    searchedProductCount,
    unselectAllItemLabel,
    searchPlaceholderMobile,
    catTextMobile,
    unselectItems,
    productIsLoading,
    productFilterData,
  } = props

  const [displayFilters, setDisplayFilters] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const [categorySearchText, setCategorySearchText] = useState("")
  const [searchText, setSearchText] = useState<string>(currentSearchText)
  const [clearCatFlag, setClearCatFlag] = useState(false)
  const [keyPressed, setKeyPressed] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<object>({})
  const { applicationConfiguration } = useContext(ApplicationContext)
  const domainLang = getDomainLang()

  const selectedFilterIndex: any = useSelector(
    (state: any) => state?.common?.accordianProductFilterIndex,
    shallowEqual,
  )
  const dispatch: Dispatch<any> = useDispatch()

  const isMobile = useMediaQuery("(max-width: 991px)")

  const updateProductFilterState: any = useCallback(
    (value: any) => dispatch(updateProductFilter(value)),
    [dispatch],
  )

  useEffect(() => {
    if (!searchText) {
      handleClear()
    }
  }, [searchText])
  
  useEffect(() => {
    const filterArray = Array.from(additionalFilters, ([name, value]) => ({ name, value })).reduce(
      (acc: any, it: any) => [...acc, ...it.value],
      [],
    )
    const selectedValues = mobileFilterData.reduce((acc, { label, taxonomies }) => {
      return {
        ...acc,
        [label]: taxonomies.filter((item: any) =>
          filterArray?.filter((id: string) => productFilterData?.includes(id)).includes(item?.id),
        ),
      }
    }, {} as any)
    setSelectedOptions(selectedValues)
  }, [mobileFilterData])

  const handleFilterBtnToggle = (x: boolean) => {
    if (x) {
      getMobileFilterData && getMobileFilterData()
    }
    setDisplayFilters(x)
  }

  const handleClear = () => {
    setIsCleared(!isCleared)
    handleFilterBtnToggle(false)
    // save product filter state in store
    updateProductFilterState(0)
    handleAllFilterClear && handleAllFilterClear()
  }

  const formatMatchString = (arrayLength: number, totalCount: number | undefined) => {
    const searchedText = searchedProductCount?.split("@searchprodcount")
    const availableProducts = availableProductCountText?.split("@prodcount")

    return searchFlag || domainLang === "country-ja" ? (
      <>
        {searchedText && searchedText[0]}
        <span className="search-count">{totalCount}</span>
        {searchedText && searchedText[1]}
      </>
    ) : (
      <>
        <span className="search-count">{totalCount}</span>
        {availableProducts && availableProducts[1]}
      </>
    )
  }

  const handleApply = () => {
    applyAdditionalFilters(searchText)
    handleFilterBtnToggle(false)
  }

  const handleCheck = (e: React.FormEvent<HTMLInputElement>, label: string, item: any) => {
    const isChecked = (e.target as any)?.checked
    const filterList = filterData.map((filter) => {
      return {
        ...filter,
        taxonomies: filter?.taxonomies.map((d: any) => {
          if (d.id === item.id) {
            d.isSelected = isChecked
          }
          return d
        }),
      }
    })
    handleSearch(searchText)
    handleCategorySelection && handleCategorySelection(filterList)

    // save product filter state in store
    filterData.forEach((item: any, index: number) => {
      if (item.label === label) {
        updateProductFilterState(index)
      }
    })
  }

  const handleApplySearch = (text: string) => {
    setSearchText(text)
    handleSearch(text)
  }

  const updateSearchText = (text: string) => {
    handleSearchText(text)
    setSearchText(text)
    if (!text) {
      handleSearch(text)
    }
  }

  const showMore = (resetState?: string) => {
    const val = resetState ? false : !isExpanded
    if (resetState) {
      setCategorySearchText("")
    }
    setIsExpanded(val)
  }

  const getSideBarContent = (data: any) => {
    const filterData = categorySearchText
      ? data?.taxonomies.filter((tax: any) =>
          tax?.label?.toLowerCase()?.includes(categorySearchText.toLowerCase()),
        )
      : data?.taxonomies
    let displayContent
    if (isExpanded) {
      displayContent = filterData
    } else {
      displayContent = filterData.slice(0, 8)
    }
    const selectedTaxonomies = displayContent.filter((tax: any) => tax.isSelected === true)
    const unSelectedTaxonomies = displayContent.filter((tax: any) => tax.isSelected === false)
    const content = (
      <>
        {selectedTaxonomies.length > 0 &&
          selectedTaxonomies.map((taxonomy: any) => (
            <CheckBox
              id={taxonomy?.label}
              key={taxonomy?.id}
              value={taxonomy?.id}
              label={taxonomy?.label}
              isChecked={taxonomy?.isSelected}
              onChange={(isChecked: any) => handleCheck(isChecked, data.label, taxonomy)}
            />
          ))}
        {selectedTaxonomies.length > 0 && unSelectedTaxonomies.length > 0 && <hr />}
        {unSelectedTaxonomies.length > 0 &&
          unSelectedTaxonomies.map((taxonomy: any) => (
            <CheckBox
              id={taxonomy?.label}
              key={taxonomy?.id}
              value={taxonomy?.id}
              label={taxonomy?.label}
              isChecked={taxonomy?.isSelected}
              onChange={(isChecked: any) => handleCheck(isChecked, data.label, taxonomy)}
            />
          ))}
        {filterData && filterData?.length > 8 && (
          <div className="cat-btn-text">
            <TextButton onClick={() => showMore()} tabindex={1}>
              {isExpanded
                ? showLessText
                : `${showMoreText} (${filterData?.length - displayContent?.length})`}
            </TextButton>
          </div>
        )}
      </>
    )
    return content
  }

  const getLangSpecificTitle = (selectedTaxonomies: any, totalTaxonomies: any) => {
    const fesProductSelectCount =
      applicationConfiguration?.siteConfig?.fes_product_select_count ?? ""
    return `(${fesProductSelectCount
      .replace(/@selectedprodcount/g, selectedTaxonomies)
      .replace(/@totalprodcount/g, totalTaxonomies)})`
  }

  const getFormattedTitle = (item: any) => {
    const selectedTaxonomies = item?.taxonomies?.filter((tax: any) => tax.isSelected === true)
    return selectedTaxonomies?.length > 0
      ? getLangSpecificTitle(selectedTaxonomies?.length, item?.taxonomies?.length)
      : `(${item?.taxonomies?.length})`
  }

  const getUnselectAllBtn = (selectedTaxonomies: any, vid: string) => {
    return (
      selectedTaxonomies.length > 0 && (
        <div className="cat-unselect-btn cat-btn-text">
          <TextButton
            onClick={() => handleClearCategorySelection && handleClearCategorySelection(vid)}
            tabindex={1}>
            {unselectAllItemLabel}
          </TextButton>
        </div>
      )
    )
  }

  const getCategoryFilterBox = (item: any) => {
    const handleInputChange = (e: any) => {
      setCategorySearchText(e.target.value)
    }

    const filterBox = (
      <>
        {item?.taxonomies?.length > 8 ? (
          <>
            <label id={item?.vid} htmlFor={item?.vid} />
            <Input
              className={`search-group-input-align search-box search-group-input-align search-icon-category ${
                keyPressed ? "search-box-onkeypress" : "search-box-searchicon"
              }`}
              value={categorySearchText}
              id={item?.vid}
              type="filter"
              useStateValue={false}
              onChange={handleInputChange}
              placeholder={filterText}
              onKeyDown={() => setKeyPressed(true)}
              onBlur={() => setKeyPressed(false)}
            />
          </>
        ) : (
          <></>
        )}
      </>
    )

    return filterBox
  }

  const handleClearMobileCategorySelection = (label: any) => {
    additionalFilters.set(label, [])
    const filterArray = Array.from(additionalFilters, ([name, value]) => ({ name, value })).reduce(
      (acc: any, it: any) => [...acc, ...it.value],
      [],
    )
    const selectedValues = filterData.reduce((acc, { label, taxonomies }) => {
      return { ...acc, [label]: taxonomies.filter((item: any) => filterArray.includes(item?.id)) }
    }, {} as any)
    setClearCatFlag(true)
    updateAdditionalFilters(additionalFilters)
    setSelectedOptions(selectedValues)
  }

  const updatedDynamicFilter = () => {
    const updatedData = filterData?.map((filter: any) => {
      return {
        label: filter?.label ?? "",
        vid: filter?.vid ?? "",
        taxonomies: filter?.taxonomies.filter((tax: any) => productFilterData?.includes(tax.id)),
      }
    })

    return updatedData?.map((item: any) => {
      const selectedTaxonomies = item?.taxonomies?.filter((tax: any) => tax.isSelected === true)
      return {
        title: item?.label,
        selectionCount: getFormattedTitle(item),
        categoryFilterBox: getCategoryFilterBox(item),
        unselectCat: getUnselectAllBtn(selectedTaxonomies, item?.vid),
        content: getSideBarContent(item),
      }
    })
  }

  const accordionArgs = {
    accordionArray: updatedDynamicFilter(),
  }

  const applyButton = (
    <div className="search-filter-bar-hcontrol search-filter-bar-modal-element-applyBox">
      <Button
        type="button"
        icon={<Icon iconName="CheckedIcon" />}
        iconPosition="right"
        className="search-button"
        tabindex={1}
        onClick={handleApply}>
        {applyFiltertext}
      </Button>
    </div>
  )

  return (
    <>
      {!isMobile && (
        <div className="advanceSearchFilterWithAccordion-sidebar scroll-top">
          <SearchGroup
            {...searchArgs}
            handleTextSearch={handleSearch}
            handleTextChange={updateSearchText}
            currentSearchText={currentSearchText}
            buttonType="button"
            className="advanceSearchFilterWithAccordion-search"
            inputLabel={textSearchLabel}
            customButtonText={searchFieldData?.buttonText ?? ""}
          />
          {!productIsLoading ? (
            <>
              <div className="group-result-and-filter">
                <h5 className="advanceSearchFilterWithAccordion-resultcount">
                  {formatMatchString(props?.resultLength, totalProductCount)}
                </h5>
                {/* <span className="search-filter-bar-modal-element-filter-clear advanceSearchFilterWithAccordion-clearFilter">
              <span aria-hidden="true" onClick={handleClear}>
                {clearFilterText}
              </span>
            </span> */}
              </div>
              {productFilterData?.length > 0 && (
                <div className="advanceSearchFilterWithAccordion-accordion">
                  <AccordionFlexEnhanced
                    selectedIndex={selectedFilterIndex}
                    resetState={showMore}
                    {...accordionArgs}
                  />
                </div>
              )}
            </>
          ) : (
            <Loader display={productIsLoading} />
          )}
        </div>
      )}
      {isMobile && (
        <div className="search-filter-section scroll-top">
          {productFilterData?.length > 0 && (
            <Row
              className={`search-filter-clear ${!displayFilters && isMobile ? "hideFilters" : ""}`}>
              <Column
                desktop={12}
                tablet={12}
                mobile={12}
                className="search-filter-clear-container search-filter-top">
                <span className="search-filter-bar-modal-overlay">&nbsp;</span>
                <div className="search-filter-bar-modal-element">
                  {mobileFilterData?.map(({ label, isSelected, title, taxonomies }) => (
                    taxonomies?.length > 0 ?
                    <div
                      key={label}
                      className={`search-filter-bar-element ${
                        !displayFilters && isMobile ? "hideFilters" : ""
                      }`}>
                      <div className="mobile-cat-name-count">
                        <span className="cat-selection">
                          <span>{label}</span>
                          <span>{title}</span>
                        </span>
                        {isSelected && (
                          <TextButton
                            className="mobile-unselect-cat"
                            onClick={() => handleClearMobileCategorySelection(label)}
                            tabindex={1}>
                            {unselectItems}
                          </TextButton>
                        )}
                      </div>
                      <ProductDropDown
                        options={taxonomies.map((item: any) => ({
                          id: item?.id,
                          value: item?.id,
                          label: item?.label,
                        }))}
                        isMulti
                        // labelItemsSelected={labelItemsSelected}
                        isCleared={isCleared}
                        intialValue={selectedOptions?.[label as keyof typeof selectedOptions] ?? []}
                        onValueChange={(val) => handleAdditionalFilters?.(label, val)}
                        ariaLabel=""
                        name=""
                        clearCatFlag={clearCatFlag}
                        setClearCatFlag={setClearCatFlag}
                        catTextMobile={catTextMobile}
                      />
                    </div>
                    : <></>
                  ))}
                  {applyButton}
                  <div className="search-filter-bar-modal-element-filter-clear">
                    <span aria-hidden="true" onClick={handleClear}>
                      {unselectAllItemLabel}
                      <span className="icon">
                        <Icon iconName="CrossIcon" />
                      </span>
                    </span>
                  </div>
                </div>
              </Column>
            </Row>
          )}
          <Row className="search-filter-action">
            <Column desktop={12} tablet={12} mobile={12}>
              <AdvanceSearchFilterBar
                {...searchFieldData}
                labelItemsSelected={labelItemsSelected}
                handleFilterBtnToggle={handleFilterBtnToggle}
                handleSearch={handleApplySearch}
                isCleared={isCleared}
                handleAdditionalFilters={handleAdditionalFilters}
                currentSearchText={searchText}
                handleSearchText={handleSearchText}
                inputLabel={textSearchLabel}
                searchPlaceholderMobile={searchPlaceholderMobile}
              />
              {!productIsLoading ? (
                <h5 className="advanceSearchFilterWithAccordion-resultcount">
                  {formatMatchString(props?.resultLength, totalProductCount)}
                </h5>
              ) : (
                <></>
              )}
            </Column>
          </Row>
        </div>
      )}
    </>
  )
}

export default AdvanceSearchFilterWithAccordionEnhanced
