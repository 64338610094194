export const USER_DATA = "USER_DATA"
export const RESET_USER = "RESET_USER"

// user profile
export const SET_USER_PROFILE = "SET_USER_PROFILE"
export const GET_USER_PROFILE = "GET_USER_PROFILE"

// bookmarks
export const ADD_RATINGS = "ADD_RATINGS"
export const RESET_RATINGS = "RESET_RATINGS"

// Favorite & star ratings
export const RATINGS_COUNT = "GET_RATINGS_COUNT"

// product filter check
export const ACCORDIAN_PRODUCT_FILTER_INDEX = "ACCORDIAN_PRODUCT_FILTER_INDEX"
export const ADD_PRODUCT_FILTER = "ADD_PRODUCT_FILTER"
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT"
export const SET_ORDER = "SET_ORDER"
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER"
export const PRODUCT_CATALOG_TITLE = "PRODUCT_CATALOG_TITLE"

// app id action
export const SET_REF_APP_ID = "SET_REF_APP_ID"

// paywall content
export const PAYWALL_CONTENT = "PAYWALL_CONTENT"
export const RESET_PAYWALL_3_ROLES = "RESET_PAYWALL_3_ROLES"
export const SET_PAYWALL_3_ROLES = "SET_PAYWALL_3_ROLES"

// reload content
export const IS_RELOADED = "IS_RELOADED"

// advance filter
export const ADD_UPDATE_FILTERS = "ADD_UPDATE_FILTERS"
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER"
export const UPDATE_ADVANCE_SEARCH_TEXT = "UPDATE_ADVANCE_SEARCH_TEXT"
export const UPDATE_ADVANCE_FILTERS = "UPDATE_ADVANCE_FILTERS"

// job search
export const ADD_UPDATE_JOBS_FILTERS = "ADD_UPDATE_JOBS_FILTERS"
export const UPDATE_JOBS_PAGE_NUMBER = "UPDATE_JOBS_PAGE_NUMBER"
export const UPDATE_JOBS_SEARCH_TEXT = "UPDATE_JOBS_SEARCH_TEXT"
export const UPDATE_ALL_JOBS_FILTERS = "UPDATE_ALL_JOBS_FILTERS"
export const UPDATE_JOB_SORT = "UPDATE_JOB_SORT"

// po search
export const ADD_UPDATE_PO_LIST_FILTERS = "ADD_UPDATE_PO_LIST_FILTERS"
export const UPDATE_PO_LIST_PAGE_NUMBER = "UPDATE_PO_LIST_PAGE_NUMBER"
export const UPDATE_PO_LIST_SEARCH_TEXT = "UPDATE_PO_LIST_SEARCH_TEXT"
export const UPDATE_ALL_PO_LIST_FILTERS = "UPDATE_ALL_PO_LIST_FILTERS"
export const UPDATE_PO_LIST_SORT = "UPDATE_PO_LIST_SORT"

// Po detail search
export const ADD_UPDATE_PO_DETAIL_FILTERS = "ADD_UPDATE_PO_DETAIL_FILTERS"
export const UPDATE_PO_DETAIL_PAGE_NUMBER = "UPDATE_PO_DETAIL_PAGE_NUMBER"
export const UPDATE_PO_DETAIl_SEARCH_TEXT = "UPDATE_PO_DETAIl_SEARCH_TEXT"
export const UPDATE_ALL_PO_DETAIL_FILTERS = "UPDATE_ALL_PO_DETAIL_FILTERS"
export const UPDATE_PO_DETAIL_SORT = "UPDATE_PO_DETAIL_SORT"

// Co detail search
export const ADD_UPDATE_CO_LIST_FILTERS = "ADD_UPDATE_PO_LIST_FILTERS"
export const UPDATE_CO_LIST_PAGE_NUMBER = "UPDATE_PO_LIST_PAGE_NUMBER"
export const UPDATE_CO_LIST_SEARCH_TEXT = "UPDATE_PO_LIST_SEARCH_TEXT"
export const UPDATE_ALL_CO_LIST_FILTERS = "UPDATE_ALL_PO_LIST_FILTERS"
export const UPDATE_CO_LIST_SORT = "UPDATE_PO_LIST_SORT"

// User details
export const ADD_UPDATE_USER_DETAILS_FILTERS = "ADD_UPDATE_USER_DETAILS_FILTERS"
export const UPDATE_USER_DETAILS_PAGE_NUMBER = "UPDATE_USER_DETAILS_PAGE_NUMBER"
export const UPDATE_USER_DETAILS_SEARCH_TEXT = "UPDATE_USER_DETAILS_SEARCH_TEXT"
export const UPDATE_ALL_USER_DETAILS_FILTERS = "UPDATE_ALL_USER_DETAILS_FILTERS"
export const UPDATE_USER_DETAILS_SORT = "UPDATE_USER_DETAILS_SORT"

// User details
export const ADD_UPDATE_USER_ACTION_FILTERS = "ADD_UPDATE_USER_ACTION_FILTERS"
export const UPDATE_USER_ACTION_PAGE_NUMBER = "UPDATE_USER_ACTION_PAGE_NUMBER"
export const UPDATE_USER_ACTION_SEARCH_TEXT = "UPDATE_USER_ACTION_SEARCH_TEXT"
export const UPDATE_ALL_USER_ACTION_FILTERS = "UPDATE_ALL_USER_ACTION_FILTERS"
export const UPDATE_USER_ACTION_SORT = "UPDATE_USER_ACTION_SORT"

// common - user login & type
export const USER_CATEGORY = "USER_CATEGORY"

// webshop
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART"
export const UPDATE_PRODUCT_IN_CART = "UPDATE_PRODUCT_IN_CART"
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART"
export const EMPTY_CART = "EMPTY_CART"
export const ADD_PRODUCT_TO_CART_TEMPORARY = "ADD_PRODUCT_TO_CART_TEMPORARY"
export const ADD_PRODUCT_TO_CART_FROM_TEMP = "ADD_PRODUCT_TO_CART_FROM_TEMP"

export const ADD_UPDATE_WEBSHOP_FILTERS = "ADD_UPDATE_WEBSHOP_FILTERS"
export const UPDATE_WEBSHOP_PAGE_NUMBER = "UPDATE_WEBSHOP_PAGE_NUMBER"
export const UPDATE_WEBSHOP_SEARCH_TEXT = "UPDATE_WEBSHOP_SEARCH_TEXT"
export const UPDATE_ALL_WEBSHOP_FILTERS = "UPDATE_ALL_WEBSHOP_FILTERS"

// application error page
export const APPLICATION_ERROR_PAGE = "APPLICATION_ERROR_PAGE"

// Magazine
export const ADD_MAGAZINE_TAXONOMY = "ADD_MAGAZINE_TAXONOMY"
export const MAGAZINE_LAYOUT = "MAGAZINE_LAYOUT"
export const MAGAZINE_DATA = "MAGAZINE_DATA"
export const MAGAZINE_TEMP_DATA = "MAGAZINE_TEMP_DATA"
export const CLEAR_MAGAZINE_TEMP_DATA = "CLEAR_MAGAZINE_TEMP_DATA"
export const MAGAZINE_PARENT_TAB = "MAGAZINE_PARENT_TAB"
export const MAGAZINE_CHILD_TAB = "MAGAZINE_CHILD_TAB"

// application session
export const SET_SESSION = "SET_SESSION"
export const CLEAR_SESSION = "CLEAR_SESSION"
export const SET_APPLICATION_ID = "SET_APPLICATION_ID"

// isi banner
export const ADD_ISI_BANNER = "ADD_ISI_BANNER"

// interface search
export const ADD_UPDATE_INTERFACE_SEARCH = "ADD_UPDATE_INTERFACE_SEARCH"
export const UPDATE_INTERFACE_PAGE_NUMBER = "UPDATE_INTERFACE_PAGE_NUMBER"
export const UPDATE_INTERFACE_SEARCH_TEXT = "UPDATE_INTERFACE_SEARCH_TEXT"
export const UPDATE_ALL_INTERFACE_SEARCH = "UPDATE_ALL_INTERFACE_SEARCH"
export const UPDATE_INTERFACE_SORT = "UPDATE_INTERFACE_SORT"

//academy course
export const UPDATE_TAB = "UPDATE_TAB"
export const CHANGE_TAB = "CHANGE_TAB"
export const CHANGE_TAB_BTN = "CHANGE_TAB_BTN" 
export const MODULE_ID = "MODULE_ID"
