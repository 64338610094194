import { DATE_FORMATS } from "@helpers/constants/generic"
import { getApi } from "@utils/baseApi"
import { filterNullValues } from "@utils/paywallUtilities"
import moment from "moment"

const getModulesInfoSectionData = async (url: string) => {
  const rawData = (await getApi(url.replace(/\?.*/, ""), false)) as any

  const getActivitiesData = (activities: any) => {
    return activities?.map((item: any, index: number) => ({
      title: item?.field_activity_title ?? "",
      desktopColumns: 3,
      tabletColumns: 1,
      id: 23456,
      cardImage: {
        src: item?.field_activity_image?.[0]?.src ?? "",
        alt: item?.field_activity_image?.[0]?.alt ?? "",
      },
      difficultyLevel: {
        label: item?.field_date_of_commencement
          ? moment(item?.field_date_of_commencement).format(DATE_FORMATS.MMM_DD_YYYY)
          : "",
        icon: "CalendarIcon1",
      },
      courseDuration: {
        label: item?.field_activity_time,
        icon: "TimeIcon",
      },
      taxonomies: item?.field_course_topic_tag?.length && item.field_course_topic_tag.map((item: any) => ({
        taxonomyLabel: item?.name ?? "",
      })) || [],
    }))
  }

  const responseData = rawData?.map((item: any) => ({
    modulesInformation: {
      title: item?.title ?? "",
      trainingTime: item?.field_training_time ?? "",
      desc: item?.body ?? "",
      keyOpinion: item?.field_key_opinion_leaders ?? "",
      nid: item?.nid ?? "",
      ctaLabel: item?.view_all_cta_label ?? "",
      ctaLink: item?.view_all_cta_link ?? "",
      moduleHeading: item?.field_module_heading ?? "",
    },
    carouselData: {
      duration: 0,
      carouselBlockType: "catalogue",
      itemPerSlide: getActivitiesData(item?.activities)?.slice(0, 5)?.length > 1 ? 2 : 1,
      slidesInfo: filterNullValues(getActivitiesData(item?.activities)?.slice(0, 5)) ?? [],
      enableException: true,
      slideItemsForTablet: 1,
      slideItemsForMobile: 1,
    },
  }))
  
  return {
    responseData: responseData,
    activityHeading: rawData?.[0]?.module_activity_heading ?? "",
  }
}

export default getModulesInfoSectionData
