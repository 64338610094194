import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AnchorButton, Seperator } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import SearchFilterTable from "@organisms/searchFilterTable"
import { Pagination, TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import moment from "moment"
import { getApiDomainAndLang, middlewareGETAPI } from "@utils/baseApi"
import { getPostedDatesOptions } from "@helpers/dataFunctions/getJobSearchData"
import { fetchTableData, getFilterLabelData } from "@helpers/dataFunctions/getPOListSearchData"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { connect, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import _ from "lodash"
import { ColumnNameType } from "./_purchase-order-search-filter.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import {
  addUpdatePOListFilter,
  updateAllPOListFilters,
  updatePOListPagination,
  updatePOListSearchText,
  updatePOListSort,
} from "store/actions/POListSearchActionCreators"
import { SelectWithLabel } from "@organisms/interfaceSearch/_interface-search.interface"
import { ARITHMETIC_KEYS, URL_TARGET } from "@helpers/constants/generic"

const ITEMS_PER_PAGE = 20
const DATE_FORMAT = "YYYY-MM-DD"

interface DispatchProps {
  addUpdatePOListFilter: (data: any) => void
  updatePOListPagination: (data: number) => void
  updatePOListSearchText: (data: string) => void
  updateAllPOListFilters: (data: any, page: number, str: string, sort?: any) => void
  updatePOListSort: (data: any) => void
}

type StateTypes = {
  poListSearchFilterData: {
    poListFilters: any
    currentPage: number
    searchText: string
    sort: {
      column: string
      order: string
    }
  }
}

type PoListingProps = {
  blockUrl: string
}

const sortKeyPair: ColumnNameType = {
  customerPo: "po_number",
  poStatus: "po_status",
  dateOrdered: "date_ordered",
  dateShipped: "date_shipped",
}
type Props = DispatchProps & StateTypes & PoListingProps
const PurchaseOrderSearchFilter = (props: Props) => {
  const { poListSearchFilterData, blockUrl } = props
  const { poListFilters, currentPage, searchText, sort } = poListSearchFilterData
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortValue, setSortValue] = useState<any>({ value: "", order: 0 })
  const [poList, setPOList] = useState<searchFilterType[]>([])
  const [sortedList, setSortedList] = useState<searchFilterType[]>([])
  const [filterDetails, setFilterDetails] = useState<any>({})
  const [resultCount, setResultCount] = useState<number>(0)
  const [isUpdated, setIsUpdated] = useState(false)
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>(poListFilters)
  const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE)
  const [openOrders, setOpenOrders] = useState<number>(0)
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")

  const isMobile = useMediaQuery("(max-width: 991px)")
  const router = useRouter()
  const disabledColumnSort = ["trackingNumber", "packingSlip", "invoice"]
  const filterList: searchFilterType[] = sortValue ? sortedList : poList
  const { lang } = getApiDomainAndLang()

  const { applicationConfiguration } = useContext(ApplicationContext)

  const dispatch: Dispatch<any> = useDispatch()

  const updatePOListFilterState: any = useCallback(
    (value: any) => {
      props.addUpdatePOListFilter(value)
    },
    [dispatch],
  )
  const updatePaginationState: any = useCallback(
    (value: number) => props.updatePOListPagination(value),
    [dispatch],
  )

  const updateSearchTextState: any = useCallback(
    (searchText: string) => props.updatePOListSearchText(searchText),
    [dispatch],
  )
  const updateAllFiltersState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props.updateAllPOListFilters(value, page, searchText, sort),
    [dispatch],
  )

  const updatePOListSortState: any = useCallback(
    (value: any) => props.updatePOListSort(value),
    [dispatch],
  )

  const checkIfValueExistsInQuery = (queryStr: string, value: string) => {
    const selectedIds = queryStr ? queryStr.split(",") : []
    return selectedIds.includes(value)
  }

  const checkIfDateFilterExistsInQuery = (queryStr: any, name: string) => {
    return queryStr?.[name]
  }

  const dateURL = (addFilterKey?: boolean) => {
    const startDate = poListFilters?.find(
      (item: SelectWithLabel) => item?.filterName === "field_start_date",
    )?.value
    const endDate = poListFilters?.find(
      (item: SelectWithLabel) => item?.filterName === "field_end_date",
    )?.value

    const dateWith100YRS = (date: Date, action: string) => {
      const newDate = new Date(date)
      action === ARITHMETIC_KEYS.ADDITION
        ? newDate.setFullYear(newDate.getFullYear() + 100)
        : newDate.setFullYear(newDate.getFullYear() - 100)
      return moment(newDate).locale(lang).format(DATE_FORMAT)
    }

    const startDateKey = !addFilterKey ? "field_start_date" : "filter[field_start_date]"
    const enDateKey = !addFilterKey ? "field_end_date" : "filter[field_end_date]"

    if (startDate) {
      if (endDate) {
        return `&${startDateKey}=${startDate}&${enDateKey}=${endDate}`
      } else {
        return `&${startDateKey}=${startDate}&${enDateKey}=${dateWith100YRS(
          startDate,
          ARITHMETIC_KEYS.ADDITION,
        )}`
      }
    } else if (endDate) {
      return `&${startDateKey}=${dateWith100YRS(
        endDate,
        ARITHMETIC_KEYS.SUBSTRACTION,
      )}&${enDateKey}=${endDate}`
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (!applicationConfiguration?.siteConfig?.cc_po_listing) {
      return
    }

    const fetchFilterDetails = () => {
      const configData = getFilterLabelData(applicationConfiguration.siteConfig)
      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        unselectFiltersCTA: configData.clearLabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
        applyFiltersCTA: configData.applyFiltersCTALabel,
        unSelectItemsLabel: configData.unSelectItemsLabel,
        sortByCTAText: configData.sortByLabel,
        filterIconName: configData.filter_toggle_label,
      }

      const filtersOptions = [
        {
          label: configData.poStatusLabel,
          filterName: "po_status",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: getPostedDatesOptions(configData.poStatusOptions),
            ariaLabel: configData.poStatusLabel,
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
      ]
      const startDateValues = {
        label: configData?.dateRangeLabel,
        placeHolderTxt: configData?.dateRangePlaceholder,
        filterName: "field_start_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }
      const endDateValues = {
        label: configData.dateRangeToLabel,
        placeHolderTxt: configData?.dateRangeToPlaceholder,
        filterName: "field_end_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }

      configData?.dateRangeToLabel && filtersOptions.unshift(endDateValues)
      configData?.dateRangeLabel && filtersOptions.unshift(startDateValues)

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          customerPo: "po_number",
          poStatus: "po_status",
          dateOrdered: "date_ordered",
          dateShipped: "date_shipped",
          trackingNumber: "tracking_no",
          packingSlip: "packing_slip",
          invoice: "invoice_number",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(configData.tableColumns)
      const displayConfig = {
        tableColumns: Object.values(configData.tableColumns),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
        noResultsDesc: configData.noResultsDesc,
        sortByLabel: configData.sortByLabel,
        searchUrl: `${blockUrl}`,
        showFiltersCTALabel: configData.showFiltersCTALabel || "Show Filters",
        applyFiltersCTALabel: configData.applyFiltersCTALabel || "Apply the filters",
        filterIconName: configData.filter_toggle_label,
        openOrderCTALabel: configData?.openOrderCTALabel,
        openOrderCTAText: configData?.openOrderCTAText,
        openOrderCTALink: configData?.openOrderCTALink,
        searchTitle: configData?.searchTitle,
        searchDesc: configData?.searchDesc,
      }

      const filterDetailsObj = {
        poSearchContent: filtersOptions,
        filtersLabels: filterLabelsData,
        displayConfig,
        tableColumns: allColumns,
      }
      const selectedCategories = router?.query
      const tempSele = selectedCategories
      const urlFilters = Object.keys(tempSele)

      if (urlFilters.length === 1 && urlFilters[0] === "slug") {
        const updatedSelectOptions = filtersOptions?.map((item) => ({
          ...item,
          selectOptions: {
            ...item.selectOptions,
            options: item.selectOptions.options?.map((val: any) => ({
              ...val,
              isSelected:false,
            })),
          },
        }))
        updatePOListFilterState(updatedSelectOptions)
      } else {
        const updatedSelectOptions = filtersOptions?.map((item: any) => {
          const dateExists = checkIfDateFilterExistsInQuery(selectedCategories, item?.filterName)
          if (!item?.placeHolderTxt) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val: any) => {
                  // @ts-ignore
                  return {
                    ...val,
                    isSelected:
                      selectedCategories?.[item?.filterName] &&
                      !!checkIfValueExistsInQuery(
                        // @ts-ignore
                        selectedCategories?.[item?.filterName],
                        val?.value?.toString(),
                      ),
                  }
                }),
              },
            } 
          } else {
            const dateQuery = dateExists && dateExists.split(" ")?.[0] ? 
                  moment(dateExists.split(" ")?.[0]).locale(lang).format(DATE_FORMAT) : undefined
            item?.filterName === "field_start_date" && dateQuery && setStartDate(dateQuery)
            item?.filterName === "field_end_date" && dateQuery && setEndDate(dateQuery)
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val: any) => {
                  // @ts-ignore
                  return {
                    ...val,
                    isSelected:
                      selectedCategories?.[item?.filterName] &&
                      !!checkIfValueExistsInQuery(
                        // @ts-ignore
                        selectedCategories?.[item?.filterName],
                        val?.value?.toString(),
                      ),
                  }
                }),
              },
              value: dateQuery,
            } 
          }
        })

        const sortFilter = {
          column: selectedCategories?.sort_field || "",
          order: selectedCategories?.sort_order || "ASC",
        }
        updateAllFiltersState(
          updatedSelectOptions,
          Number(selectedCategories?.page ?? 0),
          selectedCategories?.search || "",
          sortFilter,
        )
      }
      setFilterDetails(filterDetailsObj)
      setIsUpdated(true)
    }
    fetchFilterDetails()
  }, [applicationConfiguration])

  const handleSearch = (text: string) => {
    updateSearchTextState(text)
  }

  const handleClick = (label: string, value: IOption) => {
    const alteredFilter = poListFilters?.map(
      (item: { filterName: string; selectOptions: { options: any[] }; placeHolderTxt: string }) => {
        if (item.filterName === label) {
          if (!item?.placeHolderTxt) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => {
                  if (val.id === value?.id) {
                    return {
                      ...val,
                      isSelected: false,
                    }
                  }
                  return val
                }),
              },
            }
          } else {
            item?.filterName === "field_start_date" && setStartDate("")
            item?.filterName === "field_end_date" && setEndDate("")
            return {
              ...item,
              value: "",
            }
          }
        }
        return item
      },
    )
    updatePOListFilterState(alteredFilter)
  }
  const handleSortWhole = (key: string) => {
    if (sort.column === "") {
      updatePOListSortState({ column: key, order: "ASC" })
    } else if (sort.column && sort.column === key) {
      if (sort.order === "ASC") {
        updatePOListSortState({ column: key, order: "DESC" })
      } else if (sort.order === "DESC") {
        updatePOListSortState({ column: key, order: "ASC" })
      }
    } else {
      updatePOListSortState({ column: key, order: "ASC" })
    }
  }

  const handleSortKey = (key: string) => {
    if (sort.column === "" || sort.column !== key) {
      updatePOListSortState({ column: key, order: sort?.order })
    } else {
      updatePOListSortState({ column: "", order: sort?.order })
    }
  }

  const handleSortOrder = () => {
    updatePOListSortState({ column: sort?.column, order: sort.order === "ASC" ? "DESC" : "ASC" })
  }

  const getUrlParams = (selectedFilters: Array<IOption>, filterName: string, passAll = false) => {
    let url = ""
    if (selectedFilters?.length && !passAll) {
      if (selectedFilters?.length > 1) {
        // selectedFilters.forEach((element: any, index: any) => {
        //   url += `&filter[${filterName}][${index}]=${element.id}`
        // })
        url += `&filter[${filterName}]`
        selectedFilters.forEach((element: any, index: any) => {
          url += `${index === 0 ? "=" : ","}${element.id}`
        })
      } else {
        url += `&filter[${filterName}]=${selectedFilters[0].id}`
      }
    }
    return url
  }

  const checkIfValueExists = (val: any, valArr: any) =>
    valArr.findIndex((item: any) => item.id === val.id) > -1

  const handleAdditionalFilters = (label: string, value: any) => {
    if (isMobile) {
      const alteredFilter = localSelectedFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] }, placeHolderTxt: string }) => {
          if (item.filterName === label) {
            if (!item?.placeHolderTxt) {
              return {
                ...item,
                selectOptions: {
                  ...item.selectOptions,
                  options: item.selectOptions.options?.map((val) => ({
                    ...val,
                    isSelected: checkIfValueExists(val, value),
                  })),
                },
              }
            } else {
              const newVal = value && value?.length ? value : ""
              item?.filterName === "field_start_date" && setStartDate(newVal)
              item?.filterName === "field_end_date" && setEndDate(newVal)
              return {
                ...item,
                value: newVal
                  ? moment(Date.parse(newVal))
                      .locale(lang)
                      .format(DATE_FORMAT)
                  : "",
              }
            }
          }
          return item
        },
      )
      setLocalSelectedFilters(alteredFilter)
    } else {
      const alteredFilter = poListFilters?.map(
        (item: {
          filterName: string
          selectOptions: { options: any[] }
          placeHolderTxt: string
        }) => {
          if (item.filterName === label) {
            if (!item?.placeHolderTxt) {
              return {
                ...item,
                selectOptions: {
                  ...item.selectOptions,
                  options: item.selectOptions.options?.map((val) => ({
                    ...val,
                    isSelected: checkIfValueExists(val, value),
                  })),
                },
              }
            } else {
              item?.filterName === "field_start_date" && setStartDate(value ?? "")
              item?.filterName === "field_end_date" && setEndDate(value ?? "")
              return {
                ...item,
                value: value
                  ? moment(Date.parse(value))
                      .locale(lang)
                      .format(DATE_FORMAT)
                  : "",
              }
            }
          }
          return item
        },
      )
      updatePOListFilterState(alteredFilter)
    }
  }

  const frameUrl = ({ filterName, selectedOptions }: any) => {
    const currentFilter = poListFilters.find((item: any) => item.filterName === filterName)
      .selectOptions.options
    return getUrlParams(
      selectedOptions,
      filterName,
      selectedOptions?.length === currentFilter?.length,
    )
  }

  const fetchAllSelectedFilters = () => {
    const selectedValues = {} as any
    poListFilters?.forEach(
      (item: {
        filterName: string
        selectOptions: { options: any[] }
        placeHolderTxt: string
        value: string
      }) => {
        const formattedDate = moment(Date.parse(item?.value))
          .locale(lang)
          .format(DATE_FORMAT)
        selectedValues[`${item.filterName}`] = !item?.placeHolderTxt
          ? item.selectOptions.options
              ?.filter((val) => val.isSelected)
              .map(({ isSelected, ...rest }: any) => rest)
          : item?.value
          ? [{ label: formattedDate, value: formattedDate }]
          : []
      },
    )
    return selectedValues
  }

  useMemo(() => {
    const selectedFiltersList = poListFilters?.map((item: any) => ({
      filterName: item.filterName,
      selectedOptions: item.selectOptions.options?.filter((val: any) => val.isSelected),
    }))
    const urlValue = selectedFiltersList
      ?.map((filter: any) => frameUrl(filter))
      .join("")
      ?.substring(1)
    const searchUrlStr = searchText
      ? `&filter[po_ndc_number]=${encodeURIComponent(searchText)}`
      : ""
    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""

    const getTableData = async () => {
      setIsLoading(true)
      const response = await middlewareGETAPI(
        `${blockUrl}?${urlValue}${searchUrlStr}${sortString}${dateURL(true)}&page=${currentPage}`,
      )
      if (response && response.response) {
        const formatedTableData = fetchTableData(response?.response)
        setPOList(formatedTableData)
        setSortedList(formatedTableData)
        // @ts-ignore
        const totalResults = response?.response?.meta?.count
        // @ts-ignore
        const itemsToRender = Number(response?.response?.number_of_items_per_page) ?? ITEMS_PER_PAGE
        // @ts-ignore
        const openOrderCount = response?.response?.meta?.open_orders_count ?? NUMBERS.ZERO
        setItemsPerPage(itemsToRender)
        setOpenOrders(openOrderCount)
        setResultCount(totalResults)
      }
      setIsLoading(false)
    }
    if (isUpdated) {
      getTableData().then(() => {
        const selectedFilterList = fetchAllSelectedFilters()
        const obj = {} as any
        Object.entries(selectedFilterList).forEach(([key, value]: any) => {
          obj[key] = value.length ? value.map((u: any) => u.value).join(",") : ""
        })
        const { protocol, host, pathname } = window.location
        let urlQueryString = ""
        Object.entries(obj).forEach(([key, value]: any) => {
          urlQueryString += `&${key}=${value}`
        })
        const sortUrl = sort?.column ? `&sort_field=${sort?.column}&sort_order=${sort?.order}` : ""
        const newurl = `${protocol}//${host}${pathname}?${urlQueryString.substring(
          1,
        )}&page=${currentPage}` // &search=${searchText || ""}${sortUrl}`
        window.history.replaceState({ path: newurl }, "", newurl)
        setLocalSelectedFilters(poListFilters)
      })
    }
  }, [poListSearchFilterData, isUpdated])

  const applyAdditionalFilters = () => {
    updatePOListFilterState(localSelectedFilters)
  }

  const handlePagination = (offSet: number) => {
    updatePaginationState(offSet / itemsPerPage)
  }

  const clearAllFilters = () => {
    const clearedFilters = poListFilters?.map((item: any) => {
      if (!item?.placeHolderTxt) {
        return {
          ...item,
          selectOptions: {
            ...item.selectOptions,
            options: item.selectOptions.options?.map((val: any) => {
              // @ts-ignore
              return {
                ...val,
                isSelected: false,
              }
            }),
          },
        }
      } else {
        item.filterName === "field_start_date" && setStartDate("")
        item.filterName === "field_end_date" && setEndDate("")
        return {
          ...item,
          value: "",
        }
      }
    })
    updateAllFiltersState(clearedFilters, 0, searchText || "")
  }

  const fetLocalSelectedValue = () => {
    const selectedValues = {} as any
    localSelectedFilters?.forEach(
      (item: { filterName: string; selectOptions: { options: any[] }; placeHolderTxt: string; value: string }) => {
        selectedValues[`${item.filterName}`] = !item?.placeHolderTxt ? item.selectOptions.options
          ?.filter((val) => val.isSelected)
          .map(({ isSelected, ...rest }: any) => rest) : item?.value
          ? [{ label: moment(Date.parse(item?.value)).locale(lang).format(DATE_FORMAT), 
            value: moment(Date.parse(item?.value)).locale(lang).format(DATE_FORMAT) }]
          : []
      },
    )
    return selectedValues
  }

  const formatMatchString = (arrayLength: number) =>
    arrayLength > NUMBERS.ZERO
      ? filterDetails.displayConfig?.resultsLabel.replace("@count_val", arrayLength.toString())
      : filterDetails.displayConfig?.noResultsLabel.replace("@search_txt", currentSearchText)

  const resetLocalFilterWithReducer = () => {
    setLocalSelectedFilters(poListFilters)
  }

  return (
    <>
      {(filterDetails?.displayConfig?.searchTitle || filterDetails?.displayConfig?.searchDesc) && (
        <TitleDescription
          title={filterDetails?.displayConfig?.searchTitle}
          description={filterDetails?.displayConfig?.searchDesc}
        />
      )}
      {/* @ts-ignore */}
      {Object.keys(filterDetails).length ? (
        <div className="careerSearchFilter scroll-top">
          <EnhancedFilterSearch
            filtersOptions={filterDetails?.poSearchContent}
            filterLabels={filterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={true}
            handleUnselectAll={clearAllFilters}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={isMobile ? fetLocalSelectedValue() : fetchAllSelectedFilters()}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCount}
            handleSortByKey={handleSortKey}
            handleSortByOrder={handleSortOrder}
            sortList={filterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(poListFilters, localSelectedFilters)}
            startDate={startDate}
            endDate={endDate}
            disabledColumnSort={disabledColumnSort}
          />
          {!(isMobile && !poList?.length) && <Seperator className="careerSearchFilter-separator" />}
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          {!isLoading && (
            <AnchorButton
              tabindex={0}
              isSecondary
              className="view-open-order-cta"
              href={filterDetails?.displayConfig?.openOrderCTALink}
              target={URL_TARGET.BLANK}
              isExternal={false}>
              {filterDetails?.displayConfig?.openOrderCTALabel} <br/>
              {filterDetails?.displayConfig?.openOrderCTAText?.replace("@count_val", openOrders)}
            </AnchorButton>
          )}
          {!isLoading &&
            (filterList?.length ? (
              <div>
                <SearchFilterTable
                  filterData={filterList}
                  columnsList={filterDetails?.displayConfig.tableColumns}
                  tableColumns={filterDetails.tableColumns}
                  handleSort={handleSortWhole}
                  sortValue={sort}
                  disableSortColumnList={disabledColumnSort}
                />
                <div className="search-filter-pagination">
                  {resultCount > itemsPerPage && (
                    <div className="search-filter-pagination">
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        itemsCount={resultCount}
                        onClick={handlePagination}
                        offSet={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <TitleDescription
                title={formatMatchString(sortedList?.length) ?? ""}
                description={filterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                searchKey=""
                className="careerSearchFilter-no-result"
              />
            ))}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdatePOListFilter: (data: any) => addUpdatePOListFilter(data),
  updatePOListPagination: (data: number) => updatePOListPagination(data),
  updatePOListSearchText: (data: string) => updatePOListSearchText(data),
  updateAllPOListFilters: (data: any, page: number, str: string, sort?: any) =>
    updateAllPOListFilters(data, page, str, sort),
  updatePOListSort: (data: any) => updatePOListSort(data),
}

const mapState = (state: any) => ({
  poListSearchFilterData: state.poListReducer,
})

export default connect(mapState, mapDispatch)(PurchaseOrderSearchFilter)
