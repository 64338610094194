import { getMicrositePageConfigData } from "@helpers/flexibleApi"
import getBlockData from "@helpers/flexibleApi/_flexibleApi.functions"
import ApplicationContext from "@utils/application-context/applicationContext"
import getNestedObject from "@utils/nestedObjects"
import { useCallback, useContext } from "react"

const useMicrositeConfig = (applicationConfiguration: any) => {
  const { setApplicationComponentsData } = useContext(ApplicationContext)

  const getCurrentPageConfig = useCallback(
    (pageAlias: string, globalConfig: any) => {
      const {
        footerNavigation,
        footerSecondaryMenu,
        footerBottomSection,
        eyebrowMenu,
        footerMicrosite,
        liteFooter,
        headerTopSection
      } = globalConfig
     
      const appComponents = applicationConfiguration?.applicationComponentsData?.appComponents
      const currPageComp =
        appComponents?.find((data: any) => data.pageAlias === pageAlias)?.componentConfig || null
      const msHeaderTopsection = getNestedObject(currPageComp, "header.data.headerTopSection") || false
      const msHeaderTopConfig = getNestedObject(currPageComp, "header.data.headerTopConfig") || ""

      const msHeader = {
          headerTopSection : msHeaderTopsection !== false ? {
            themeName: headerTopSection?.themeName,
            countryName: headerTopSection?.countryName,
            chooseLocationText: headerTopSection?.chooseLocationText,
            tagLine: msHeaderTopsection?.tagLine ,
            login_register_enable: msHeaderTopsection?.login_register_enable === undefined ? headerTopSection?.login_register_enable : !msHeaderTopsection?.login_register_enable,
            loginText: msHeaderTopsection?.loginText,
            loginLink: headerTopSection?.loginLink,
            registerText: headerTopSection?.registerText,
            registerLink:headerTopSection?.registerLink,
            logo: msHeaderTopsection?.logo , 
            searchDataContent: {
              placeholder: headerTopSection?.search_help_text || null,
              buttonText: headerTopSection?.search_title || null,
            },
            sticky_menu_enable: headerTopSection?.sticky_menu_enable,
            userMenuDetails: {
              user_menu_logged_in: getNestedObject(headerTopSection, "userMenuDetails.user_menu_logged_in"),
              ctaMenuLink:getNestedObject(headerTopSection, "userMenuDetails.ctaMenuLink"),
              user_menu_logged_out: getNestedObject(headerTopSection, "userMenuDetails.user_menu_logged_out"),
            },
            toastMessages: {
              loginMessage:getNestedObject(headerTopSection, "toastMessages.loginMessage"),
              logoutMessage:getNestedObject(headerTopSection, "toastMessages.logoutMessage"),
            },
            favicon : msHeaderTopConfig?.favicon
          } : headerTopSection,
          
          headerTopConfig: {
            field_disable_cart: msHeaderTopConfig?.field_disable_cart,
            field_disable_logo: msHeaderTopConfig?.field_disable_logo ?? false,
            field_disable_megamenu: msHeaderTopConfig?.field_disable_megamenu,
            field_disable_search: msHeaderTopConfig?.field_disable_search,
            field_disable_slogan: msHeaderTopConfig?.field_disable_slogan,
            field_disable_brand_switcher: currPageComp?.header ? msHeaderTopConfig?.field_disable_brand_switcher :  getNestedObject(applicationConfiguration, "applicationHeaderData.headerData.headerTopSection.isBrandSwitcherDisabled"),
            field_disable_country_switcher: currPageComp?.header ?  msHeaderTopConfig?.field_disable_country_switcher : getNestedObject(applicationConfiguration, "applicationHeaderData.headerData.headerTopSection.isCountrySwitcherDisabled"),
          }

        }
      const pageConfigs = {
        footer: currPageComp?.footer?.data
          ? {
              footerBottomSection: currPageComp?.footer?.data?.footerBottomSection,
              footerNavigation: currPageComp?.footer?.data?.footerNavigation,
              footerSecondaryMenu: currPageComp?.footer?.data?.footerSecondaryMenu,
              footerMicrosite: currPageComp?.footer?.data?.footerMicrosite,
            }
          : {
              footerBottomSection: {
                logoLink: footerBottomSection?.logoLink,
                paragraph: footerBottomSection?.paragraph,
                copyRightText: footerBottomSection?.copyRightText,
                footerText: footerBottomSection?.footerText,
                socialIconData: footerBottomSection?.socialIconData,
                follow_us: footerBottomSection?.follow_us,
              },
              footerNavigation,
              footerSecondaryMenu,
              footerMicrosite,
            },
        liteFooter: currPageComp?.footer?.data?.liteFooter ?? liteFooter,
        eyebrowMenu: currPageComp?.eyebrow_menu
          ? {
              logo: currPageComp?.eyebrow_menu?.data?.logo,
              links: currPageComp?.eyebrow_menu?.data?.links,
              enabled: 1,
            }
          : eyebrowMenu,
        // Yet to handle global fallback for banners
        banner: currPageComp?.isi_legal_disc_banner?.data?.bannerModuleData || null,
        headerTopSection : msHeader?.headerTopSection || headerTopSection,
        headerConfig : msHeader?.headerTopConfig || null
      }
      return pageConfigs
    },
    [applicationConfiguration],
  )

  const setCurrentPageConfig = useCallback(
    (pageConfig: any) => {
      if (pageConfig) {
        setApplicationComponentsData &&
          setApplicationComponentsData({
            actionType: "APP_COMPONENTS",
            componentConfig: pageConfig,
          })
      }
    },
    [applicationConfiguration],
  )

  const fetchMicrositePageConfigData = useCallback(async (pageAlias: string, pageConfig: any) => {
    if (pageConfig) {
      const micrositeData: any =
        (await Promise.all(
          Object.entries(pageConfig)?.map(async ([key, value]: any) => {
            const blockUrl = value?.[0]?.url ?? ""
            const blockData = blockUrl ? await getBlockData(key, blockUrl, "", 0, 0) : null
            return {
              [key]: blockData,
            }
          }),
        )) || []
      const pageConfigData = getMicrositePageConfigData(micrositeData)

      setCurrentPageConfig({
        pageAlias,
        componentConfig: pageConfigData,
      })
    }
  }, [])
  return {
    setCurrentPageConfig,
    getCurrentPageConfig,
    fetchMicrositePageConfigData,
  }
}

export default useMicrositeConfig
