import { Column, Img, RichText } from "@atoms"
import getDirection from "@utils/direction"
import { ISignatureProps, ITestimonialContentProps } from "./_testimonialCardContent.interface"

const Signature = ({ name, designation, logoImageUrl, logoImageAlt, hideQuotes }: ISignatureProps) => (
  <div className={hideQuotes ? "testimonial-content-footer no-left-space" : "testimonial-content-footer"}>
    {name && <span className="testimonial-content-footer-span">{name}</span>}
    <span className="testimonial-content-footer-marginBottom">{designation}</span>
    {!hideQuotes && <span className="testimonial-content-footer-logo">
      <Img src={logoImageUrl} alt={logoImageAlt} width="206" height="24" type="icon" />
    </span>}
  </div>
)

const TestimonialCardContent = ({ summary, hideQuotes, ...rest }: ITestimonialContentProps) => {
  return (
    <div className="testimonial-content" dir={getDirection()}>
      <Column mobile={12} tablet={3} desktop={3} className="testimonial-content-textdata">
        <div className="testimonial-content-text-style">
          {(!hideQuotes && summary) && (
            <figure className="quote">
              <blockquote className="curly-quotes-first" />
              <blockquote className="curly-quotes-second">
                <RichText className="testimonial-content-richtext" content={summary?.value} />
              </blockquote>
            </figure>
          )}
          {hideQuotes && summary && (
            <RichText className="testimonial-content-richtext bottom-space" content={summary?.value} />
          )}
          <Signature hideQuotes={hideQuotes} {...rest} />
        </div>
      </Column>
    </div>
  )
}

export default TestimonialCardContent
