import { getApi } from "@utils/baseApi"
import getArchieveYearData from "./getArchieveYearData"
import { sortDataAsc } from "./getJobSearchData"
import SEARCH_FACETS from "@helpers/constants/searchFacets"

const getArchiveYearData = async (serverData?: any) => {
  const archiveYear = await getArchieveYearData(serverData)
  const options: Array<any> = []
  archiveYear?.data?.map((option: any) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

const getData = async (serverData?: any) => {
  return await getApi(
    `/jsonapi/views/term_facets/rest_export_1?views-filter[type]=${serverData?.content_type}&views-argument[0]=${serverData?.vid}`,
    false,
    serverData,
  )
}
const getFacetsData = async (serverData?: any) => {
  const results = await getData(serverData)
  const options: Array<any> = []

  if (results?.data) {
    results?.data?.map((option: any) => {
      options.push({
        id: option?.attributes?.drupal_internal__tid,
        value: option?.attributes?.name,
        label: option?.attributes?.name,
      })
    })

    return options
  }
  return []
}

const getStructuredData = (data: any, label: string, res: any, placeholder: string) => {
  return {
    label: label || "",
    filterName: data.search_query_param,
    selectOptions: {
      options: res,
      ariaLabel: "Select Element",
      name: "select",
      isMulti: true,
      placeHolder: placeholder ?? "",
    },
  }
}

const getWSIStructuredData = (data: any, label: string, res: any, placeholder: string) => {
  return {
    label: label,
    filterName: data.search_query_param,
    selectOptions: {
      placeHolder: placeholder ?? "",
      isCleared: true,
      isMulti: true,
      options: sortDataAsc(res),
      ariaLabel: label,
      name: "select",
      simpleDropDownLabel: true,
    },
  }
}

const getNewsFacetsDetails = async (data: any, type: string, config: any) => {
  if (type === SEARCH_FACETS?.NEWS_STORIES) {
    if (data.vid === SEARCH_FACETS.ALPHABETHICAL_ORDER) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(data, config?.AlphaLabel, res, config?.dropdown_placeholder_text)
      }
    } else if (data.vid === SEARCH_FACETS?.PROFESSION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_profession,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.DISEASE_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_disease_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.TREATMENT_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_treatment_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ACTIVE_INGREDIENT) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_active_ingredient,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.MEDICATION_TYPE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_medication_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.EDUCATION_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_education_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.NEWS_CATEGORY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_news_category,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.AUDIENCE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.news_n_stories_label_audience,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.SPECIALITY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.other_category_text,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ARCHIVE_YEAR) {
      const res = await getArchiveYearData(data)
      if (res.length) {
        return {
          label: config?.FormLabel,
          filterName: SEARCH_FACETS?.CREATED,
          selectOptions: {
            isCleared: true,
            options: res,
            ariaLabel: SEARCH_FACETS?.SELECT_ELEMENT,
            name: SEARCH_FACETS?.SELECT,
            isMulti: true,
            placeHolder: config?.dropdown_placeholder_text ?? "",
          },
        }
      }
    }
  }
}

const getEducationFacetsDetails = async (data: any, type: string, config: any) => {
  if (type === SEARCH_FACETS?.EDUCATION) {
    if (data.vid === SEARCH_FACETS?.ALPHABETHICAL_ORDER) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(data, config?.AlphaLabel, res, config?.dropdown_placeholder_text)
      }
    } else if (data.vid === SEARCH_FACETS?.PROFESSION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_profession,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.SPECIALITY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.other_category_text,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.EDUCATION_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_education_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.EDUCATION_EVENT_TYPE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_event_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.THERAPY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_therapeutic_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.DISEASE_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_disease_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.TREATMENT_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_treatment_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.AUDIENCE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_label_audience,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.MEDICATION_TYPE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_medication_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.EDUCATION_BRAND_GERMANY_ONLY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.education_education_brand,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ARCHIVE_YEAR) {
      const res = await getArchiveYearData(data)
      if (res.length) {
        return {
          label: config?.FormLabel,
          filterName: SEARCH_FACETS?.CREATED,
          selectOptions: {
            isCleared: true,
            options: res,
            ariaLabel: SEARCH_FACETS?.SELECT_ELEMENT,
            name: SEARCH_FACETS?.SELECT,
            isMulti: true,
            placeHolder: config?.dropdown_placeholder_text ?? "",
          },
        }
      }
    }
  }
}

const getEventsFacetsDetails = async (data: any, type: string, config: any) => {
  if (type === SEARCH_FACETS?.EVENTS) {
    if (data.vid === SEARCH_FACETS?.EVENTS_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res?.length) {
        return getStructuredData(
          data,
          config?.event_label_event_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.DISEASE_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.event_label_disease_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data?.vid === SEARCH_FACETS?.TREATMENT_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.event_label_treatment_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.SPECIALITY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.other_category_text,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.PROFESSION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.event_label_profession,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.AUDIENCE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.event_label_audience,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ALPHABETHICAL_ORDER) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.all_category_text,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ARCHIVE_YEAR) {
      const res = await getArchiveYearData(data)
      if (res.length) {
        return {
          label: config?.from_text,
          filterName: SEARCH_FACETS?.CREATED,
          selectOptions: {
            options: res,
            ariaLabel: SEARCH_FACETS?.SELECT_ELEMENT,
            name: SEARCH_FACETS?.SELECT,
            isMulti: true,
            placeHolder: config?.dropwdown_placeholder_text ?? "",
          },
        }
      }
    }
  }
}

const getTherapyFacetsDetails = async (data: any, type: string, config: any) => {
  if (type === SEARCH_FACETS?.THERAPY) {
    if (
      data.vid === SEARCH_FACETS?.DISEASE_AREA &&
      data.search_query_param === SEARCH_FACETS?.FIELD_DIESASE_AREA_TARGET_ID
    ) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.therapy_label_disease_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.SPECIALITY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.other_category_text,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (
      data.vid === SEARCH_FACETS?.TREATMENT_AREA &&
      data.search_query_param === SEARCH_FACETS?.FIELD_TREAMENT_AREA_TARGET_ID
    ) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.therapy_label_treatment_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.PROFESSION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.therapy_label_profession,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.MEDICATION_TYPE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.therapy_label_medication_type,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.AUDIENCE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.therapy_label_audience,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ALPHABETHICAL_ORDER) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(data, config?.AlphaLabel, res, config?.dropdown_placeholder_text)
      }
    } else if (data.vid === SEARCH_FACETS?.ARCHIVE_YEAR) {
      const res = await getArchiveYearData(data)
      if (res.length) {
        return {
          label: config?.FormLabel,
          filterName: SEARCH_FACETS?.CREATED,
          selectOptions: {
            isCleared: true,
            options: res,
            ariaLabel: SEARCH_FACETS?.SELECT_ELEMENT,
            name: SEARCH_FACETS?.SELECT,
            isMulti: true,
            placeHolder: config?.selectOptionText ?? "",
          },
        }
      }
    }
  }
}

const getCourseCatalogueFacetsDetails = async (data: any, type: string, config: any) => {
  if (type === SEARCH_FACETS?.COURSECATALOGUE) {
    if (data.vid === SEARCH_FACETS?.ALPHABETHICAL_ORDER) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(data, config?.AlphaLabel, res, config?.dropdown_placeholder_text)
      }
    } else if (data.vid === SEARCH_FACETS?.PROFESSION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_profession,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.SPECIALITY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.other_category_text,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.COURSE_CATEGORY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_course_category,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.COURSE_SUGGESTION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_course_suggestion,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.THERAPY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_therapeutic_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.DISEASE_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_disease_area,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.SANDOZ_ACADEMY_TOPICS) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_sandoz_academy_topics,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.AUDIENCE) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_catalogue_label_audience,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.COURSE_DIFFICULTY_LEVEL) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(
          data,
          config?.course_cataloguen_course_difficulty_level,
          res,
          config?.dropdown_placeholder_text,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ARCHIVE_YEAR) {
      const res = await getArchiveYearData(data)
      if (res.length) {
        return {
          label: config?.FormLabel || "",
          filterName: SEARCH_FACETS?.CREATED,
          selectOptions: {
            isCleared: true,
            options: res,
            ariaLabel: SEARCH_FACETS?.SELECT_ELEMENT,
            name: SEARCH_FACETS?.SELECT,
            isMulti: true,
            placeHolder: config?.dropdown_placeholder_text ?? "",
          },
        }
      }
    }
  }
}

const getWSIFacetsDetails = async (data: any, type: string, config: any) => {
  if (type === SEARCH_FACETS?.WSI) {
    if (data.vid === SEARCH_FACETS?.SPECIALITY_VOCABULARY) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getWSIStructuredData(data, config?.specialityLabel, res, config?.selectPlaceholder)
      }
    } else if (data.vid === SEARCH_FACETS?.TREATMENT_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getWSIStructuredData(
          data,
          config?.webstore_label_treatment_area,
          res,
          config?.selectPlaceholder,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.PROFESSION) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getWSIStructuredData(
          data,
          config?.webstore_label_profession,
          res,
          config?.selectPlaceholder,
        )
      }
    } else if (data.vid === SEARCH_FACETS?.ALPHABETHICAL_ORDER) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getWSIStructuredData(data, config?.alphabeticLabel, res, config?.selectPlaceholder)
      }
    } else if (data.vid === SEARCH_FACETS?.ARCHIVE_YEAR) {
      const res = await getArchiveYearData(data)

      if (res?.length) {
        return {
          label: config?.webstore_label_time_period,
          filterName: SEARCH_FACETS?.CREATED,
          selectOptions: {
            placeHolder: config?.selectPlaceholder ?? "",
            isCleared: true,
            isMulti: true,
            options: sortDataAsc(res),
            ariaLabel: config?.webstore_label_time_period,
            name: SEARCH_FACETS?.SELECT,
            simpleDropDownLabel: true,
          },
        }
      }
    } else if (data.vid === SEARCH_FACETS?.DISEASE_AREA) {
      const res = await getFacetsData(data)
      if (res.length) {
        return getStructuredData(data, config?.diseaseAreaLabel, res, config?.selectPlaceholder)
      }
    }
  }
}

const getSearchFacetsData = async (configData: any, type: string) => {
  let finalResults: any = []
  if (type === SEARCH_FACETS?.NEWS_STORIES) {
    const promises = configData?.news_stories_search_facets.map(async (data: any) => {
      const result = await getNewsFacetsDetails(data, type, configData)

      if (result) {
        finalResults.push(result)
      }
    })

    await Promise.all(promises)
    return finalResults
  } else if (type === SEARCH_FACETS?.EDUCATION) {
    const promises = configData?.education_search_facets.map(async (data: any) => {
      const result = await getEducationFacetsDetails(data, type, configData)
      if (result) {
        finalResults.push(result)
      }
    })

    await Promise.all(promises)
    return finalResults
  } else if (type === SEARCH_FACETS?.EVENTS) {
    const promises = configData?.events_search_facets.map(async (data: any) => {
      const result = await getEventsFacetsDetails(data, type, configData)
      if (result) {
        finalResults.push(result)
      }
    })
    await Promise.all(promises)
    return finalResults
  } else if (type === SEARCH_FACETS?.THERAPY) {
    const promises = configData?.therapy_areas_search_facets.map(async (data: any) => {
      const result = await getTherapyFacetsDetails(data, type, configData)
      if (result) {
        finalResults.push(result)
      }
    })

    await Promise.all(promises)
    return finalResults
  } else if (type === SEARCH_FACETS?.WSI) {
    const promises = configData?.webstore_search_facets?.map(async (data: any) => {
      const result = await getWSIFacetsDetails(data, type, configData)
      if (result) {
        finalResults.push(result)
      }
    })

    await Promise.all(promises)
    return finalResults
  }
  else if (type === SEARCH_FACETS?.COURSECATALOGUE) {
    const promises = configData?.course_catalogue_search_facets.map(async (data: any) => {
      const result = await getCourseCatalogueFacetsDetails(data, type, configData)
      if (result) {
        finalResults.push(result)
      }
    })

    await Promise.all(promises)
    return finalResults
  }
}

export default getSearchFacetsData
