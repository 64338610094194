import React from "react"
import { Anchor, Column, Icon, Img, RichText } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IPostcodeContactCardProps } from "./_postcodeContactCard.interface"

const PostcodeContactCard = ({
  display_name,
  phone_number,
  email,
  address,
  image,
}: IPostcodeContactCardProps) => (
  <Column desktop={12} tablet={12} mobile={12} className="postcode-contact-card">
    <div className="card">
      <div className="card-body">
        {image && (
          <Img
            className="postcode-contact-card-image"
            styledimage={image.styledimage}
            src={image.src}
            alt={image.alt}
            width={268}
            height={268}
          />
        )}
        {display_name && (
          <h4 className="postcode-contact-card-title bold">
            {trimText(display_name, CHARLIMIT.TITLE)}
          </h4>
        )}
        <div className="postcode-contact-card-phone-icon-align">
          {phone_number ? (
            <>
              <Icon
                iconName="phoneIcon"
                width={24}
                height={24}
                color="var(--brand-primary-color-three)"
              />
              <Anchor
                className="postcode-contact-card-phone"
                href={`tel:${phone_number}`}
                tabindex={1}
                isExternal={true}
                noUnderline={false}>
                {phone_number}
              </Anchor>
            </>
          ) : (
            <span className="postcode-contact-card-phone hidden">-</span>
          )}
        </div>
        <div className="postcode-contact-card-email-icon-align">
          {email ? (
            <>
              <Icon
                iconName="mailIcon"
                width={24}
                height={24}
                color="var(--brand-primary-color-three)"
              />
              <Anchor
                className="postcode-contact-card-phone"
                href={`mailto:${email}`}
                tabindex={1}
                isExternal={true}
                noUnderline={false}>
                {email}
              </Anchor>
            </>
          ) : (
            <span className="postcode-contact-card-phone hidden">-</span>
          )}
        </div>
        <div className="postcode-contact-card-address-icon-align">
          {address ? (
            <>
              <Icon
                iconName="mapIcon"
                width={24}
                height={24}
                color="var(--brand-primary-color-three)"
              />
              <RichText
                className="postcode-contact-card-text"
                content={address}
                characterLimit={CHARLIMIT?.DESCRIPTION}
              />
            </>
          ) : (
            <span className="postcode-contact-card-phone hidden">-</span>
          )}
        </div>
      </div>
    </div>
  </Column>
)

export default PostcodeContactCard
