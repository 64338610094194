import * as actionTypes from "../ActionTypes"

export const addUpdateCOListFilter = (coListFilters: any) => {
    const action: COListFilterAction = {
        type: actionTypes.ADD_UPDATE_CO_LIST_FILTERS,
        coListFilters,
    }
    return performRequest(action)
}
export const updateCOListPagination = (currentPage: number) => {
    const action: COListFilterAction = {
        type: actionTypes.UPDATE_CO_LIST_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updateCOListSearchText = (searchText: string) => {
    const action: COListFilterAction = {
        type: actionTypes.UPDATE_CO_LIST_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllCOListFilters = ( coListFilters: any,currentPage: number, searchText: string, sort?: any) => {
    const action: COListFilterAction = {
        type: actionTypes.UPDATE_ALL_CO_LIST_FILTERS,
        coListFilters,
        currentPage,
        searchText,
        sort,
    }
    return performRequest(action)
}

export const updateCOListSort = (sort: any) => {
    const action: COListFilterAction = {
        type: actionTypes.UPDATE_CO_LIST_SORT,
        sort,
    }
    return performRequest(action)
}

const performRequest = (action: COListFilterAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}
