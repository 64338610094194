import getNestedObject from "@utils/nestedObjects"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import { Icon } from "@atoms"

export const getUserDetailLabelData = (siteConfig:any) => {
  if (siteConfig) {
    const categoryLabelData = {
      searchLabel: getNestedObject(siteConfig, "user_listing.user_listing_search_box_title"),
      searchPlaceholder: getNestedObject(siteConfig, "user_listing.user_listing_search_placeholder_text"),
      searchCTALabel: getNestedObject(siteConfig, "user_listing.user_listing_search_cta_label"),
      uatTableColumns: getNestedObject(siteConfig, "user_listing.uat_header_label"),
      ulTableColumns: getNestedObject(siteConfig, "user_listing.ut_header_label"),
      resultsLabel: getNestedObject(siteConfig, "user_listing.user_listing_search_results_text"),
      noResultsLabel: getNestedObject(siteConfig, "user_listing.user_listing_search_no_results_text"),
      noResultsDesc: "",
      sortByLabel: getNestedObject(siteConfig, "user_listing.user_listing_sortby_label") ?? "",
      paginationCount: getNestedObject(siteConfig, "user_listing.user_listing_items_per_page"),
      exportToCTALabel:getNestedObject(siteConfig, "user_listing.user_listing_export_to_excel_cta_label") ?? "",
      addCompanyCTALabel:getNestedObject(siteConfig, "company_listing.company_listing_add_company_cta_label") ?? "",
      userDetailsLabel: getNestedObject(siteConfig, "user_listing.user_details_tab_label") ?? "",
      userActionLabel: getNestedObject(siteConfig, "user_listing.user_listing_user_action_table_title") ?? "",
      userLabel: getNestedObject(siteConfig, "user_listing.user_listing_users_table_title") ?? "",
      createNewUserLabel:  getNestedObject(siteConfig, "user_listing.user_create_new_user_cta_label") ?? "",
    }
    return categoryLabelData
  }

  return {
    searchLabel: "",
    searchPlaceholder: "",
    searchCTALabel: "",
    uatTableColumns: "",
    ulTableColumns: "",
    resultsLabel: "",
    noResultsLabel: "",
    noResultsDesc: "",
    sortByLabel: "",
    paginationCount: "",
    exportToCTALabel:"",
    addCompanyCTALabel:"",
    userDetailsLabel: "",
    userActionLabel: "",
    userLabel: "",
    createNewUserLabel: ""
  }
}


export const fetchUserDetailsTableData = (response: any) => {
  const tableData: searchFilterType[] = response?.data?.map((item:any, index:any) => {
    return {
      lastLogin: {
        title: "",
        desc: item.last_login ?? "",
        type: "text",
        link: "",
      },
      name: {
        title: "",
        desc: item.name ?? "",
        type: "richtext",
        link: "",
      },
      company: {
        title: "",
        desc: item.company ?? "",
        type: "richtext",
        link: "",
      },
      administrator: {
        title: "",
        desc: "",
        type: "actions",
        link: "",
        linkIcon: item.administrator ? <Icon iconName="CheckMark" /> : "",
      },
      createdBy: {
        title: "",
        desc: item.created_by ?? "",
        type: "richtext",
        link: "",
      },
      actionItems: {
        title: "",
        desc: "",
        type: "actions",
        link: "",
        linkIcon:
          index % 2 === 0 ? (
            <div className="user-icons">
              <Icon iconName="userLockIcon" />
              <Icon iconName="userActiveIcon" />
            </div>
          ) : (
            <div className="user-icons">
              <Icon iconName="CheckMark" />
              <Icon iconName="CrossIcon" />
            </div>
          ),
      },
    }
  })

  return tableData
}

export const sortDataAsc = (data: any) =>
  data.sort((a: any, b: any) => a.label.localeCompare(b.label))

const getUserDetails = (data: any) => {
  return {}
}

export default getUserDetails
