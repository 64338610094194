import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getProductBlockData } from "."
import { consoleError } from "../../utils/error"

const getProductlistData = async (url: string) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getProductlistData.name}.`)

  const allCardsRawData = await getProductBlockData(url)
  const fieldPaywallRoles = getAllRoles(allCardsRawData)
  let allCardsData: any = []
  allCardsData = await Promise.all(
    allCardsRawData?.data?.map(async (rawData: any) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(rawData, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const bookmark = rawData?.attributes?.status ? rawData?.attributes?.status : false
      const imagePaywallData = isPaywallProtected(rawData?.prodImages)
      return {
        id: rawData?.drupal_internal__nid ?? null,
        taxonomyLabel: rawData?.resultTaxonomies?.description ?? "",
        heading: isPaywallProtected(rawData?.title).isPaywallProtectedFlag
          ? ""
          : rawData?.title ?? "",
        description: isPaywallProtected(rawData?.description).isPaywallProtectedFlag
          ? ""
          : rawData?.description ?? "",
        prodInfoTaxonomies: rawData?.prodInfoTaxonomies?.map((t: any) => t.tid) ?? [],
        packInfoTaxonomies: rawData?.packInfoTaxonomies?.map((t: any) => t.tid) ?? [],
        category: isPaywallProtected(rawData?.category).isPaywallProtectedFlag
          ? ""
          : rawData?.category ?? "",
        productListViewImage: {
          src: rawData?.prodImages?.[0]?.src ?? "",
          alt: rawData?.prodImages?.[0]?.alt ?? "",
          styledimage: rawData?.prodImages?.[0]?.image_style_uri ?? null,
        },
        productCardImage: {
          src: imagePaywallData?.isPaywallProtectedFlag
            ? getPaywalledImgData(imagePaywallData?.errorObj).imageUrl
            : rawData?.prodImages?.[0]?.src ?? "",
          alt: imagePaywallData?.isPaywallProtectedFlag ? "" : rawData?.prodImages?.[0]?.alt ?? "",
          styledimage: imagePaywallData?.isPaywallProtectedFlag
            ? null
            : rawData?.prodImages?.[0]?.image_style_uri ?? null,
        },
        isLinkExternal: false,
        buttonLabel: isPaywallProtected(rawData?.primaryButtonLabel).isPaywallProtectedFlag
          ? ""
          : rawData?.primaryButtonLabel ?? "",
        href: isPaywallProtected(rawData?.primaryButtonUrl).isPaywallProtectedFlag
          ? ""
          : filterURL(rawData?.primaryButtonUrl) ?? "",
        specifications: [],
        bookmark: isPaywallProtected(rawData?.attributes?.status).isPaywallProtectedFlag
          ? false
          : bookmark,
      }
    }),
  )
  return {
    data: {
      allCardsData: filterNullValues(allCardsData),
      filterOptions: [],
      totalCount: Number(allCardsRawData?.count ?? "0"),
    },
    fieldPaywallRoles,
  }
}

export default getProductlistData
