const NEWS_FILTER_CONSTANTS = {
  IHEIGHT: "310",
  IWIDTH: "468",
  NEWS_CARD_TYPE: {
    BANNER: "banner",
    SMALL: "small",
  },
}

export default NEWS_FILTER_CONSTANTS
