import { Button, Icon } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { FlexibleCard } from "@molecules"
import { useCallback } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { btnClickTabChange, moduleId } from "store/actions/AcademyCourseActionCreators"
import { v4 } from "uuid"
import { IAcademyModulesProps } from "./_academyModules.interface"

interface DispatchProps {
  btnClickTabChange: (data: any) => void
  moduleId: (data: any) => void
}

type StateTypes = {
  academyCourseFilterData: {
    btnClickTabChange: boolean
  }
}

type Props = DispatchProps & StateTypes & IAcademyModulesProps

const AcademyModules = (props: Props) => {
  const user: any = useSelector((state: UserState) => state.user)
  const moduleId = v4()
  const dispatch: Dispatch<any> = useDispatch()
  const btnClickTabChangeState: any = useCallback(
    (value: boolean) => props?.btnClickTabChange(value),
    [dispatch],
  )

  const changeModuleIdState: any = useCallback((value: any) => props?.moduleId(value), [dispatch])

  const handleClick = () => {
    btnClickTabChangeState({ btnClickTabChanged: true })
  }

  return (
    <div className="academy-modules-wrapper">
      <h2 className="academy-modules-wrapper-title">
        {props?.cardsArray?.[NUMBERS.ZERO]?.topic_label}
      </h2>
      <div className="academy-modules-wrapper-container">
        {props?.cardsArray?.map((item: IAcademyModulesProps, index: number) => {
          return (
            <div key={index} id={moduleId} className="card-item">
              <FlexibleCard
                detailsPath={""}
                {...item}
                btnClickTabChange={btnClickTabChangeState}
                moduleChangeId={changeModuleIdState}
              />
            </div>
          )
        })}
      </div>
      {props?.cardsArray[NUMBERS?.ZERO]?.ctaLabel && user && user?.isLoggedIn ? (
        <div className="academy-modules-wrapper-cta">
          <Button
            tabindex={0}
            onClick={() => handleClick()}
            icon={<Icon iconName={"ArrowForward1"} />}
            iconPosition="right">
            {props?.cardsArray[NUMBERS?.ZERO]?.ctaLabel}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
const mapDispatch = {
  btnClickTabChange: (data: any) => btnClickTabChange(data),
  moduleId: (data: any) => moduleId(data),
}

const mapState = (state: any) => ({
  academyCourseFilterData: state.academyCourseReducer,
})
export default connect(mapState, mapDispatch)(AcademyModules)
