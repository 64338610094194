/* eslint-disable react/jsx-key */
import { Column, Img, Row } from "@atoms"
import { TestimonialCardContent } from "@molecules"
import { CarouselWrapper } from "@organisms"
import getDirection from "@utils/direction"
import NUMBERS from "@helpers/constants/numbers"
import { ITestimonialProps, TSlidesInfo } from "./_testimonial.interface"

const TestimonialSlide = ({
  logoImageUrl,
  logoImageAlt,
  alt,
  image,
  styledimage,
  className,
  hideQuotes,
  ...rest
}: TSlidesInfo) => (
  <>
    <Row key={rest.testimonialContent.name} className={`testimonial ${className}`}>
      <Column mobile={12} tablet={12} desktop={6} className="testimonial-content-left">
        <TestimonialCardContent
          {...rest.testimonialContent}
          logoImageUrl={logoImageUrl}
          logoImageAlt={logoImageAlt}
          hideQuotes={hideQuotes}
        />
      </Column>
      <Column mobile={12} tablet={12} desktop={6} className={!hideQuotes ? "testimonial-content-right" : "testimonial-content-auto-right"}>
        <Img
          className="testimonial-image-content"
          type="testimonials"
          styledimage={styledimage}
          alt={alt}
          src={image}
          width="548"
          height="370"
        />
      </Column>
    </Row>
  </>
)

const Testimonial = ({ slidesInfo, title, hideQuotes = false }: ITestimonialProps) => (
  <>
  {hideQuotes ? <h2 className="testimonial-heading bold">{title}</h2> : <></>}
  <CarouselWrapper
    className="testimonial-caraousel"
    slideToShow={1}
    slideToShowTablet={1}
    dir={getDirection()}>
    {slidesInfo?.map((slides: TSlidesInfo, key: number) => (
      <TestimonialSlide key={key}
        {...slides}
        className={slidesInfo.length === NUMBERS.ONE ? "single-slide" : ""}
        hideQuotes={hideQuotes}
      />
    ))}
  </CarouselWrapper>
  </>
)

export default Testimonial
