import getNestedObject from "@utils/nestedObjects"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import { Icon } from "@atoms"


export const getCoFilterLabelData = (siteConfig: any) => {
  if (siteConfig) {
    const tableLabels ={
      soldToNumber: getNestedObject(siteConfig, "company_listing.company_listing_sold_to_no_label"),
      companyName: getNestedObject(siteConfig, "company_listing.company_listing_companyname_label"),
      createdBy: getNestedObject(siteConfig, "company_listing.company_listing_createdby_label"),
      actionItem: getNestedObject(siteConfig, "company_listing.company_listing_action_label"),
    }
    const CategoryLabelData = {
      searchLabel: getNestedObject(siteConfig, "company_listing.company_listing_search_box_title"),
      searchPlaceholder: getNestedObject(siteConfig, "company_listing.company_listing_search_placeholder_text"),
      searchCTALabel: getNestedObject(siteConfig, "company_listing.company_listing_search_cta_label"),
      tableColumns: tableLabels,
      resultsLabel: getNestedObject(siteConfig, "company_listing.company_listing_search_results_text"),
      noResultsLabel: getNestedObject(siteConfig, "company_listing.company_listing_search_no_results_text"),
      noResultsDesc: "",
      sortByLabel: getNestedObject(siteConfig, "company_listing.company_listing_sortby_label") ?? "",
      paginationCount: getNestedObject(siteConfig, "company_listing.company_listing_items_per_page"),
      exportToCTALabel:getNestedObject(siteConfig, "company_listing.company_listing_export_to_excel_cta_label") ?? "",
      addCompanyCTALabel:getNestedObject(siteConfig, "company_listing.company_listing_add_company_cta_label") ?? "",

    }
    return CategoryLabelData

  }

  return {
    searchLabel: "",
    searchPlaceholder: "",
    searchCTALabel: "",
    tableColumns: "",
    resultsLabel: "",
    noResultsLabel: "",
    noResultsDesc: "",
    sortByLabel: "",
    paginationCount: "",
    exportToCTALabel:"",
    addCompanyCTALabel:""
  }

}

const extractTitleAndLink = (input:string) => {
  const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*?)"[^>]*?>([^<]*?)<\/a>/i;
  const [, link, title] = input.match(regex) || [];
  return {
    title,
    link,
  };
}

export const fetchCoListingTableData = (response: any) => {
  const tableData: searchFilterType[] = response?.data?.map((item:any) => {

     return {
        soldToNumber: {
         title: "",
         desc: item.sold_to_number ?? "",
         type: "text",
         link: ""
        },
        companyName: {
          title: item.company_name ?? "",
          desc:  "",
          type: "text",
          link: `/company-detail/${item.company_name}`
        },
        createdBy:  {
          title: item.created_by ?? "",
          desc:  "",
          type: "text",
          link: `/user-info/${item.created_by}`
        },
        actionItem:  {
          title: item.action ?? "",
          desc:  "",
          type: "download",
          link:  item.action ?? "",
          linkIcon: <Icon iconName="CrossIcon" />,
        },
      }
  })

  return tableData
}
export const sortDataAsc = (data: any) =>
  data.sort((a: any, b: any) => a.label.localeCompare(b.label))

const getCOListSearchData = (data: any) => {
  return {}
}

export default getCOListSearchData