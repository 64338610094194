// import { configureStore } from '@reduxjs/toolkit'
import { createStore, applyMiddleware, combineReducers } from "redux"
import { createWrapper } from "next-redux-wrapper"
import thunkMiddleware from "redux-thunk"

import AdvanceFilterReducer from "./reducers/AdvanceFilterReducer"
import CommonReducer from "./reducers/CommonReducers"
import JobSearchReducer from "./reducers/JobSearchReducer"
import ProductReducer from "./reducers/ProductReducer"
import RatingsReducer from "./reducers/RatingsReducer"
import SessionReducer from "./reducers/SessionReducer"
import UserReducer from "./reducers/UserReducer"
import WebshopReducer from "./reducers/WebshopReducer"
import MagazineReducer from "./reducers/MagazineReducer"
import ISIBannerReducer from "./reducers/ISIBannerReducer"
import InterfaceSearchReducer from "./reducers/InterfaceSearchReducer"

import session from "redux-persist/lib/storage/session"
import storage from "redux-persist/lib/storage"
import isServer from "@utils/fs/isServer"
import POListSearchReducer from "./reducers/POListSearchReducer"
import PoDetailSearchReducer from "./reducers/PoDetailSearchReducer"
import COListSearchReducer from "./reducers/COListSearchReducer"
import UserDetailSearchReducer from "./reducers/UserDetailSearchReducer"
import UserActionReducer from "./reducers/UserActionReducer"
import AcademyCourseReducer from "./reducers/AcadamyCourseReducer"


const bindMiddleware = (middleware: any) => {
    if (process.env.NODE_ENV !== "production") {
        const { composeWithDevTools } = require("redux-devtools-extension")
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
};

let store: any
const makeStore = () => {
    if (isServer()) {
        //If it's on server side, create a store
        const combinedReducerInServer = combineReducers({})
        return createStore(combinedReducerInServer, bindMiddleware([thunkMiddleware]));
    } else {
        //If it's on client side, create a store which will persist
        const { persistStore, persistReducer } = require("redux-persist")

        const feDomain = JSON.parse(sessionStorage.getItem("feDomainData") as string)
        const localStoragePersistConfig = { 
            key: `common_${feDomain}`, 
            storage,
        }

        const combinedReducer = combineReducers({
            common: persistReducer(localStoragePersistConfig, CommonReducer),
            user: UserReducer,
            ratings: RatingsReducer,
            product: ProductReducer,
            advanceFilter: AdvanceFilterReducer,
            jobReducer: JobSearchReducer,
            poListReducer: POListSearchReducer,
            poDetailReducer: PoDetailSearchReducer,
            coListReducer:COListSearchReducer,
            userDetailSearchReducer:UserDetailSearchReducer,
            userActionReducer: UserActionReducer,
            webshop: WebshopReducer,
            magazine: MagazineReducer,
            appSession: SessionReducer,
            isiBanner: ISIBannerReducer,
            interfaceReducer: InterfaceSearchReducer,
            academyCourseReducer: AcademyCourseReducer
        })

        const sessionStoragePersistConfig = {
            key: `root_${feDomain}`,
            storage: session,
            whitelist: ["user", "ratings", "product", "advanceFilter", "jobReducer", "webshop", "isiBanner", "interfaceReducer", "poListReducer", "poDetailReducer", "coListReducer", "userDetailSearchReducer", "userActionReducer", "academyCourseReducer"],
            blacklist: ["magazine", "appSession"]
        }

        const persistedReducer = persistReducer(sessionStoragePersistConfig, combinedReducer); // Create a new reducer with our existing reducer

        store = createStore(
            persistedReducer,
            bindMiddleware([thunkMiddleware])
        );

        (store as any).__persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature
        return store;
    }
};

export { store }

export const wrapper = createWrapper(makeStore)
