import * as actionTypes from "../ActionTypes"

export const addUpdateUserDetailsFilter = (userDetailsFilters: any) => {
    const action: UserDetailsFilterAction = {
        type: actionTypes.ADD_UPDATE_USER_DETAILS_FILTERS,
        userDetailsFilters,
    }
    return performRequest(action)
}
export const updateUserDetailsPagination = (currentPage: number) => {
    const action: UserDetailsFilterAction = {
        type: actionTypes.UPDATE_USER_DETAILS_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updateUserDetailsSearchText = (searchText: string) => {
    const action: UserDetailsFilterAction = {
        type: actionTypes.UPDATE_USER_DETAILS_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllUserDetailsFilters = (userDetailsFilters: any, currentPage: number, searchText: string, sort?: any) => {
    const action: UserDetailsFilterAction = {
        type: actionTypes.UPDATE_ALL_USER_DETAILS_FILTERS,
        userDetailsFilters,
        currentPage,
        searchText,
        sort,
    }
    return performRequest(action)
}

export const updateUserDetailsSort = (sort: any) => {
    const action: UserDetailsFilterAction = {
        type: actionTypes.UPDATE_USER_DETAILS_SORT,
        sort,
    }
    return performRequest(action)
}

const performRequest = (action: UserDetailsFilterAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}