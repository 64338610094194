import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useMemo, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import { AnchorButton, Column, FavoriteIcon, Icon, RichText, Row, Taxonomy } from "@atoms"
import BookmarkWithButton from "@molecules/bookmarkWithButton"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IVideoTeaserCardContentProps } from "./_videoTeaserCardContent.interface"
import IconWithLabel from "../iconWithLabel"
import ApplicationContext from "@utils/application-context/applicationContext"

const VideoTeaserCardContent = ({
  id,
  taxonomies,
  heading,
  text,
  time,
  buttonUrl,
  isButtonUrlExternal,
  buttonLabel,
  bookmark,
}: IVideoTeaserCardContentProps) => {
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const applicationConfigContext = useContext(ApplicationContext)

  let totalFavoriteCount = 0
  const dispatch: Dispatch<any> = useDispatch()
  const bookmarkHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)

  const selectedCards = favourite?.filter((f: any) => parseInt(f.node_id) === id)
  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === id) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  return (
    <div className="video-teaser-card-content">
      <Row>
        <Column desktop={6} tablet={6} mobile={6}>
          {taxonomies && <Taxonomy taxonomies={taxonomies} />}
        </Column>
        <Column desktop={6} tablet={6} mobile={6} className="video-teaser-card-content-icon">
          <Row className="video-teaser-card-content-icon-row">
            <Column
              desktop={4}
              tablet={4}
              mobile={4}
              className="video-teaser-card-content-bookmark-icon">
              <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={bookmarkHandler} />
            </Column>

            <Column
              desktop={8}
              tablet={8}
              mobile={8}
              className="video-teaser-card-content-fav-icon">
              <div className="video-teaser-card-content-favorite-section">
                {applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
                  <FavoriteIcon
                    id={id}
                    totalFavoriteCount={totalFavoriteCount}
                    favouriteStatus={isFavouriteSelected}
                    favoriteHandlerCallback={favouriteHandler}
                  />
                )}
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
      {heading && (
        <h4 className="video-teaser-card-content-header bold">
          {trimText(heading, CHARLIMIT.TITLE)}
        </h4>
      )}
      {text && (
        <RichText
          className="video-teaser-card-content-text"
          content={text}
          characterLimit={CHARLIMIT.DESCRIPTION}
        />
      )}
      <div className="video-teaser-card-content-time-button-container">
        {time && (
          <div className="video-teaser-card-content-time-section">
            <IconWithLabel icon={<Icon iconName="TimeIcon" width={24} height={24} />}>
              {time}
            </IconWithLabel>
          </div>
        )}
        {buttonUrl && (
          <div className="video-teaser-card-content-button">
            <AnchorButton isExternal={isButtonUrlExternal} tabindex={2} isSecondary href={buttonUrl}>
              {buttonLabel}
            </AnchorButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoTeaserCardContent
