import { Anchor, Button, Column, Icon, Img, RichText, Taxonomy } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import BookmarkWithButton from "@molecules/bookmarkWithButton"
import IconWithLabel from "@molecules/iconWithLabel"
import StarsRating from "@molecules/starsRating"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getApiDomainAndLang } from "@utils/baseApi"
import { trimText } from "@utils/stringparsing"
import moment from "moment"
import { Dispatch, useCallback, useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addRatings } from "store/actions/RatingsActionCreators"
import IFlexibleCard from "./_flexibleCard.interface"
const { lang } = getApiDomainAndLang()
const FlexibleCard = (props: IFlexibleCard) => {
  const {
    desktopColumns = 3,
    tabletColumns = 6,
    title,
    id,
    desc,
    className,
    cardImage,
    taxonomies,
    bookmarkContent,
    isRatingsAvailable = false,
    avgRating,
    difficultyLevel,
    courseDuration,
    credits,
    courseCategory,
    courseSuggestion,
    subTitle,
    fromDate,
    fromLabel,
    toDate,
    toLabel,
    avgRatingTitle,
    enableCredits,
    detailsPath,
    moduleTitle,
    tag,
    author,
    moduleLinkTitle,
    isCourseModule,
    btnClickTabChange,
    moduleChangeId,
  } = props

  const user: any = useSelector((state: UserState) => state.user)
  const dispatch: Dispatch<any> = useDispatch()
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const { applicationConfiguration } = useContext(ApplicationContext)
  const toDateFormated = toDate && applicationConfiguration?.siteConfig?.date_format
    ? moment(toDate).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)
    : null
  const fromDateFormated = fromDate && applicationConfiguration?.siteConfig?.date_format
    ? moment(fromDate).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)
    : null
  const stateHandler = (state: any) => {
    updateRatings({
      id: props?.id,
      state,
    })
  }

  const handleModuleChange = (id: number) => {
    btnClickTabChange({ btnClickTabChanged: true })
    moduleChangeId({ moduleId: id })
  }
  const cardBody = (
    <div className="flex-card-body">
      <div className="flex-card-body-head">
        <div
          className={`flex-card-body-head-star-taxonomy ${
            isCourseModule ? "modules-star-taxonomy" : ""
          }`}>
          <Column desktop={8} tablet={8} mobile={8}>
            {moduleTitle ? (
              <Anchor href={detailsPath} tabindex={2} isExternal={false} noUnderline>
                <h4 className="flex-card-body-title">{trimText(moduleTitle, CHARLIMIT.TITLE)}</h4>
              </Anchor>
            ) : (
              <></>
            )}
            {isRatingsAvailable && avgRating ? (
              <StarsRating
                avgRating={avgRating}
                setShowLoginPopUp={() => setShowLoginPopUp(true)}
                title={avgRatingTitle}
              />
            ) : (
              <></>
            )}
          </Column>
          {enableCredits && credits?.length && (
            <Column desktop={4} tablet={4} mobile={4} className="flex-card-body-head-credit">
              {credits?.length ? <Taxonomy taxonomies={credits} /> : <></>}
            </Column>
          )}
          {tag?.length && (
            <Column desktop={4} tablet={4} mobile={4} className="flex-card-body-head-tag">
              {tag?.length ? <span> {tag} </span> : <></>}
            </Column>
          )}
        </div>
      </div>
      {subTitle || courseSuggestion?.label ? (
        <div className="flex-card-body-recommand">
          {courseSuggestion?.icon && courseSuggestion?.label ? (
            <IconWithLabel icon={<Icon iconName={courseSuggestion?.icon} />}>
              {courseSuggestion?.label}
            </IconWithLabel>
          ) : (
            <></>
          )}
          {subTitle ? (
            <h6 className="flex-card-body-recommand-subTitle bold">{subTitle}</h6>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {title && (
        <Anchor href={detailsPath} tabindex={2} isExternal={false} noUnderline>
          {isCourseModule ? (
            <h3 className="flex-card-body-title">{trimText(title, CHARLIMIT.TITLE)}</h3>
          ) : (
            <h4 className="flex-card-body-title">{trimText(title, CHARLIMIT.TITLE)}</h4>
          )}
        </Anchor>
      )}
      {!isCourseModule && (
        <div className="flex-card-body-head-fromToText">
          {fromDateFormated && fromLabel && <span>{`${fromLabel} ${fromDateFormated} `}</span>}
          {toDateFormated && toLabel && <span>{`${toLabel} ${toDateFormated}`}</span>}
        </div>
      )}

      {desc && <RichText content={desc} className="flex-card-body-desc" />}
      {isCourseModule && author && <RichText content={author} className="flex-card-body-author" />}
      <div className="flex-card-body-bottom">
        {difficultyLevel?.label ? (
          <IconWithLabel icon={<Icon iconName={difficultyLevel?.icon} />}>
            {difficultyLevel?.label}
          </IconWithLabel>
        ) : (
          <></>
        )}
        {courseDuration?.label ? (
          <IconWithLabel icon={<Icon iconName={courseDuration?.icon} />}>
            {courseDuration?.label}
          </IconWithLabel>
        ) : (
          <></>
        )}
        {bookmarkContent ? (
          <div className="flex-card-body-head-icon-bookmark">
            <BookmarkWithButton id={id} {...bookmarkContent} bookmarkCallback={stateHandler} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )

  return (
    <>
      <Column
        desktop={isCourseModule ? 6 : desktopColumns}
        tablet={tabletColumns}
        mobile={12}
        className={isCourseModule ? "academy-modules-cols" : className}>
        <div className="flex-card">
          {cardImage?.src && (
            <>
              <div className="flex-card-img-section">
                <div className="flex-card-img">
                  <Anchor noUnderline tabindex={0} href={detailsPath} isExternal={false}>
                    <Img
                      type="card"
                      styledimage={cardImage?.styledimage}
                      src={cardImage?.src}
                      alt={cardImage?.alt}
                      width={344}
                      height={246}
                      islazyloaded={false}
                    />
                  </Anchor>
                </div>

                <div className="flex-card-img-wrapper">
                  <Anchor noUnderline tabindex={0} href={detailsPath} isExternal={false}>
                    <div className="flex-card-img-overlay">
                      <div className="flex-card-img-tax-icon">
                        {taxonomies?.length ? (
                          <Taxonomy taxonomies={taxonomies} className="start-0" />
                        ) : (
                          <></>
                        )}
                        {courseCategory?.icon ? (
                          <div className="icon-bg end-0">
                            <Icon iconName={courseCategory?.icon} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Anchor>
                </div>
              </div>
            </>
          )}
          <div className="flex-card-body-section">
            {cardBody}
            {isCourseModule && moduleLinkTitle && user && user.isLoggedIn && (
              <Button tabindex={0} isSecondary onClick={() => handleModuleChange(id)}>
                {moduleLinkTitle ? <span>{moduleLinkTitle}</span> : <></>}
                <Icon iconName="ArrowForward1" />
              </Button>
            )}
          </div>
        </div>
      </Column>
    </>
  )
}

export default FlexibleCard
