import { Column, Container, Img, RichText, Row } from "@atoms"
import { ICourseOverviewTeaser } from "./_courseOverviewTeaser.interface"

const CourseOverviewTeaser = ({
  imagePrimary,
  titlePrimary,
  descriptionPrimary,
  imageSecondary,
  titleSecondary,
  descriptionSecondary,
}: ICourseOverviewTeaser) => {
  return (
    <Container fluid>
      <div className="course-overview-teaser-fluid">
        <Row isTemplateWrapper className="course-overview-teaser-fluid-blockAlign">
          <Column desktop={6} tablet={6} mobile={12} className="course-overview-teaser-fluid-left">
            <div className="course-overview-teaser-fluid-content">
              <RichText
                className="bold course-overview-teaser-fluid-content-title"
                content={titlePrimary}
              />
              <RichText
                content={descriptionPrimary}
                className="course-overview-teaser-fluid-content-text"
              />
            </div>
          </Column>
          <Column desktop={6} tablet={6} mobile={12} className="course-overview-teaser-fluid-image">
            <Img
              className="course-overview-teaser-fluid-image-content"
              type="default"
              width={720}
              height={540}
              src={imagePrimary?.src}
              alt={imagePrimary?.alt}
            />
          </Column>
        </Row>
        <Row isTemplateWrapper className="course-overview-teaser-fluid-blockAlign">
          <Column desktop={6} tablet={6} mobile={12} className="course-overview-teaser-fluid-image">
            <Img
              className="course-overview-teaser-fluid-image-content"
              type="default"
              width={720}
              height={540}
              src={imageSecondary?.src}
              alt={imageSecondary?.alt}
            />
          </Column>
          <Column desktop={6} tablet={6} mobile={12} className="course-overview-teaser-fluid-right">
            <div className="course-overview-teaser-fluid-content">
              <RichText
                className="bold course-overview-teaser-fluid-content-title"
                content={titleSecondary}
              />
              <RichText
                content={descriptionSecondary}
                className="course-overview-teaser-fluid-content-text"
              />
            </div>
          </Column>
        </Row>
      </div>
    </Container>
  )
}

export default CourseOverviewTeaser
