import { EVENTS } from "@utils/gtmEvents"
import { dataLayerPush } from "@utils/gtmutils"
import { MouseEvent } from "react"
import { IAnchor } from "./_anchor.interface"
// import Link from "next/link"
import { getApiDomainAndLang } from "@utils/baseApi"

const Anchor = ({
  className,
  download,
  noUnderline,
  icon,
  isExternal,
  children,
  href,
  onClick,
  dataLayerOnClick,
  title,
  target
}: IAnchor) => {
  const source = href ?? ""
  const { feDomain } = getApiDomainAndLang()
  const path = feDomain?.includes("/") ? feDomain?.split("/")?.[1] : ""

  const getClasses = () => {
    const classes = []
    classes.push("anchor")
    if (className) classes.push(className)
    if (noUnderline) classes.push("no-underline")
    if (icon) classes.push("btn-icon-inline-block")
    return classes.join(" ")
  }

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const { title } = document
    const trackArticleTitle = {
      article_title: title,
    }
    onClick && onClick(event)
    if ((event.target as HTMLElement).innerText === "Print") {
      dataLayerPush(EVENTS.PRINT, trackArticleTitle)
    } else {
      dataLayerPush(EVENTS.NAVIGATION_CLICK, trackArticleTitle)
    }
  }

  let targetValue
  if (target) {
    targetValue = target
  } else {
    if (isExternal) {
      targetValue = "_blank"
    } else {
      targetValue = "_self"
    }
  }

  const validateEmail = (source: string) => {
    return source.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const getSource = () => {
    if (source.startsWith("http") || download) return source
    if (source.includes("#") || source.includes("mailto:") || source.includes("tel:")) return source
    if (source.includes("route:<nolink>")) return "javascript:void(0)"
    if (validateEmail(source)) return source
    if (path && source.startsWith("/") && !source.startsWith(`/${path}`)) return `/${path}${source}`
    if (path && !source.startsWith("/") && !source.startsWith(`${path}/`)) return `/${path}/${source}`
    return source
  }

  return (
    // <Link href={source} legacyBehavior>
    <a
      className={getClasses()}
      target={targetValue}
      href={getSource()}
      rel={isExternal ? "noopener,noreferrer" : undefined}
      download={download ? "download" : false}
      onClick={dataLayerOnClick || handleClick}
      title={title}>
      {icon && <div className="icon">{icon}</div>}
      {children}
    </a>
    // </Link>
  )
}

export default Anchor
