import React, { useContext } from "react"
import { IProductCategoryBannerProps } from "./_productCategoryBanner.interface"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import { getApi } from "@utils/baseApi"
import { useRouter } from "next/router"
import { HeroBanner } from "@organisms"
import { getAllRoles, getPaywalledImgData, returnValIfnotPaywalled } from "@utils/paywallUtilities"
import { connect, useSelector } from "react-redux"
import { updatePaywall3Roles, updatePaywallContent } from "store/actions/CommonActionCreators"
import ApplicationContext from "@utils/application-context/applicationContext"

interface DispatchProps {
  updatePaywallContent: (isPaywall: boolean) => void
  updatePaywall3Roles: (paywallRoles: any) => void
}

interface StateProps {
  paywalledContent: boolean
  user?: any
}

type Props = DispatchProps & IProductCategoryBannerProps & StateProps
const ProductCategoryBanner = (props: Props) => {
  const router = useRouter()
  const brandId = router?.query?.["brand-id"] || router?.query?.["brand-alias"] || router?.query?.slug?.[1] || ""
  const user = useSelector((state: any) => state.user)
  const {
    applicationConfiguration: { isPimProduct = false },
  } = useContext(ApplicationContext)
  const { data } = useSWRRequest(`${props?.blockUrl}/${brandId}`, async () => {
    try {
      const response = await getApi(`${props?.blockUrl}/${brandId}`)
      if (!response?.banner_data?.brandImages) {
        return null
      }
      const fieldPaywallRoles = getAllRoles(response)
      if (fieldPaywallRoles?.length && !user?.isLoggedIn) {
        props?.updatePaywall3Roles(Array.from(new Set(fieldPaywallRoles)))
        props?.updatePaywallContent(true)
      }

      if (response?.banner_data) {
        const bannerData = response?.banner_data
        const bannerImage =
          returnValIfnotPaywalled(bannerData?.brandImages) && bannerData?.brandImages?.length
            ? {
                src: bannerData?.brandImages?.[0]?.src ?? "",
                alt: bannerData?.brandImages?.[0]?.alt ?? "",
                styledimage: bannerData?.brandImages?.[0]?.image_style_uri ?? null,
              }
            : getPaywalledImgData(bannerData?.brandImages)
        return {
          heroBannerContent: {
            title: returnValIfnotPaywalled(bannerData?.brand_title),
            paragraph: returnValIfnotPaywalled(bannerData?.brand_description),
          },
          heroBannerImage: {
            styledimage: bannerImage.styledimage ?? null,
            desktopImage: {
              src: bannerImage.src ?? null,
              alt: bannerImage.alt ?? null,
              width: 1440,
              height: 540,
            },
            mobileImage: {
              src: bannerImage.src ?? null,
              alt: bannerImage.alt ?? null,
              width: 390,
              height: 240,
            },
          },
        }
      }
      return null
    } catch (e) {
      console.log("Something went wrong while fetching banner data")
    }
  })

  // hide the block if no image present
  return data && isPimProduct ? <HeroBanner {...data} /> : <></>
}

const mapDispatch = {
  updatePaywallContent: (isPaywall: boolean) => updatePaywallContent(isPaywall),
  updatePaywall3Roles: (paywallRoles: any) => updatePaywall3Roles(paywallRoles),
}

const mapState = (state: any) => ({
  paywalledContent: state.common.paywallContent,
  user: state.user,
})

export default connect(mapState, mapDispatch)(ProductCategoryBanner)
