import React from "react"
import { Anchor, Img } from "@atoms"
import NEWS_FILTER_CONSTANTS from "@helpers/constants/newsFilter"
import NewsFilterBannerContent from "../../molecules/newsFilterCardsContent"
import { INewsFilterCardsProps } from "./_newsFilterCards.interface"

const NewsFilterCards = ({
  newsFilterCardsContent,
  newsFilterCardsImage,
  className,
}: INewsFilterCardsProps) => {
  const newsFilterCardsImagePath = newsFilterCardsImage?.src || null
  const cardType = newsFilterCardsContent?.cardType
  const redirectLink = newsFilterCardsContent?.link

  const getClasses = () => {
    const classes = ["news-filter", className].filter(Boolean)
    if (cardType === NEWS_FILTER_CONSTANTS.NEWS_CARD_TYPE.SMALL) {
      classes.push("news-filter-small")
    } else classes.push("news-filter-banner")

    return classes.join(" ")
  }

  return (
    <div className={getClasses()}>
      {cardType === NEWS_FILTER_CONSTANTS.NEWS_CARD_TYPE.SMALL && <hr className="top-bar" />}
      {newsFilterCardsImagePath && (
        <div className="news-filter-image">
          <Anchor href={redirectLink} tabindex={0} noUnderline={true}>
            <Img
              className="news-filter-image-content"
              type="news_details"
              styledimage={newsFilterCardsImage.styledimage}
              src={newsFilterCardsImagePath}
              alt={newsFilterCardsImage.alt}
              width={NEWS_FILTER_CONSTANTS.IWIDTH}
              height={NEWS_FILTER_CONSTANTS.IHEIGHT}
            />
          </Anchor>
        </div>
      )}
      <div className="news-filter-content-right">
        <NewsFilterBannerContent {...newsFilterCardsContent} />
      </div>
    </div>
  )
}

export default NewsFilterCards
