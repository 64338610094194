import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMediaQuery } from "@helpers/hooks"
import SearchFilterTable from "@organisms/searchFilterTable"
import { Pagination, TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import moment from "moment"
import { getApiDomainAndLang, middlewareGETAPI } from "@utils/baseApi"
import { getPostedDatesOptions } from "@helpers/dataFunctions/getJobSearchData"
import { fetchOpenOrderTableData, getFilterLabelData } from "@helpers/dataFunctions/getPOListSearchData"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { connect, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { ColumnNameType } from "./_open-order-search-filter.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import {
  addUpdatePOListFilter,
  updateAllPOListFilters,
  updatePOListPagination,
  updatePOListSearchText,
  updatePOListSort,
} from "store/actions/POListSearchActionCreators"
import _ from "lodash"

const ITEMS_PER_PAGE = 20
const DATE_FORMAT = "YYYY-MM-DD"

interface DispatchProps {
  addUpdatePOListFilter: (data: any) => void
  updatePOListPagination: (data: number) => void
  updatePOListSearchText: (data: string) => void
  updateAllPOListFilters: (data: any, page: number, str: string, sort?: any) => void
  updatePOListSort: (data: any) => void
}

type StateTypes = {
  poListSearchFilterData: {
    poListFilters: any
    currentPage: number
    searchText: string
    sort: {
      column: string
      order: string
    }
  }
}

const sortKeyPair: ColumnNameType = {
  customerPo: "po_number",
  poStatus: "po_status",
  dateOrdered: "date_ordered",
  dateShipped: "date_shipped",
}
type Props = DispatchProps & StateTypes
const OpenOrderSearchFilter = (props: Props) => {
  const { poListSearchFilterData } = props
  const { poListFilters, currentPage, searchText, sort } = poListSearchFilterData
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortValue, setSortValue] = useState<any>({ value: "", order: 0 })
  const [poList, setPOList] = useState<searchFilterType[]>([])
  const [sortedList, setSortedList] = useState<searchFilterType[]>([])
  const [filterDetails, setFilterDetails] = useState<any>({})
  const [resultCount, setResultCount] = useState<number>(0)
  const [isUpdated, setIsUpdated] = useState(false)
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>(poListFilters)
  const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE)
  const [openOrders, setOpenOrders] = useState<number>(0)
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")

  const isMobile = useMediaQuery("(max-width: 991px)")
  const router = useRouter()
  const disabledColumnSort = ["trackingNumber", "packingSlip", "invoice"]
  const filterList: searchFilterType[] = sortValue ? sortedList : poList
  const { lang } = getApiDomainAndLang()

  const { applicationConfiguration } = useContext(ApplicationContext)

  const dispatch: Dispatch<any> = useDispatch()

  const updatePOListFilterState: any = useCallback(
    (value: any) => {
      props.addUpdatePOListFilter(value)
    },
    [dispatch],
  )
  const updatePaginationState: any = useCallback(
    (value: number) => props.updatePOListPagination(value),
    [dispatch],
  )

  const updateSearchTextState: any = useCallback(
    (searchText: string) => props.updatePOListSearchText(searchText),
    [dispatch],
  )
  const updateAllFiltersState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props.updateAllPOListFilters(value, page, searchText, sort),
    [dispatch],
  )

  const updatePOListSortState: any = useCallback(
    (value: any) => props.updatePOListSort(value),
    [dispatch],
  )

  const checkIfValueExistsInQuery = (queryStr: string, value: string) => {
    const selectedIds = queryStr ? queryStr.split(",") : []
    return selectedIds.includes(value)
  }

  const checkIfDateFilterExistsInQuery = (queryStr: any, name: string) => {
    return queryStr?.[name]
  }


  useEffect(() => {
    if (!applicationConfiguration?.siteConfig?.cc_po_listing) {
      return
    }

    const fetchFilterDetails = () => {
      const configData = getFilterLabelData(applicationConfiguration.siteConfig)
      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        unselectFiltersCTA: configData.clearLabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
        applyFiltersCTA: configData.applyFiltersCTALabel,
        unSelectItemsLabel: configData.unSelectItemsLabel,
        sortByCTAText: configData.sortByLabel,
        filterIconName: configData.filter_toggle_label,
        openOrderTitle: configData.openOrderTitle
      }

      const filtersOptions = [
        {
          label: configData.poStatusLabel,
          filterName: "po_status",
          selectOptions: {
            isCleared: true,
            isMulti: false,
            options: getPostedDatesOptions(configData.poStatusOptions),
            ariaLabel: configData.poStatusLabel,
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
      ]
      const startDateValues = {
        label: configData?.dateRangeLabel,
        placeHolderTxt: configData?.dateRangePlaceholder,
        filterName: "field_start_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }
      const endDateValues = {
        label: configData.dateRangeToLabel,
        placeHolderTxt: configData?.dateRangeToPlaceholder,
        filterName: "field_end_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }

      configData?.dateRangeToLabel && filtersOptions.unshift(endDateValues)
      configData?.dateRangeLabel && filtersOptions.unshift(startDateValues)

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          customerPo: "po_number",
          poStatus: "po_status",
          dateOrdered: "date_ordered",
          dateShipped: "date_shipped",
          trackingNumber: "tracking_no",
          packingSlip: "packing_slip",
          invoice: "invoice_number",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(configData.tableColumns)
      const displayConfig = {
        tableColumns: Object.values(configData.tableColumns),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
        noResultsDesc: configData.noResultsDesc,
        sortByLabel: configData.sortByLabel,
        searchUrl: "/restapi/customer-connect/po-listing",
        showFiltersCTALabel: configData.showFiltersCTALabel || "Show Filters",
        applyFiltersCTALabel: configData.applyFiltersCTALabel || "Apply the filters",
        filterIconName: configData.filter_toggle_label,
        openOrderCTALabel: configData?.openOrderCTALabel,
        openOrderCTAText: configData?.openOrderCTAText,
        searchTitle: configData?.searchTitle,
        searchDesc: configData?.searchDesc,
        openOrderTitle: configData?.openOrderTitle
      }

      const filterDetailsObj = {
        poSearchContent: filtersOptions,
        filtersLabels: filterLabelsData,
        displayConfig,
        tableColumns: allColumns,
      }
      const selectedCategories = router?.query
      const tempSele = selectedCategories
      const urlFilters = Object.keys(tempSele)

      if (urlFilters.length === 1 && urlFilters[0] === "slug") {
        const updatedSelectOptions = filtersOptions?.map((item) => ({
          ...item,
          selectOptions: {
            ...item.selectOptions,
            options: item.selectOptions.options?.map((val: any) => ({
              ...val,
              isSelected:false,
            })),
          },
        }))
        updatePOListFilterState(updatedSelectOptions)

        const sortFilter = {
          column: selectedCategories?.sort_field || "",
          order: selectedCategories?.sort_order || "ASC",
        }
        updateAllFiltersState(
          updatedSelectOptions,
          Number(selectedCategories?.page ?? 0),
          selectedCategories?.search || "",
          sortFilter,
        )
      }
      setFilterDetails(filterDetailsObj)
      setIsUpdated(true)
    }
    fetchFilterDetails()
  }, [applicationConfiguration])

  const handleSearch = (text: string) => {
    updateSearchTextState(text)
  }

  const handleSortWhole = (key: string) => {
    if (sort.column === "") {
      updatePOListSortState({ column: key, order: "ASC" })
    } else if (sort.column && sort.column === key) {
      if (sort.order === "ASC") {
        updatePOListSortState({ column: key, order: "DESC" })
      } else if (sort.order === "DESC") {
        updatePOListSortState({ column: key, order: "ASC" })
      }
    } else {
      updatePOListSortState({ column: key, order: "ASC" })
    }
  }

  const handleSortKey = (key: string) => {
    if (sort.column === "" || sort.column !== key) {
      updatePOListSortState({ column: key, order: sort?.order })
    } else {
      updatePOListSortState({ column: "", order: sort?.order })
    }
  }

  const handleSortOrder = () => {
    updatePOListSortState({ column: sort?.column, order: sort.order === "ASC" ? "DESC" : "ASC" })
  }

  const clearAllFilters = () => {}

  const handleAdditionalFilters = () => {}

  const fetLocalSelectedValue = () => {}

  const applyAdditionalFilters = () => {}

  const handleClick = () => {}

  const resetLocalFilterWithReducer = () => {}

  const getUrlParams = (selectedFilters: Array<IOption>, filterName: string, passAll = false) => {
    let url = ""
    if (selectedFilters.length && !passAll) {
      if (selectedFilters.length > 1) {
        selectedFilters.forEach((element: any, index: any) => {
          url += `&filter[${filterName}][${index}]=${element.id}`
        })
      } else {
        url += `&filter[${filterName}]=${selectedFilters[0].id}`
      }
    }
    return url
  }

  const frameUrl = ({ filterName, selectedOptions }: any) => {
    const currentFilter = poListFilters.find((item: any) => item.filterName === filterName)
      .selectOptions.options
    return getUrlParams(
      selectedOptions,
      filterName,
      selectedOptions.length === currentFilter.length,
    )
  }

  const fetchAllSelectedFilters = () => {
    const selectedValues = {} as any
    poListFilters?.forEach(
      (item: {
        filterName: string
        selectOptions: { options: any[] }
        placeHolderTxt: string
        value: string
      }) => {
        const formattedDate = moment(Date.parse(item?.value))
          .locale(lang)
          .format(DATE_FORMAT)
        selectedValues[`${item.filterName}`] = !item?.placeHolderTxt
          ? item.selectOptions.options
              ?.filter((val) => val.isSelected)
              .map(({ isSelected, ...rest }: any) => rest)
          : item?.value
          ? [{ label: formattedDate, value: formattedDate }]
          : []
      },
    )
    return selectedValues
  }

  useMemo(() => {
    const selectedFiltersList = poListFilters?.map((item: any) => ({
      filterName: item.filterName,
      selectedOptions: item.selectOptions.options?.filter((val: any) => val.isSelected),
    }))
    const urlValue = selectedFiltersList
      ?.map((filter: any) => frameUrl(filter))
      .join("")
      ?.substring(1)
    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""

    const getTableData = async () => {
      setIsLoading(true)
      const response = await middlewareGETAPI(
        `restapi/customer-connect/po-listing?type=open_orders${urlValue}${sortString}&page=${currentPage}`,
      )
      
      if (response && response.response) {
        const formatedTableData = fetchOpenOrderTableData(response?.response)
        setPOList(formatedTableData)
        setSortedList(formatedTableData)
        // @ts-ignore
        const totalResults = response?.response?.meta?.count
        // @ts-ignore
        const itemsToRender = Number(response?.response?.number_of_items_per_page) ?? ITEMS_PER_PAGE
        // @ts-ignore
        const openOrderCount = response?.response?.meta?.open_orders_count ?? NUMBERS.ZERO
        setItemsPerPage(itemsToRender)
        setResultCount(totalResults)
        setOpenOrders(openOrderCount)

      }
      setIsLoading(false)
    }
    if (isUpdated) {
      getTableData().then(() => {
        const selectedFilterList = fetchAllSelectedFilters()
        const obj = {} as any
        Object.entries(selectedFilterList).forEach(([key, value]: any) => {
          obj[key] = value.length ? value.map((u: any) => u.value).join(",") : ""
        })
        const { protocol, host, pathname } = window.location
        let urlQueryString = ""
        Object.entries(obj).forEach(([key, value]: any) => {
          urlQueryString += `&${key}=${value}`
        })
        const sortUrl = sort?.column ? `&sort_field=${sort?.column}&sort_order=${sort?.order}` : ""
        const newurl = `${protocol}//${host}${pathname}?${urlQueryString.substring(
          1,
        )}&page=${currentPage}` // &search=${searchText || ""}${sortUrl}`
        window.history.replaceState({ path: newurl }, "", newurl)
        setLocalSelectedFilters(poListFilters)
      })
    }
  }, [poListSearchFilterData, isUpdated])

  const handlePagination = (offSet: number) => {
    updatePaginationState(offSet / itemsPerPage)
  }

  const formatMatchString = (arrayLength: number) =>
    arrayLength > NUMBERS.ZERO
      ? filterDetails.displayConfig?.resultsLabel.replace("@count_val", arrayLength.toString())
      : filterDetails.displayConfig?.noResultsLabel.replace("@search_txt", currentSearchText)

  return (
    <>
      {(filterDetails?.displayConfig?.openOrderTitle) && (
        <TitleDescription
          title={filterDetails?.displayConfig?.openOrderTitle}
          description=""
        />
      )}
      {/* @ts-ignore */}
      {Object.keys(filterDetails).length ? (
        <div className="careerSearchFilter scroll-top">
          <EnhancedFilterSearch
            filtersOptions={[]}
            filterLabels={filterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={false}
            handleUnselectAll={clearAllFilters}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={fetLocalSelectedValue()}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText=""
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCount}
            handleSortByKey={handleSortKey}
            handleSortByOrder={handleSortOrder}
            sortList={filterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(poListFilters, localSelectedFilters)}
            disabledAdvanceSearch={true}
            showResultCount={false}
            displayMobileSort={true}
            disabledSearch={true}
            disabledColumnSort={disabledColumnSort}
          />

          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}

          {!isLoading &&
            (filterList?.length ? (
              <div>
                <SearchFilterTable
                  filterData={filterList}
                  columnsList={filterDetails?.displayConfig.tableColumns}
                  tableColumns={filterDetails.tableColumns}
                  handleSort={handleSortWhole}
                  sortValue={sort}
                  disableSortColumnList={disabledColumnSort}
                />
                <div className="search-filter-pagination">
                  {resultCount > itemsPerPage && (
                    <div className="search-filter-pagination">
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        itemsCount={resultCount}
                        onClick={handlePagination}
                        offSet={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <TitleDescription
                title={formatMatchString(sortedList?.length) ?? ""}
                description={filterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                searchKey=""
                className="careerSearchFilter-no-result"
              />
            ))}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdatePOListFilter: (data: any) => addUpdatePOListFilter(data),
  updatePOListPagination: (data: number) => updatePOListPagination(data),
  updatePOListSearchText: (data: string) => updatePOListSearchText(data),
  updateAllPOListFilters: (data: any, page: number, str: string, sort?: any) =>
    updateAllPOListFilters(data, page, str, sort),
  updatePOListSort: (data: any) => updatePOListSort(data),
}

const mapState = (state: any) => ({
  poListSearchFilterData: state.poListReducer,
})

export default connect(mapState, mapDispatch)(OpenOrderSearchFilter)