const URL_TARGET = {
  SELF: "_self",
  BLANK: "_blank",
}

const HTML_EVENTS = {
  CLICK: "click",
}

const DATE_FORMATS = {
  MMMM_DD_YYYY: "MMMM DD, YYYY",
  MM_DD_YYYY: "MM/DD/YYYY",
  YYYY_MM_DD: "YYYY-MM-DD",
  MMMM_YYYY: "MMMM YYYY",
  MMM_DD_YYYY: "MMM DD, YYYY",
  YYYY_MM_DDD: "YYYY/MM/DD",
  YYYY_MM_DD_HMS: "YYYY/MM/DD - HH:mm:SS",
}

const DEFAULT_MIN_DATE = "01/01/1900"

const SECTION_LAYOUT = {
  ONE_COL: "layout_onecol",
  TWO_COL: "layout_twocol_section",
  THREE_COL: "layout_threecol_section",
  TABS: "lb_tabs_tabs"
}

const STICKY_STYLE = ["sticky_yes", "2nd_and_3rd_column_sticky", "3rd_column_sticky"]

const LAYOUT_REGIONS = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  TABS: "lb_tabs_tabs"
}

const COLUMN_POSITION = {
  centre: "justify-content-center",
  left: "justify-content-start",
  right: "justify-content-end",
}

const LOGIN_TYPES = {
  SOLAR: "SOLAR",
  DOC_CHECK: "DOCCHECK",
}

const ENABLE_DISABLE_VALUE = {
  0: false,
  1: true,
}

const newsBlockType = "node--news_and_stories"

const SALES_REP = "SalesRep"

const COLUMN_DESKTOP_LAYOUT = {
  one_column: 12,
  two_column: 6,
  three_column: 4,
}

const COLUMN_TABLET_LAYOUT = {
  one_column: 12,
  two_column: 6,
  three_column: 6,
}

const COLUMN_KEYS = {
  recordNumber: "serial",
  channel: "channel",
  country: "country",
  attributes: "attributes",
  sentToEmailAddress: "email",
  dateOfSubmission: "created",
}

const ARITHMETIC_KEYS = {
  ADDITION: "addition",
  SUBSTRACTION: "substraction",
}

const PIN_PROTECTION = {
  PATIENT: "patient",
  HCP: "hcp",
  PIN_PROTECTION_FOR_PATIENT: "pin_protection_for_patient",
  PIN_PROTECTION_FOR_HCP: "pin_protection_for_hcp",
}

const PAGE_NID_FILTER = "filter[nid]="

const EVENT_TYPE = {
  SCOUT_EVENT: "scout_event",
  SOLAR_EVENT: "solar_event"
}

export {
  URL_TARGET,
  HTML_EVENTS,
  DATE_FORMATS,
  LAYOUT_REGIONS,
  SECTION_LAYOUT,
  DEFAULT_MIN_DATE,
  STICKY_STYLE,
  newsBlockType,
  COLUMN_POSITION,
  LOGIN_TYPES,
  SALES_REP,
  COLUMN_DESKTOP_LAYOUT,
  COLUMN_TABLET_LAYOUT,
  ENABLE_DISABLE_VALUE,
  COLUMN_KEYS,
  ARITHMETIC_KEYS,
  PIN_PROTECTION,
  PAGE_NID_FILTER,
  EVENT_TYPE,
}
