import { Column, Container, RichText, Row } from "@atoms"
import { CourseInfoType, ICourseInformation } from "./_courseInformation.interface"

const CourseInformation = ({ title, courseInfo }: ICourseInformation) => {
  const columnDetails = (courseData: CourseInfoType[]) => {
    let data: any = []
    for (let i = 0; i < courseData.length; i++) {
      data.push(
        <Column desktop={6} tablet={6} mobile={12} className="courseInfo-section-detail" key={i}>
          {courseData[i].title ? (
            <RichText
              className="bold courseInfo-section-detail-title"
              content={courseData[i].title}
            />
          ) : (
            <></>
          )}
          {courseData[i].desc ? (
            <RichText className="courseInfo-section-detail-desc" content={courseData[i].desc} />
          ) : (
            <></>
          )}
        </Column>,
      )
    }
    return data
  }

  return (
    <Container>
      <div className="courseInfo">
        <h2 className="bold courseInfo-title">{title}</h2>
        <Row isTemplateWrapper className="courseInfo-section">
          {courseInfo ? <>{columnDetails(courseInfo)}</> : <></>}
        </Row>
      </div>
    </Container>
  )
}

export default CourseInformation
