import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getMsHeaderMenuData = async (url: string) => {
  let msHeaderMenuData = await getBlockData(url)
  const micrositeMenuData = msHeaderMenuData?.data
  const headerTopSection = {
    logo:
    {
      src: getNestedObject(micrositeMenuData, "relationships.field_header_logo.data.meta.absolute_uri") || "",
      alt: getNestedObject(micrositeMenuData, "attributes.field_header_logo_alt_text") || "",
      href: getNestedObject(micrositeMenuData, "attributes.field_header_logo_link") || "",
    },
    tagLine: getNestedObject(micrositeMenuData, "attributes.field_slogan.value") || "",
    login_register_enable : getNestedObject(micrositeMenuData, "attributes.field_disable_login_register"),
    favicon: getNestedObject(micrositeMenuData, "relationships.field_favicon.data.meta.absolute_uri") || "",
  }
  const headerTopConfig = {
    field_disable_cart: getNestedObject(micrositeMenuData, "attributes.field_disable_cart") || "",
    field_disable_logo: getNestedObject(micrositeMenuData, "attributes.field_disable_logo") || false,
    field_disable_megamenu: getNestedObject(micrositeMenuData, "attributes.field_disable_megamenu") || "",
    field_disable_search: getNestedObject(micrositeMenuData, "attributes.field_disable_search") || "",
    field_disable_slogan: getNestedObject(micrositeMenuData, "attributes.field_disable_slogan") || "",
    field_disable_brand_switcher: getNestedObject(micrositeMenuData, "attributes.field_disable_brand_switcher") || false,
    field_disable_country_switcher: getNestedObject(micrositeMenuData, "attributes.field_disable_country_switcher") || false,
  }

  return {
    headerTopSection,
    headerTopConfig
  }
}

export default getMsHeaderMenuData
