import { getApi } from "@utils/baseApi"

const getCourseInformationData = async (url: string, args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const rawData = await getApi(url.replace(/\?.*/, ""), false)
  const getCourseInfo = () => {
    const newArray = [] as any
    rawData?.map((item: any) => {
      const object = {
        title: item?.field_title_cif ?? "",
        desc: item?.field_description_cif,
      }
      newArray.push(object)
    })
    return newArray
  }
  return {
    title: rawData?.[0]?.sa_key_course_info_cta_label ?? "",
    courseInfo: getCourseInfo() ?? [],
  }
}
export default getCourseInformationData
