import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  tabSelected: false,
  tabChanged: false,
  btnClickTabChanged: false,
  moduleId: null,
}

const AcademyCourseReducer = (state: any = initialFilterData, action: AcademyCourseAction): any => {
  switch (action.type) {
    case actionType.UPDATE_TAB:
      return {
        ...state,
        tabSelected: action.tabSelected,
      }

    case actionType.CHANGE_TAB:
      return {
        ...state,
        tabChanged: action.tabChanged,
      }

    case actionType.CHANGE_TAB_BTN:
      return {
        ...state,
        btnClickTabChanged: action.btnClickTabChanged,
      }
    case actionType.MODULE_ID:
      return {
        ...state,
        moduleId: action.moduleId,
      }

    default:
      return state
  }
}

export default AcademyCourseReducer
