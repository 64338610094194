import { getApi } from "@utils/baseApi"

const getAcademyChartData = async (url: string) => {
  const rawData = (await getApi(url.replace(/\?.*/, ""), false))?.[0]
  return {
    title: rawData?.your_progress_title ?? "",
    desc: rawData?.your_progress_desc ?? "",
    btnLabel: rawData?.your_progress_download_cta_label ?? "",
    progressLabel: rawData?.your_progress_completed_label ?? "",
    chartData: {
      data: [
        {
          name: "Private placements in Switzerland",
          y: 30,
          color: "#1c59b5",
          className: "",
        },
        { name: "Bank loans", y: 70, color: "#a8d5ff", className: "" },
      ],
    },
  }
}

export default getAcademyChartData
