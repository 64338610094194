import { Key, useEffect, useState } from "react"
import { Column, Row } from "@atoms"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import { useMediaQuery } from "@helpers/hooks"
import Pagination from "@molecules/pagination"
import ProductCardListViewContent from "@molecules/productCardListViewContent"
import ViewAndSortOption from "@molecules/viewAndSortOptions"
import AdvanceSearchFilterWithAccordion from "@organisms/advanceSearchFilterWithAccordion"
import { TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import getProductlistData from "@helpers/dataFunctions/getProductListData"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import { IProductListProps } from "./_productlist.interface"

const ProductList = ({ blockUrl, dispalyData }: IProductListProps) => {
  const [searchText, setSearchText] = useState<string>("")
  const filteredURL = blockUrl
  const router = useRouter()
  const searchVal = router?.query?.search ?? ""
  const pageNo = router?.query?.page ? Number(router?.query?.page) : NUMBERS.ONE
  const sortOrder = router?.query?.order ?? ""
  const { data: productData, isLoading } = useSWRRequest(
    `${filteredURL}?page=${pageNo}&search=${searchVal}${
      sortOrder ? `&sort_order=${sortOrder}` : ""
    }`,
    async () => {
      try {
        const productDataSWR = await getProductlistData(
          `${filteredURL}?page=${pageNo}&search=${searchVal}${
            sortOrder ? `&sort_order=${sortOrder}` : ""
          }`,
        )
        return productDataSWR?.data
      } catch (error) {
        console.log(error)
      }
    },
  )
  let plpCatTitle = dispalyData?.title
  const label = dispalyData?.label
  const { GRID_CONFIGURATOR_TEXT, GRID_VIEW, LIST_VIEW } = mediaLibraryConstants
  const defaultView = label?.default_view === GRID_CONFIGURATOR_TEXT ? GRID_VIEW : LIST_VIEW
  const [filteredData, setFilteredData] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)
  const [additionalFilters, setAdditionalFilters] = useState(new Map())
  const [urlFilters, setUrlFilters] = useState<Array<number>>([])

  const [productView, setproductView] = useState(defaultView)
  const isMobile = useMediaQuery("(max-width: 767px)")

  const viewType = isMobile ? "grid" : productView
  const productViewLabels = {
    list_view_text: dispalyData?.label?.list_view_text,
    grid_view_text: dispalyData?.label?.grid_view_text,
  }
  const selectedCategories = router?.query?.category || ""

  useEffect(() => {
    const urlSearchText = (router?.query?.search as string) ?? ""
    // @ts-ignore
    const categoryList = selectedCategories ? selectedCategories?.split(",") : []
    setUrlFilters(categoryList)
    setSearchText(urlSearchText)
  }, [router?.query])

  productData?.allCardsData?.forEach((items: any) => {
    if (Number(selectedCategories) && Number(selectedCategories) === Number(items.category)) {
      plpCatTitle = items.taxonomyLabel
    }
  })

  const handleAdditionalFilters = (label: string, value: any) => {
    setAdditionalFilters(
      new Map(
        additionalFilters.set(
          label,
          value.map((v: any) => v.value || v.id),
        ),
      ),
    )
  }

  useEffect(() => {
    handleTextSearch()
  }, [urlFilters])

  const updateSearchInURL = (searchText: string) => {
    const order = sortOrder ? { order: sortOrder } : {}
    router.push(
      {
        pathname: window.location.pathname,
        query: {
          category: urlFilters.join(","),
          search: searchText,
          page: 1,
          ...order,
        },
      },
      undefined,
      { shallow: true },
    )
  }
  const handleTextSearch = () => {
    setLoading(true)
    const filteredList = productData?.allCardsData?.filter((item: any) => {
      return (
        (searchText && searchText.length
          ? item?.heading?.toLowerCase()?.includes(searchText.toLowerCase()) ||
            item?.description?.toLowerCase()?.includes(searchText.toLowerCase())
          : true) &&
        (urlFilters.length
          ? item?.prodInfoTaxonomies
              .concat(item?.packInfoTaxonomies)
              .concat(item?.category)
              .some((tax: number) => urlFilters.indexOf(tax) >= 0)
          : true)
      )
    })
    setFilteredData(filteredList)
    setLoading(false)
  }
  const applyAdditionalFilters = (searchText: string) => {
    setLoading(true)
    const filterArray = Array.from(additionalFilters, ([name, value]) => ({ name, value })).reduce(
      (acc: any, it: any) => [...acc, ...it.value],
      [],
    )
    router.push(
      {
        pathname: window.location.pathname,
        query: { category: filterArray.join(","), search: searchText },
      },
      undefined,
      { shallow: true },
    )
    setSearchText(searchText)
  }

  const advanceFilter = {
    handleSearch: (searchTextValue: string, e?: any) => {
      e?.preventDefault()
      updateSearchInURL(searchTextValue)
    },
    handleAdditionalFilters,
    searchFieldData: {
      buttonText: dispalyData?.searchLabels?.buttonText,
      isCleared: true,
    },
    clearFilterText: dispalyData?.filterLabels?.clearText,
    labelItemsSelected: "selected items",
    applyFiltertext: dispalyData?.filterLabels?.applyText,
    filterData: dispalyData?.filterOptions,
    searchArgs: dispalyData?.searchLabels,
    resultLabels: dispalyData?.resultLabels,
    resultLength: productData?.totalCount || 0,
    applyAdditionalFilters,
    currentSearchText: searchText,
    handleSearchText: setSearchText,
  }

  const saveIdHandler = () => {
    const changedView = productView === GRID_VIEW ? LIST_VIEW : GRID_VIEW
    setproductView(changedView)
    return changedView
  }

  const getPagination = (
    itemsRestriction: number,
    resultLength: number,
    paginationHandler: (offSet: number) => void,
  ) => (
    <Row className="pagination-wrapper">
      <Pagination
        itemsPerPage={itemsRestriction}
        itemsCount={resultLength}
        onClick={paginationHandler}
        offSet={pageNo - 1}
      />
    </Row>
  )

  const handlePagination = (offSet: number) => {
    const pageNumber = (offSet + dispalyData.itemsPerPage) / dispalyData.itemsPerPage
    const order = sortOrder ? { order: sortOrder } : {}
    router.push(
      {
        pathname: window.location.pathname,
        query: {
          category: urlFilters.join(","),
          search: searchText,
          page: pageNumber,
          ...order,
        },
      },
      undefined,
      { shallow: true },
    )
  }
  const handleSortOrder = (order: string) => {
    router.push(
      {
        pathname: window.location.pathname,
        query: {
          category: urlFilters.join(","),
          search: searchText,
          page: pageNo,
          order,
        },
      },
      undefined,
      { shallow: true },
    )
  }
  const getProductList = () => (
    <>
      {productData?.allCardsData?.length ? (
        <div className={`switch-view-button-spaceContainer ${viewType}-view`}>
          {productData?.allCardsData.map((item: any, index: Key | null | undefined) => (
            <ProductCardListViewContent key={index} {...item} view={viewType} />
          ))}{" "}
        </div>
      ) : (
        <TitleDescription
          title={dispalyData?.resultLabels?.noResultsFoundText}
          description=""
          className="no-result"
        />
      )}

      {productData?.totalCount > dispalyData?.itemsPerPage &&
        getPagination(dispalyData?.itemsPerPage, productData?.totalCount, handlePagination)}
    </>
  )
  return (
    <div>
      {!loading ? (
        <>
          <Row className="switch-view-button">
            <Column desktop={6} tablet={6} mobile={12}>
              <h3 className="plp-title-section">
                {plpCatTitle != "" ? plpCatTitle : dispalyData?.title}
              </h3>
            </Column>
            <Column desktop={6} tablet={6} mobile={12} className="switch-view-button-alignment">
              <ViewAndSortOption
                label={productViewLabels}
                hideSortCTA
                view={productView}
                hideViewTypeCTA={dispalyData?.hideViewCTA}
                onSaveIdHandler={saveIdHandler}
                isMobileDisplay={!!isMobile}
                setOrderSortValue={handleSortOrder}
                // @ts-ignore
                defaultSort={sortOrder ?? "DESC"}
              />
            </Column>
          </Row>
          <Row className="plp-advance-filterdata">
            <Column desktop={3} tablet={12} mobile={12}>
              <AdvanceSearchFilterWithAccordion {...advanceFilter} />
            </Column>
            <Column desktop={9} tablet={12} mobile={12}>
              {!(loading || isLoading) ? (
                getProductList()
              ) : (
                <div className="load-container">
                  <Loader display={true} />
                </div>
              )}
            </Column>
          </Row>
        </>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </div>
  )
}

export default ProductList
