import { useCallback, useContext, useEffect, useState } from "react"
import { Button, Input } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { getApi } from "@utils/baseApi"
import debounce from "lodash.debounce"
import Loader from "@atoms/loader"
import ApplicationContext from "@utils/application-context/applicationContext"
import { trimText } from "@utils/stringparsing"
import CHARLIMIT from "@helpers/constants/charLimit"
import { IPostcodeSearchProps } from "./_postcodeSearch.interface"

const PostcodeSearch = (props: IPostcodeSearchProps) => {
  const { handleTextSearch, postCodeDefaultValues, onSelectSuggestion } = props

  const [resultData, setResultData] = useState<any>({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [textInput, setTextInput] = useState("")
  const [loading, setLoading] = useState<boolean>(true)
  const [displaySuggestions, setDisplaySuggestions] = useState(false)
  const [suggestionList, setSuggestionList] = useState<Array<string>>([])

  const MIN_SEARCH_TEXT_LENGTH: number = NUMBERS.TWO as number

  const applicationConfigContext = useContext(ApplicationContext)
  const { applicationConfiguration } = applicationConfigContext

  useEffect(() => {
    const renderNoResultText = async () => {
      const noResultData = applicationConfiguration.postcodeSearchData
      setResultData({
        postcodesearch_title: noResultData?.postcodesearch_title ?? null,
        postcodesearch_subtitle: noResultData?.postcodesearch_subtitle ?? null,
        postcodesearch_searchplaceholder: noResultData?.postcodesearch_searchplaceholder ?? null,
        postcodesearch_button_details: noResultData?.postcodesearch_button_details ?? null,
      })
    }

    if (applicationConfiguration) {
      renderNoResultText()
    }
  }, [applicationConfiguration])

  const getSearchSuggestions = async (value: string, lookupId: number | null, specialityId: number | null) => {
    try {
      const textLength = value?.length
      if (textLength > MIN_SEARCH_TEXT_LENGTH) {
        const searchTextCode = value?.split(",")[0]
        if (isNaN(parseInt(searchTextCode))) {
          setLoading(false)
          setSuggestionList([])
          return
        }

        const spclid = specialityId ? specialityId : null
        const searchSuggestions = await getApi(`/restapi/postcode-search/${searchTextCode}/${lookupId}/${spclid}`)

        if (searchSuggestions["message"] === "No record found") {
          setLoading(false)
          setSuggestionList([])
        } else {
          const searchSuggestionsList: Array<any> = (
            searchSuggestions?.autosuggest_data as Array<any>
          ).length
            ? searchSuggestions?.autosuggest_data
            : []
          setSuggestionList(searchSuggestionsList)
        }
      } else {
        setSuggestionList([])
      }
    } catch (error) {
      console.error("Failed to fetch postcode!")
    }

    setLoading(false)
  }

  const debounceSearch = useCallback(
    debounce((searchText: string) => getSearchSuggestions(searchText, postCodeDefaultValues?.lookupTargetID, postCodeDefaultValues?.specialityTargetID), 500),
    [postCodeDefaultValues],
  )

  useEffect(() => {
    setIsButtonDisabled(textInput?.trim().length < NUMBERS.THREE)
  }, [textInput])

  useEffect(() => {
    if (textInput) {
      setLoading(true)
      debounceSearch(textInput)
    }
  }, [debounceSearch, textInput])

  const getHighlightedText = (text: string) => {
    const resultTexts = text.split(new RegExp(`(${textInput})`, "gi"))

    return (
      <span>
        {resultTexts.map((part: string, index: number) =>
          part.toLowerCase() === textInput?.toLowerCase() ? <b key={index}>{part}</b> : part,
        )}
      </span>
    )
  }

  const formatText = (item: any) => {
    let label = ""
    if (item?.postcode) {
      label = item.postcode
    }
    if (item?.city) {
      label = label.length ? `${label}, ${item.city}` : item.city
    }
    if (item?.country) {
      label = label.length ? `${label}, ${item.country}` : item.country
    }
    return getHighlightedText(label)
  }

  const closeList = () => {
    setDisplaySuggestions(false)
  }

  const handleSearch = async (e: any, item?: any) => {
    e.preventDefault()

    let value = ""
    if (item) {
      const postcode = item?.postcode ?? ""
      const city = item?.city ?? ""
      const country = item?.country ?? ""

      if (postcode) {
        value += postcode
        if (city || country) {
          value += `, ${city}`
        }
        if (country) {
          value += `, ${country}`
        }
      }
    onSelectSuggestion(item)
    } else {
      value = textInput
    }

    let postcode = ""
    try {
      postcode = value?.split(",")[0].trim() || ""
    } catch (error) {
      console.error("failed to parse postcode!")
    } finally {
      if (postcode && /^\d+$/.test(postcode)) {
        setTextInput(postcode)
        handleTextSearch(postcode, e)
      } else {
        handleTextSearch(postcode, e)
      }

      if (value) {
        setTextInput(value)
      } else {
        handleTextSearch(postcode, e)
      }
    }
    setDisplaySuggestions(false)
    setSuggestionList([])
  }

  const handleChange = (e: any) => {
    setTextInput(e?.target?.value)
    setDisplaySuggestions(true)
  }

  return (
    <div className="postcode-search">
      <form onSubmit={handleSearch}>
        {resultData?.postcodesearch_title && (
          <h2 className="postcode-search-title bold">
            {trimText(resultData?.postcodesearch_title, CHARLIMIT.TITLE)}
          </h2>
        )}
        <div className="postcode-search-content-bar">
          <Input
            className="postcode-search-input"
            value={textInput}
            useStateValue={false}
            aria-label="postcode"
            type="text"
            label={resultData?.postcodesearch_subtitle}
            placeholder={resultData?.postcodesearch_searchplaceholder}
            setTextInput={setTextInput}
            onFocus={() => setDisplaySuggestions(true)}
            onChange={handleChange}
          />

          {displaySuggestions && textInput?.length > MIN_SEARCH_TEXT_LENGTH && (
            <>
              {" "}
              <div
                className={`postcode-search-output ${
                  suggestionList?.length > 0 ? "has-data" : "no-data"
                }`}
                id="suggestion-list">
                <ul>
                  {loading && (
                    <li>
                      <Loader display={loading} />
                    </li>
                  )}
                  {!loading &&
                    (suggestionList?.length > 0 ? (
                      <>
                        {suggestionList.map((item: any, key) => (
                          <li className="postcode-search-output-item" key={`${key + item}`}>
                            <button onClick={(e) => handleSearch(e, item)}>
                              {formatText(item)}
                            </button>
                          </li>
                        ))}
                      </>
                    ) : (
                      <li />
                    ))}
                </ul>
              </div>
              <div className="blanket" onClick={closeList} />
            </>
          )}
        </div>
        <Button
          className="postcode-search-button"
          tabindex={1}
          type="submit"
          isDisabled={isButtonDisabled}>
          {resultData?.postcodesearch_button_details}
        </Button>
      </form>
    </div>
  )
}

export default PostcodeSearch
