import React, { useEffect, useState } from "react"
import InputBoxWithLabel from "@molecules/inputBoxWithLabel"
import { Button, CheckBox, DropDown, Icon, Recaptcha, RichText } from "@atoms"
import { useSelector } from "react-redux"
import NUMBERS from "@helpers/constants/numbers"
import { middlewarePostAPI } from "@utils/baseApi"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import { config } from "@utils/baseApi/config.external"
import { getAssetPrefixUrl, getProfileDetails } from "@utils/helper"
import { handleDataLayerError } from "@utils/gtmUtilsHelpers"
import { useRouter } from "next/router"
import DisplayMessage from "@molecules/displayMessage"
import { IOptiondata, SelectType } from "../contactUs/_contactUs.interface"
import { INewsLetterSignUpProps } from "./_newsLetterSignUp.interface"

const NewsLetterSignUp = (props: INewsLetterSignUpProps) => {
  const { sections, notificationMessages } = props

  const [errors, setErrors] = useState<any>({})
  const [formValues, setFormValues] = useState<any>({})
  const [formSubmitValues, setFormSubmitValues] = useState<any>({})
  const [selectedValues, setSelectedValues] = useState<any>([])
  const [consentChecked, setConsentChecked] = useState(false)
  const [showToast, setShowToast] = useState<"success" | "error" | "">("")
  const [showIndicator, setShowIndicator] = useState<boolean>(false)

  const user: any = useSelector((state: UserState) => state.user)
  const formName = sections?.[0]?.[0]?.formName
  let submitUrl = config.onPrem.WEBFORM_SUBMIT
  const [recaptchaKey, setRecaptchaKey] = useState("")
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(true)

  const router = useRouter()

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, validations: any) => {
    e.preventDefault()
    const fieldName = `${e.target.name}`

    const fieldValue = e.target.value.trim()
    const newErrors: any = { ...errors }
    const { mandatory, pattern, minLength } = validations
    if (fieldValue.length <= NUMBERS.ZERO && mandatory.required) {
      newErrors[fieldName] = mandatory.message
    } else if (fieldValue.length > NUMBERS.ZERO) {
      if (pattern.required && pattern.regEx && !RegExp(pattern.regEx).test(fieldValue)) {
        newErrors[fieldName] = pattern.message
      } else if (
        fieldValue.length > NUMBERS.ZERO &&
        minLength.required &&
        fieldValue.length < minLength.length
      ) {
        newErrors[fieldName] = minLength.message
      } else {
        delete newErrors[fieldName]
      }
    } else {
      delete newErrors[fieldName]
    }
    setErrors(newErrors)
  }

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = `${e.target.name}`
    const fieldValue = e.target.value.trim()
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
  }

  const handleDropdownChange = (option: any, fieldName: string, sectionId: any) => {
    const newObj = {} as SelectType

    if (selectedValues.length === sectionId) {
      newObj[fieldName] = option?.[0]?.key

      setSelectedValues([...selectedValues, newObj])
    }
  }

  const handleCancel = () => {
    setSelectedValues([])
    if (user && user.isLoggedIn) {
      const { first_name, last_name, email_address } = getProfileDetails(user)
      setFormValues({
        first_name,
        last_name,
        email_address,
      })
      setFormSubmitValues({ first_name, last_name, email_address })
    } else {
      setFormValues({})
      setFormSubmitValues({})
    }
    setErrors({})
    setConsentChecked(false)
    window?.scrollTo(NUMBERS.ZERO, NUMBERS.ZERO)
  }

  const showToastMessage = (val: "success" | "error" | "") => {
    setShowToast(val)
    setTimeout(() => {
      setShowToast("")
    }, 10000)
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const captchaElement = document.getElementsByClassName("generic-webform-recaptcha")
    if (!isRecaptchaVerified) {
      captchaElement?.[0]?.classList.add("invalid")
      return
    }
    captchaElement?.[0]?.classList.remove("invalid")

    if (Object.keys(errors).length) {
      setErrors(errors)
    } else {
      try {
        setShowIndicator(true)
        const tempSelectedValues = Object.assign([], selectedValues)
        if (JSON.stringify(selectedValues) !== "[{}]") {
          tempSelectedValues?.shift()
          const topicsValues = tempSelectedValues?.[0]?.topics?.filter((item: any) => {
            return item !== NUMBERS.NEWSLETTER_SIGNUP_VALUE
          }) /// REMOVE 'ALL' Value
          tempSelectedValues[0].topics = topicsValues
        }

        const { fail, error } = await middlewarePostAPI(submitUrl, {
          webform_id: formName,
          ...formValues,
          ...(JSON.stringify(selectedValues) !== "[{}]"
            ? Object.assign({}, ...tempSelectedValues)
            : {}),
          consent_checkbox: true,
          re_captcha: true,
        })

        const trackFormSubmissionEvent = {
          form_name: formName,
          form_error: fail ? `${Object.keys(error?.data?.error)?.join(", ")} missing` : "",
          form_status: fail ? "error" : "submitted",
        }

        dataLayerPush(EVENTS.FORM_SUBMISSION, trackFormSubmissionEvent)

        if (trackFormSubmissionEvent.form_status === "submitted") {
          router.push({ pathname: "/newsletter-verification", query: { result: "success" } })

          setTimeout(() => {
            setShowIndicator(false)
          }, 20000)
        }
      } catch (error) {
        setShowIndicator(false)
        if (error) {
          showToastMessage("error")
        }
        const trackFormSubmissionEvent = {
          form_name: formName,
          form_error: error,
          form_status: "error",
        }
        dataLayerPush(EVENTS.FORM_SUBMISSION, trackFormSubmissionEvent)
        handleDataLayerError("NewsLetterSignup form submission failed", "NewsLetterSignup Form")
      }
    }
  }

  const updateSeletionMulti = (
    sectionId: number,
    option: any,
    fieldName: string,
    allOptions: any,
  ) => {
    const tempSelectedValues = selectedValues
    const newObj = {} as any
    if (selectedValues.length === sectionId) {
      if (option.value === "All") {
        const arr: any = []
        allOptions.map((item: any) => arr.push(item.key))
        newObj[fieldName] = arr
        setSelectedValues([...selectedValues, newObj])
      } else {
        newObj[fieldName] = [option.key]
        setSelectedValues([...selectedValues, newObj])
      }
    } else {
      let tempValue = selectedValues[sectionId][fieldName]

      if (option.key === NUMBERS.NEWSLETTER_SIGNUP_VALUE && tempValue.includes(option.key)) {
        tempSelectedValues.splice(1, 1)
        setSelectedValues([...tempSelectedValues])
        return
      }

      if (option.key === NUMBERS.NEWSLETTER_SIGNUP_VALUE && !tempValue.includes(option.key)) {
        tempValue = []
        allOptions.map((item: any) => tempValue.push(item.key))
        tempSelectedValues[sectionId][fieldName] = tempValue
        setSelectedValues([...tempSelectedValues])
        return
      }

      if (tempValue?.includes(option.key)) {
        const restofValues = tempValue.includes(NUMBERS.NEWSLETTER_SIGNUP_VALUE)
          ? selectedValues[sectionId][fieldName].filter(
            (val: any) => val !== option.key && val != NUMBERS.NEWSLETTER_SIGNUP_VALUE,
          )
          : selectedValues[sectionId][fieldName].filter((val: any) => val !== option.key)

        if (restofValues.length === 0) {
          tempSelectedValues.splice(1, 1)
          setSelectedValues([...tempSelectedValues])
        } else {
          tempSelectedValues[sectionId][fieldName] = restofValues
          setSelectedValues([...tempSelectedValues])
        }
      } else {
        tempSelectedValues[sectionId][fieldName] = tempValue
          ? [...tempValue, option.key]
          : [option.key]
        setSelectedValues([...tempSelectedValues])
      }
    }
  }

  const updateSeletion = (sectionId: number, option: any, fieldName: string) => {
    let tempSelectedValues = selectedValues
    const newObj = {} as SelectType
    if (selectedValues.length === sectionId) {
      newObj[fieldName] = option.key

      setSelectedValues([...selectedValues, newObj])
    } else {
      tempSelectedValues[sectionId][fieldName] = option.key
      tempSelectedValues = tempSelectedValues.slice(NUMBERS.ZERO, sectionId)
      setSelectedValues(tempSelectedValues)
    }
  }

  const updateConsent = () => {
    setConsentChecked(!consentChecked)
  }

  const getButtonClass = (key: number, option: IOptiondata, fieldName: string) => {
    if (selectedValues.length <= key) {
      return "show"
    }
    if (selectedValues[key]?.[fieldName] === option.key) {
      return "show updateColor"
    }
    return "hide"
  }

  useEffect(() => {
    if (user && user.isLoggedIn) {
      const { first_name, last_name, email_address } = getProfileDetails(user)
      setFormValues({
        ...formValues,
        first_name,
        last_name,
        email_address,
      })
      setErrors({
        ...errors,
        first_name: undefined,
        last_name: undefined,
        email_address: undefined,
      })
      setFormSubmitValues({
        ...formSubmitValues,
        first_name,
        last_name,
        email_address,
      })
      const errorObj = { ...errors }
      const fieldsToRemove: string[] = ["first_name", "last_name", "email_address"]
      fieldsToRemove.forEach((field) => {
        if (field in errorObj) {
          delete errorObj[field]
        }
      })
      setErrors({
        ...errorObj,
      })
    }
  }, [user])

  const getRecaptchSiteKey = async () => {
    const { location } = window
    const isLocalhost = !!location.hostname.includes("localhost")
    const url = getAssetPrefixUrl("/api/data-provider/?id=recaptcha", isLocalhost)
    const response = await fetch(url)
    const variables = response.json()
    return variables
  }
  useEffect(() => {
    const spredaArr = sections?.flat()

    spredaArr?.map((recaptcha) => {
      if (recaptcha.type === "custom_recaptcha_element") {
        if (recaptcha.recaptchaSiteKey) {
          setRecaptchaKey(recaptcha.recaptchaSiteKey)
        } else {
          getRecaptchSiteKey().then((variables) => {
            setRecaptchaKey(variables.recaptchaSiteKey || "")
          })
        }
        setRecaptchaVerified(false)
      }
    })
  }, [])

  const recaptchaOnChangeHandler = (token: string | null) => {
    if (token) {
      setRecaptchaVerified(true)
    } else {
      setRecaptchaVerified(false)
    }
  }

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  return (
    <div className="container" key={1}>
      {showIndicator && overlayElement()}

      <form className="newsLetterSignUp-form" onSubmit={handleSubmit}>
        {!!showToast && (
          <DisplayMessage
            displayMessageType={showToast}
            displayTxt={notificationMessages?.[`${showToast}_message`] ?? showToast}
          />
        )}

        {sections?.map((section: any, key: number) => (
          <div
            key={section.type}
            className={` ${selectedValues.length < key ? "hide" : "show"} ${section.filter((item: any) => item.type === "textfield").length
              ? "form-fields"
              : "step"
              }`}>
            {section?.map((item: any, idx: string) => {
              if (item?.type === "button" && item.role === "submit") {
                submitUrl = item.url
              }

              return (
                <>
                  {item?.type === "text" && <RichText className="title" content={item?.title} />}
                  {(item?.type === "textfield" ||
                    item?.type === "tel" ||
                    item?.type === "email") && (
                      <InputBoxWithLabel
                        {...item}
                        value={formValues[item.inputField.name]}
                        key={key + idx}
                        onBlurhandler={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                          handleBlur(e, item.validations)
                        }
                        onChangehandler={handleChange}
                        error={Object.entries(errors)
                          .map(([key, value]: any): string =>
                            item.inputField.name === key ? value : "",
                          )
                          .join("")}
                        maxLength={item.maxLength}
                        id={item.id}
                      />
                    )}
                  {item?.type === "checkbox" &&
                    (key == selectedValues.length || key == sections.length - 1) && (
                      <div>
                        <CheckBox
                          label={<RichText content={item?.label} />}
                          id={item.id}
                          value={item.value}
                          key={key + idx}
                          isChecked={consentChecked}
                          onChange={updateConsent}
                          onKeyPress={updateConsent}
                          tabIndex={0}
                        />
                      </div>
                    )}

                  {item?.type === "button" &&
                    item.role === "submit" &&
                    (key == selectedValues.length || key == sections.length - 1) && (
                      <Button
                        type={item.role}
                        tabindex={NUMBERS.TWO}
                        isDisabled={
                          JSON.stringify(selectedValues) !== "[{}]"
                            ? !consentChecked ||
                              !!Object.keys(errors).length ||
                              selectedValues.length < 2
                            : !consentChecked || !!Object.keys(errors).length
                        }
                        className="submit-btn">
                        {item.text}
                      </Button>
                    )}

                  {item?.type === "button" &&
                    item.role === "reset" &&
                    (key == selectedValues.length || key == sections.length - 1) && (
                      <Button
                        type={item.role}
                        tabindex={NUMBERS.TWO}
                        isSecondary={true}
                        onClick={handleCancel}
                        className="reset-btn">
                        {item.text}
                      </Button>
                    )}

                  {item?.type === "select" && item?.class && (
                    <div className="buttonsGroup">
                      {item.options.map((option: any) => (
                        <Button
                          tabindex={NUMBERS.TWO}
                          icon={
                            selectedValues[key]?.[item.key] === option.key ? (
                              <Icon iconName="closeDarkBlue" />
                            ) : (
                              <></>
                            )
                          }
                          isGhost={false}
                          iconPosition="right"
                          key={option.index}
                          onClick={() => updateSeletion(key, option, item.key)}
                          className={getButtonClass(key, option, item.key)}>
                          {option.value}
                        </Button>
                      ))}
                    </div>
                  )}

                  {item?.type === "select" && !item?.class && (
                    <div className="select-dropdown">
                      <label
                        htmlFor={`dropdown-${item.key}`}
                        aria-required={item?.validations}
                        className="inputWith-label"
                        id={item.key}>
                        {item?.label}
                      </label>
                      <DropDown
                        id={`dropdown-${item.key}`}
                        ariaLabel={item.key}
                        options={item?.options}
                        name={item.key}
                        placeHolder={item.placeholder}
                        showPlaceholder={!!item.placeholder}
                        intialValue={[]}
                        isCleared={true}
                        onValueChange={(val: any) => handleDropdownChange(val, item.key, key)}
                        isMulti={item.isMulti || false}
                        shouldToggleOnHover={false}
                      />
                    </div>
                  )}

                  {item?.type === "webform_term_select" && (
                    <div className="buttonsGroup">
                      {item.options.map((option: any) => (
                        <Button
                          tabindex={NUMBERS.TWO}
                          icon={
                            selectedValues[key]?.[item.key]?.includes(option.key) ? (
                              <Icon iconName="closeDarkBlue" />
                            ) : (
                              <></>
                            )
                          }
                          isGhost={false}
                          iconPosition="right"
                          key={option.index}
                          onClick={() => updateSeletionMulti(key, option, item.key, item.options)}
                          className={
                            selectedValues[key]?.[item.key]?.includes(option.key)
                              ? "updateColor"
                              : ""
                          }>
                          {option.label}
                        </Button>
                      ))}
                    </div>
                  )}

                  {item?.type === "custom_recaptcha_element" && item?.recaptchaSiteKey &&
                    (key == selectedValues.length || key == sections.length - 1) && (
                      <div className="generic-webform-recaptcha" key={key}>
                        <Recaptcha
                          sitekey={item.recaptchaSiteKey}
                          onChange={recaptchaOnChangeHandler}
                        />
                      </div>
                    )}
                </>
              )
            })}
          </div>
        ))}
      </form>
    </div>
  )
}
export default NewsLetterSignUp
