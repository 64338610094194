import * as actionTypes from "../ActionTypes"

export const updateTab = (course: any) => {
  const action: AcademyCourseAction = {
    type: actionTypes.UPDATE_TAB,
    tabSelected: course.tabSelected,
  }
  return performRequest([action])
}

export const changeTab = (course: any) => {
  const action: AcademyCourseAction = {
    type: actionTypes.CHANGE_TAB,
    tabChanged: course.tabChanged,
  }
  return performRequest([action])
}

export const btnClickTabChange = (course: any) => {
  const action: AcademyCourseAction = {
    type: actionTypes.CHANGE_TAB_BTN,
    btnClickTabChanged: course.btnClickTabChanged,
  }
  return performRequest([action])
}
export const moduleId = (course: any) => {
  const action: AcademyCourseAction = {
    type: actionTypes.MODULE_ID,
    moduleId: course.moduleId,
  }
  return performRequest([action])
}

const performRequest = (actions: (UserAction | WebshopAction)[]) => {
  return (dispatch: DispatchType) => {
    actions.forEach((action) => {
      dispatch(action)
    })
  }
}
