import React from "react"
import getDirection from "@utils/direction"
import { IHeroBanner } from "./_heroBanner.interface"
import HeroBannerContent from "../../molecules/heroBannerContent"
import EventBannerContent from "../../molecules/eventBannerContent"
import SmartImage from "../../molecules/smartImage"
import { RichText, SimpleVideo } from "@atoms"

const HeroBanner = ({
  eventBannerContent,
  heroBannerContent,
  heroBannerImage,
  heroBannerVideoFallBackImage,
  heroBannerVideoURL,
  internalLayout,
  isEventBanner,
  referenceField,
  uploadType,
}: IHeroBanner) => {
  const getClasses = () => {
    const classes = []
    classes.push("heroBanner")
    if (internalLayout) {
      classes.push("internal-layout")
    }
    return classes.join(" ")
  }

  return (
    <div className={getClasses()} dir={getDirection()}>
      {uploadType === "image" && heroBannerImage && (
        <div className="heroBanner-wrapper">
          <div className="heroBanner-image">
            <SmartImage {...heroBannerImage} />
          </div>
          {isEventBanner && eventBannerContent ? (
            <EventBannerContent {...eventBannerContent} />
          ) : (
            <HeroBannerContent {...heroBannerContent} />
          )}
        </div>
      )}
      {uploadType === "video" && heroBannerVideoURL && (
        <div className="heroBanner-video-wrapper container-fluid">
          <SimpleVideo
            muted={true}
            autoPlay={true}
            loop={true}
            videoURL={heroBannerVideoURL}
            poster={heroBannerVideoFallBackImage}
          />
          <HeroBannerContent {...heroBannerContent} />
        </div>
      )}
      {!isEventBanner && (
        <>
          {referenceField && (
            <div className="heroBanner-annotation container">
              <RichText className="heroBanner-annotation-text" content={referenceField} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default HeroBanner
