import { getDomainLang } from "@utils/helper"
import { IRowProps } from "./_row.interface"

const Row = (props: IRowProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("row")
    props.className && classes.push(props.className)
    props.isTemplateWrapper && classes.push("template-wrapper")
    const domailLang = getDomainLang()
    if (domailLang.length > 0) {
      classes.push(domailLang)
    }
    return classes.join(" ")
  }
  return (
    <div
      className={getClasses()}
      ref={props?.ref ? props?.ref : null}
      id={props?.id ? props?.id : null}>
      {props.children}
    </div>
  )
}

export default Row
