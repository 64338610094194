import Head from "next/head"
import { ISeoProps } from "./_seo.interface"
import { getDomainLang } from "@utils/helper"

const SEO = (props: ISeoProps) => {
  let domainLang = getDomainLang()
  domainLang = domainLang?.split("-")?.[1]

  return (
    <Head>
      <link rel="canonical" href={props?.canonical || window.location.href} hrefLang={domainLang} />
      <title>{props?.title}</title>
      <meta name="description" content={props?.description} />
      {props?.googleSiteVerification &&
        <meta name="google-site-verification" content={props?.googleSiteVerification} />
      }
      <meta name="keywords" content={props.keywords} />

      {props?.og &&
        Object.entries(props?.og).map(([property, content]) => (
          <meta key={property} property={`og:${property}`} content={content} />
        ))}

      {props?.twitter &&
        Object.entries(props?.twitter).map(([property, content]) => (
          <meta key={property} name={`twitter:${property}`} content={content} />
        ))}

      {props.structuredData && (
        <script type="application/ld+json">{JSON.stringify(props?.structuredData)}</script>
      )}
    </Head>
  )
}

export default SEO