import Maintenance from "@atoms/maintenance"
import getConfigDisplayData from "@helpers/dataFunctions/getConfigDisplayData"
import usePageReady from "@helpers/hooks/usePageReady"
import { Layout } from "@templates"
import { getHeaderData, getHeaderMegaMenu } from "@templates/layout/_layout.data"
import ApplicationContext, { IContextProvider } from "@utils/application-context/applicationContext"
import { ApplicationReducer } from "@utils/application-context/applicationReducer"
import { config } from "@utils/baseApi/config.external"
import ExternalModal from "@utils/externalModal"
import isServer from "@utils/fs/isServer"
import { getAssetPrefixUrl, isAzureFormDependent } from "@utils/helper"
import { getDomainDataFromMiddleware } from "@utils/session-util/sessionHandler"
import Adapter from "components/Adapter"
import AppErrorBoundary from "components/errorBoundary"
import AppShield from "components/shield"
import moment from "moment"
import "moment/min/locales"
import Head from "next/head"
import { useRouter } from "next/router"
import Script from "next/script"
import { nextTick } from "process"
import React, { useEffect, useMemo, useReducer, useState } from "react"
import TagManager from "react-gtm-module"
import { IAppProps } from "../components/templates/layout/_layout.interface"
import "../styles/styles.scss"
import getNestedObject from "@utils/nestedObjects"
import logMessage from "@utils/fs/logger"
import { LOG_TYPES } from "@helpers/constants/logger"
import * as Sentry from "@sentry/nextjs"

declare global {
  var domainData: string
  var domainLang: string
  var feDomainData: string
  var token: string
  var isShield: boolean
  var session: any
  var externalCssPath: string
}

function MyApp({
  Component,
  pageProps,
  megaMenu,
  headerTopSection,
  appData,
}: IAppProps): JSX.Element {
  const {
    domainData,
    feDomainData,
    domainLang,
    token,
    tokenExpiry,
    isShield,
    sessionCookie,
    dir,
    isMaintenance,
    errorMessage,
    theme,
    favicon,
    externalCssPath,
    refererUrl,
    userAuthentication,
    applicationId,
    loggingLevel,
    rewriteAliases,
  } = appData || {}
  const [cookieId, setCookieId] = useState<string>()
  const [showMaintentance, setShowMaintenance] = useState(false)
  const [applicationPageData, setApplicationPageData] = useReducer(ApplicationReducer, {})
  const [applicationConfiguration, setApplicationConfiguration] = useReducer(ApplicationReducer, {})
  const [applicationHeaderData, setApplicationHeaderData] = useReducer(ApplicationReducer, {})
  const [applicationCookieData, setApplicationCookieData] = useReducer(ApplicationReducer, {})
  const [applicationComponentsData, setApplicationComponentsData] = useReducer(
    ApplicationReducer,
    {},
  )
  const [externalCssUrl, setExternalCssUrl] = useState<string>("")
  const [cspVars, setCspVars] = useState<string>("")

  const router = useRouter()
  const isStaticPage = isAzureFormDependent(router.asPath)

  // Check if the current path is in noIndexPaths array
  const noIndexStaticPaths = [
    "/sign-up",
    "/invited-registration",
    "/profile",
    "/my-profile",
    "/my-registration",
  ]
  const isNoIndexPage = noIndexStaticPaths?.includes(router?.pathname)

  const applicationContextProvidervalue: IContextProvider = useMemo(
    () => ({
      applicationPageData,
      setApplicationPageData,
      applicationConfiguration,
      setApplicationConfiguration,
      applicationHeaderData,
      setApplicationHeaderData,
      applicationCookieData,
      setApplicationCookieData,
      applicationComponentsData,
      setApplicationComponentsData,
    }),
    [
      applicationPageData,
      setApplicationPageData,
      applicationConfiguration,
      setApplicationConfiguration,
      applicationHeaderData,
      setApplicationHeaderData,
      applicationCookieData,
      setApplicationCookieData,
      applicationComponentsData,
      setApplicationComponentsData,
    ],
  )

  /**
   *
   * @returns Check for Maintenance mode
   */
  useEffect(() => {
    console.error("Version: 4.3.0")

    if (isMaintenance === "1") {
      setShowMaintenance(true)
    } else {
      setShowMaintenance(false)
    }

    if (!isServer()) {
      sessionStorage.setItem("domainData", JSON.stringify(domainData))
      sessionStorage.setItem("feDomainData", JSON.stringify(feDomainData))
      sessionStorage.setItem("domainLang", JSON.stringify(domainLang))
      sessionStorage.setItem("token", JSON.stringify(token))
      sessionStorage.setItem("tokenExpiry", JSON.stringify(tokenExpiry))
      sessionStorage.setItem("cssPath", JSON.stringify(externalCssPath))
      sessionStorage.setItem("loggingLevel", JSON.stringify(loggingLevel))
      sessionStorage.setItem("rewriteAliases", rewriteAliases || "")
      const extCssPath = externalCssPath || sessionStorage.getItem("cssPath")
      if (extCssPath) {
        setExternalCssUrl(extCssPath)
      }
      moment.locale(domainLang)
    }
  }, [])

  const initGtm = async () => {
    const { location } = window
    const isLocalhost = !!location.hostname.includes("localhost")
    const url = getAssetPrefixUrl("/api/data-provider/?id=gtm", isLocalhost)
    const response = await fetch(url)
    const variables = response.json()
    return variables
  }

  const getCSPHeaders = async () => {
    const { location } = window
    const isLocalhost = !!location.hostname.includes("localhost")
    const url = getAssetPrefixUrl("/api/data-provider/?id=csp", isLocalhost)
    const response = await fetch(url)
    const variables = response.json()
    return variables
  }

  useEffect(() => {
    const getLanguage = () => {
      const lang = JSON.parse(sessionStorage.getItem("domainLang") || "en")
      return lang
    }
    document.documentElement.lang = getLanguage()

    if (!isStaticPage) {
      initGtm()
        .then((gtmVars) => {
          const tagManagerArgs = {
            gtmId: gtmVars.gtmId ? gtmVars.gtmId : config.gtmId,
          }
          TagManager.initialize(tagManagerArgs)
        })
        .catch((err) => {
          console.log("gtm code load error!")
        })
    }
  }, [])

  useEffect(() => {
    if (!router.asPath.includes("sitemap.xml")) {
      require("bootstrap/dist/js/bootstrap.bundle.min.js")
    }

    if (router.asPath.includes("sitemap.xml")) {
      router.reload()
    }
  }, [cookieId, router.asPath])

  const {
    isLoading,
    pageData,
    appHeaderData,
    fetchHeaderData,
    fetchCMSData,
    fetchApplicationConfiguration,
  } = usePageReady()

  const appComponents = applicationComponentsData?.appComponents
  const currPageComp =
    appComponents?.find((data: any) => data.pageAlias === pageProps?.pageAlias)?.componentConfig ||
    null
  const micrositeFavicon =
    getNestedObject(currPageComp, "header.data.headerTopSection.favicon") || favicon

  useEffect(() => {
    if (!router.asPath.includes("sitemap.xml")) {
      getConfigDisplayData().then((configData: any) => {
        const getAppHeaderData = async () =>
          await fetchHeaderData(applicationHeaderData, setApplicationHeaderData, configData?.data)

        const getCMSData = async () =>
          await fetchCMSData(applicationPageData, setApplicationPageData, configData?.data)
        if (configData?.data?.one_trust_cookie_uuid) {
          setCookieId(configData?.data?.one_trust_cookie_uuid)
        }
        getAppHeaderData()
        getCMSData()
        fetchApplicationConfiguration(
          applicationConfiguration,
          setApplicationConfiguration,
          configData?.data,
        )
      })
    }
  }, [router.asPath])

  /**
   * CSP headers
   */
  useEffect(() => {
    getCSPHeaders().then((domains: any) => {
      if (domains) {
        const regDomains = domains?.cspHeaders?.replace(/,/g, " ")
        const csp = `
                default-src 'self' blob: data: https: ${regDomains};
                script-src 'self' 'unsafe-inline' 'unsafe-eval' https: https://www.googletagmanager.com https://cdn.cookielaw.org https://cdnapisec.kaltura.com https://ssl.google-analytics.com https://www.youtube.com https://www.google.com ${regDomains};
                style-src 'self' 'unsafe-inline' https: ${regDomains};
                img-src 'self' blob: data: https: ${regDomains};
                connect-src 'self' https: ${regDomains};
                font-src 'self' data: https:;
                object-src 'self' ${regDomains};
                base-uri 'self';
                form-action 'self' https: ${regDomains};
                block-all-mixed-content;
                upgrade-insecure-requests;
              `
        setCspVars(csp)
      }
    })
  }, [])

  const rootPage = (
    <>
      <Head>
        {micrositeFavicon ? (
          <link rel="icon" href={micrositeFavicon} />
        ) : (
          <link rel="icon" href={getAssetPrefixUrl("/favicon.ico")} />
        )}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {isNoIndexPage && <meta name="robots" content="noindex, nofollow" />}
        {process.env.NEXT_PUBLIC_ENV !== "dev" ? (
          <meta httpEquiv="Content-Security-Policy" content={cspVars} />
        ) : (
          <></>
        )}
        {(externalCssUrl) && (
          <>
            <link rel="preload" href={externalCssUrl} as="style" />
            <link rel="stylesheet" href={externalCssUrl} />
          </>
        )}
      </Head>
      {!isServer() && !isAzureFormDependent(window.location.href) && cookieId && (
        <>
          <Script
            type="text/javascript"
            src={`https://cdn.cookielaw.org/consent/${cookieId}/OtAutoBlock.js`}
          />
          <Script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            data-domain-script={`${cookieId}`}
          />
          <Script src={getAssetPrefixUrl("/assets/js/ios14.js")} />
        </>
      )}

      {/* do not show header & footer for sitemap.xml page */}
      {router.asPath.includes("sitemap.xml") || router.asPath.includes("password-reset") ? (
        <Component {...pageProps} dir={dir} key={router.asPath} />
      ) : (
        <AppErrorBoundary>
          <Adapter
            applicationId={applicationId}
            theme={theme === "" || theme === null ? "solar" : theme}
            userAuthentication={userAuthentication}>

            {(pageData?.externalLinkModal && pageData?.externalLinkModal?.enabled === 1 ||  pageData?.internalModalData && pageData?.internalModalData?.enabled) && (
              <><ExternalModal 
              {...pageData?.externalLinkModal}
              internalModalData = {pageData?.internalModalData}
              externalModelEnabled = {pageData?.externalLinkModal?.enabled === 1}
              internalModelEnabled = {pageData?.internalModalData?.enabled}
              />
              </>
            )}
            <Layout
              loading={isLoading}
              floatingBanner={pageData?.floatingBanner}
              headerMegaMenu={megaMenu ?? pageData?.headerMegaMenu}
              footerNavigation={pageData?.footerNavigation}
              footerSecondaryMenu={pageData?.footerSecondaryMenu}
              footerBottomSection={pageData?.footerBottomSection}
              footerMicrosite={pageData?.footerMicrosite}
              floatingModule={pageData?.floatingModule}
              isiBannerDetails={pageProps?.isiBannerDetails}
              liteFooter={pageData?.liteFooter}
              headerLightMenu={appHeaderData?.headerLightMenu}
              headerTopSection={headerTopSection ?? appHeaderData?.headerTopSection}
              loginFormData={appHeaderData?.loginFormData}
              eyebrowMenu={appHeaderData?.eyebrowMenuData}
              hcpModal={appHeaderData?.hcpModal}
              pageAlias={pageProps?.pageAlias}
              userSession={sessionCookie}
              pageHcpModal={pageProps?.renderHcpModalPopUp}
              isDeepLink={pageProps?.isDeepLink}
              refererUrl={refererUrl}
              bannerModuleData={pageData?.bannerModuleData}
              micrositeConfig={pageProps?.micrositeConfig}
              headers={pageProps?.header1}
              countryAndBrandSwitcherData={pageData?.countryAndBrandSwitcherData}
              referenceTextData={pageProps?.referenceTextData}>
              <Component {...pageProps} dir={dir} key={router.asPath} />
            </Layout>
          </Adapter>
        </AppErrorBoundary>
      )}
    </>
  )

  const rootPageWithShield = (
    <AppShield isDisabled={!isShield} isServer={isServer()}>
      <ApplicationContext.Provider value={applicationContextProvidervalue}>
        {rootPage}
      </ApplicationContext.Provider>
    </AppShield>
  )

  if (showMaintentance) {
    return <Maintenance errorMessage={errorMessage} />
  }

  return <>{isStaticPage ? rootPage : rootPageWithShield}</>
}

MyApp.getInitialProps = async ({ ctx }: any) => {
  ctx?.res?.setHeader("Cache-Control", "public, s-maxage=300, stale-while-revalidate=1000")
  const refererUrl = ctx?.req?.headers?.referer ?? ""

  const {
    domainData,
    feDomainData,
    domainLang,
    token,
    tokenExpiry,
    isShield,
    isMaintenance,
    errorMessage,
    externalCssPath,
  } = getDomainDataFromMiddleware(ctx)
  const queryParams = ctx?.query
  const userAuthentication = {
    csrfToken: queryParams?.token || "",
    sessionId: queryParams?.sid || "",
    firstName: queryParams?.f_name || "",
    lastName: queryParams?.l_name || "",
  }

  const lang = domainLang || ctx?.req?.headers["accept-language"]
  const rtlLanguages = ["ar", "he", "fa", "ur"] // List of RTL language codes
  const dir = rtlLanguages.includes(lang || "") ? "rtl" : "ltr"
  nextTick(() => {
    ctx.dir = dir
  })

  if (
    ctx.asPath.includes("undefined") ||
    ctx.asPath.includes("json,version") ||
    ctx.asPath.includes("/json/version") ||
    ctx.asPath.includes("/sitemap.xml") ||
    ctx.asPath.split("/").lastIndexOf("json") !== -1
  ) {
    return {
      appData: {
        domainData,
        feDomainData,
        domainLang,
        token,
        tokenExpiry,
        isShield,
        dir,
        isMaintenance,
        errorMessage,
        externalCssPath,
        userAuthentication,
      },
    }
  }

  const serverData = {
    cmsUrl: domainData,
    feUrl: feDomainData,
    token,
    tokenExpiry,
    language: domainLang,
  }
  const configDisplayData = await getConfigDisplayData(serverData)
  const megaMenu = await getHeaderMegaMenu(serverData, null, configDisplayData?.data)
  
  const theme = configDisplayData?.data?.style_type
  const favicon = configDisplayData?.data?.favicon_image_url
  const headerTopSection = getHeaderData(configDisplayData?.data)
  const applicationId = configDisplayData?.data?.client_id || ""
  if (!theme) {
    const msg = JSON.stringify(serverData)
    logMessage(true, LOG_TYPES.ERROR, 200, "/jsonapi/solar/configuration-display", "[Error] Custom site config corrupted! " + msg, serverData?.cmsUrl)
    Sentry.captureException("[Error] Custom site config corrupted! " + msg)
  }
  if (!megaMenu?.length) {
    const msg = JSON.stringify(serverData)
    logMessage(true, LOG_TYPES.ERROR, 200, "/jsonapi/menu_link_content/main?page%5Boffset%5D=0&page%5Blimit%5D=200&filter[enabled][value]=1", "[Error] No mega menu! " + msg, serverData?.cmsUrl)
    Sentry.captureException("[Error] No mega menu! " + msg)
  }

  ctx?.res?.setHeader("domain-application-id", applicationId)
  const { sessionCookie, loggingLevel, rewriteAliases } = getDomainDataFromMiddleware(ctx)
  return {
    appData: {
      domainData,
      feDomainData,
      domainLang,
      token,
      tokenExpiry,
      isShield,
      sessionCookie,
      dir,
      isMaintenance,
      errorMessage,
      theme,
      favicon: favicon ?? null,
      externalCssPath,
      refererUrl,
      userAuthentication,
      applicationId,
      loggingLevel,
      rewriteAliases,
    },
    megaMenu,
    headerTopSection,
  }
}

export default MyApp
