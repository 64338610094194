import { getAssetPrefixUrl } from "./helper"

interface RoleObject {
  [key: string]: {
    error_code: number
    error_msg: string
    image_option: null | string
    roles: string | null
    country?: string | null
  }
}

const findKeysWithErrorCode = (obj: any, targetValues: any[], currentPath = ""): string[] => {
  const result: string[] = []

  const searchRecursive = (innerObj: any, currentPath: string) => {
    if (typeof innerObj === "object") {
      for (const key in innerObj) {
        if (key === "error_code" && targetValues.includes(innerObj[key])) {
          innerObj.image_option = innerObj.image_option ?? null
          const obj = {} as any
          obj[currentPath] = innerObj
          result.push(obj)
        }
        searchRecursive(innerObj[key], `${currentPath + key}.`)
      }
    }
  }

  searchRecursive(obj, currentPath)
  return result
}

const getAllRoles = (paywallData: any) => {
  // Find all the keys with error codes 410 and 310 from the paywall data.
  const errorFields: RoleObject[] = findKeysWithErrorCode(paywallData, [410, 310]) as []
  const allRoles: string[] = []

  if (errorFields?.length) {
    errorFields?.forEach((item) => {
      // Extract the roles and split them into an array if they are comma-separated.
      const roles =
        Object.values(item)?.[0]
          ?.roles?.split(",")
          ?.filter((role) => role?.trim() !== "") || []

      // Extract the countries, handle both array and comma-separated string formats.
      const countries = Array.isArray(Object.values(item)?.[0]?.country)
        ? Object.values(item)?.[0]?.country
        : Object.values(item)?.[0]
            ?.country?.split(",")
            ?.filter((country) => country?.trim() !== "") || []

      // If both roles and countries are arrays, merge them into the allRoles array.
      if (Array.isArray(roles) && Array.isArray(countries)) {
        allRoles.push(...roles, ...countries)
      }
    })
  }

  // Return the consolidated list of roles and countries.
  return allRoles
}

const getAllRolesOTC = (paywallData: any) => {
  const errorFields: RoleObject[] = findKeysWithErrorCode(paywallData, [421]) as []
  const allRoles: string[] = []
  if (errorFields?.length){
    errorFields?.forEach((item) => {
      const roles = Object.values(item)?.[0]?.roles?.split(",") || []
      const country = Object.values(item)?.[0]?.country?.split(",") || []
      allRoles.push(...roles, ...country)
    })
  }
  return allRoles
}

const findValueByKey = (arr: any, key: string): any | undefined => {
  for (const obj of arr) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return obj[key]
    }
  }
  return null
}

const isPaywallProtected = (paywallData: any) => {
  return {
    isPaywallProtectedFlag: paywallData?.error_code === 410 || paywallData?.error_code === 421 || paywallData?.error_code === 310,
    errorObj: paywallData,
  }
}

const isEmptyDesc = (desc: any) => desc?.every((val: any) => val == null)

export const returnValIfnotPaywalled = (value: any) => {
  if (isPaywallProtected(value).isPaywallProtectedFlag) {
    return ""
  }
  return value ?? ""
}

const getImgData = (imageOption: string | any, type: string) => {
  switch (type) {
    case "grey_overley":
      return getAssetPrefixUrl("/assets/images/Paywall-Grey-Overlay.svg?isPaywall=true")
    case "lock_icon":
      return getAssetPrefixUrl("/assets/images/Paywall-Lock.svg?isPaywall=true")
    case "hide_image":
      return ""
    case "other":
      return imageOption?.image_style?.image_style_uri ?? ""
    default:
      return ""
  }
}

const getPaywalledImgData = (paywallData: any) => {
  const imageOption = paywallData?.image_option
  let type = ""
  if (typeof imageOption === "string") {
    type = imageOption?.includes("other") ? imageOption?.split(":")?.[0] : imageOption
  } else {
    type = imageOption?.option
  }
  const paywalledImgObject = {} as any
  paywalledImgObject.imageUrl =
    type === "other" ? imageOption?.image_style?.src : getImgData(imageOption, type)
  paywalledImgObject.src =
    type === "other" ? imageOption?.image_style?.src : getImgData(imageOption, type)
  paywalledImgObject.alt = "protected image"
  paywalledImgObject.imageStyledUrl = type === "other" ? getImgData(imageOption, type) : ""
  paywalledImgObject.styledimage = type === "other" ? getImgData(imageOption, type) : ""
  paywalledImgObject.logoImageUrl =
    type === "other" ? imageOption?.image_style?.src : getImgData(imageOption, type)
  paywalledImgObject.logoImageAlt = ""
  paywalledImgObject.mobileImageUrl =
    type === "other" ? imageOption?.image_style?.src : getImgData(imageOption, type)
  paywalledImgObject.mobileImageAlt = "protected image"
  paywalledImgObject.layout = "fill"
  paywalledImgObject.type = type === "other" ? "magazine_one_column" : "default"
  const desktopImage = {
    src: type === "other" ? imageOption?.image_style?.src : getImgData(imageOption, type),
    alt: "protected image",
    styledimage: type === "other" ? getImgData(imageOption, type) : "",
    width: 2880,
    height: 1080,
  }
  paywalledImgObject.desktopImage = desktopImage
  return paywalledImgObject
}

const findFieldPaywallRoles = (arr: any) => {
  let fieldPaywallRoles: string[] = []

  for (const item of arr) {
    if (Array.isArray(item)) {
      fieldPaywallRoles = fieldPaywallRoles.concat(findFieldPaywallRoles(item))
    } else if (typeof item === "object" && item !== null) {
      if (Object.prototype.hasOwnProperty.call(item, "fieldPaywallRoles")) {
        fieldPaywallRoles = fieldPaywallRoles.concat(item.fieldPaywallRoles)
      }
      const values = Object.values(item)
      for (const value of values) {
        if (typeof value === "object" && value !== null) {
          fieldPaywallRoles = fieldPaywallRoles.concat(findFieldPaywallRoles([value]))
        }
      }
    }
  }

  return fieldPaywallRoles
}

const filterNullValues = (arr: any) => arr?.filter((item: any) => item !== null)

export {
  findKeysWithErrorCode,
  isPaywallProtected,
  findValueByKey,
  getPaywalledImgData,
  getAllRoles,
  findFieldPaywallRoles,
  getAllRolesOTC,
  filterNullValues,
  isEmptyDesc
}
