import { Column, Container, Seperator } from "@atoms"
import React from "react"
import { LabelGroup, ReadMoreContent, SmartImage, TextBlockProfile } from "@molecules"
import { IPatientProfileProps } from "./_patientProfile.interface"

const PatientProfile = (props: IPatientProfileProps) => {
  const { titleDetails, readMoreContent, desktopImage, mobileImage, personalDetails, styledimage } = props
  return (
    <Container>
      <div className="patientProfile">
        <div className="row">
          <Column desktop={7} tablet={12} mobile={12} className="patientProfile-divtext">
            <div className="patientProfile-heading">{titleDetails?.title}</div>
            <div className="patientProfile-subHeading">{titleDetails?.subTitle}</div>
            <Seperator className="patientProfile-seperator" />
            <TextBlockProfile {...titleDetails} />
            <Seperator className="patientProfile-seperator" />
            <LabelGroup {...personalDetails} />
            <Seperator className="patientProfile-seperator" />
            <ReadMoreContent className="patientProfile-readMore" {...readMoreContent} />
          </Column>
          <Column desktop={5} tablet={12} mobile={12} className="patientProfile-imagecol">
            <div>
              <SmartImage styledimage={styledimage} desktopImage={desktopImage} mobileImage={mobileImage} />
            </div>
          </Column>
        </div>
      </div>
    </Container>
  )
}

export default PatientProfile
