import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData, getImageMeta, getTaxonomy } from "."
import getSearchFacetsData from "./getSearchFacetsData"
import SEARCH_FACETS from "@helpers/constants/searchFacets"
import { getApi} from "@utils/baseApi"

const getCouesrCatalogueCategoryFilterData = async (serverData?: any) => {
  let categoryData = await getApi("/jsonapi/solar/sandoz-academy-course-apiresponse") // change api from here
  categoryData = categoryData?.data
  if (categoryData) {
    const CategoryLabelData = {
      searchLabel: categoryData?.sandoz_academy_what_are_you_looking_for_text,
      searchPlaceholder: categoryData?.sandoz_academy_search_placeholder_text,
      searchCTAText: categoryData?.sandoz_academy_search_cta_text,
      AlphaLabel: categoryData?.sandoz_academy_label_alphabetical_order,
      FormLabel: categoryData?.sandoz_academy_what_are_you_looking_for_text, //not found
      clearLabel: categoryData?.sandoz_academy_search_clear_all_text,
      other_category_text: categoryData?.sandoz_academy_label_speciality, //notfound
      course_catalogue_title: categoryData?.sandoz_academy_what_are_you_looking_for_text, // notfound
      resultsFoundText: categoryData?.sandoz_academy_search_result_found_text,
      noResultsFoundText: categoryData?.sandoz_academy_no_result_found_text,
      noResultsFoundDescription: categoryData?.sandoz_academy_no_results_found_body?.value,
      unSelectItemsLabel: categoryData?.sandoz_academy_search_clear_all_text,
      applyAllfilters: categoryData?.sandoz_academy_apply_all_filters,
      itemsPerPage: categoryData?.sandoz_academy_pagination,
      dropwdown_placeholder_text: categoryData?.sandoz_academy_select_option_text ?? "",
      course_catalogue_search_default_config: categoryData?.sandoz_academy_search_default_config ?? "",
      course_catalogue_search_facets: categoryData?.sandoz_academy_search_facets ?? [],
      course_catalogue_label_profession: categoryData?.sandoz_academy_label_profession ?? "",
      course_catalogue_label_course_category: categoryData?.course_category_label ?? "",
      course_catalogue_label_course_suggestion: categoryData?.course_suggestion_label ?? "",
      course_catalogue_label_therapeutic_area: categoryData?.sandoz_academy_label_therapeutic_area ?? "",
      course_catalogue_label_disease_area: categoryData?.sandoz_academy_label_disease_area ?? "",
      course_catalogue_label_sandoz_academy_topics: categoryData?.sandoz_academy_topics_label ?? "",
      course_catalogue_label_audience: categoryData?.sandoz_academy_label_audience ?? "",
      course_cataloguen_course_difficulty_level: categoryData?.course_difficulty_label ?? "",
      filter_toggle_label: categoryData?.filter_toggle_label ?? "",
    }
    return CategoryLabelData
  }


  return {
    searchLabel: "",
    searchPlaceholder: "",
    searchCTAText: "",
    AlphaLabel: "",
    FormLabel: "",
    clearLabel: "",
    other_category_text: "",
    course_catalogue_title: "",
    resultsFoundText: "",
    noResultsFoundText: "",
    noResultsFoundDescription: "",
    unSelectItemsLabel: "",
    applyAllfilters: "",
    itemsPerPage: "",
    dropwdown_placeholder_text: "",
    course_catalogue_search_default_config: "",
    filter_toggle_label: "",
  }
}

const getFormattedObj = (objectList: any[]) => {
  const options: any = []
  objectList?.map((option: any, key: number) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

export const getFormattedFlexibleCardData = async (url: any) => {
  let flexibleCards = await getBlockData(url)
  const totalCount = Number(flexibleCards?.meta?.count) ?? 0
  let categoryData = await getApi("/jsonapi/solar/sandoz-academy-course-apiresponse") // change api from here
  categoryData = categoryData?.data
  const itemsPerPage = Number(categoryData?.sandoz_academy_pagination)
  flexibleCards = flexibleCards?.data
  const fieldPaywallRoles = getAllRoles(flexibleCards)
  console.log(flexibleCards)
  flexibleCards =
    flexibleCards && flexibleCards.length
      ? await Promise.all(
        flexibleCards?.map(async (flexCard: any) => {
          const isChildPaywalled = isPaywallProtected(
            getNestedObject(flexCard, "attributes.enable_paywall_block_level"),
          )?.isPaywallProtectedFlag

          if (isChildPaywalled) {
            return null
          }
          const imagePaywallData = isPaywallProtected(flexCard?.relationships?.field_sac_image)
          const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
            ? getPaywalledImgData(imagePaywallData?.errorObj)
            : getImageMeta(flexCard?.relationships?.field_sac_image)
          const cardImage = {
            src: imageUrl,
            styledimage: imageStyledUrl,
            alt: alt
          }
          const courseSuggestions = {
            label: flexCard?.relationships?.field_course_suggestion?.data?.meta?.term_name || null,
            icon: flexCard?.relationships?.field_course_suggestion?.data?.icon_type || "academyBarcodeIcon"
          }
          const courseCategorys = {
            label: flexCard?.relationships?.field_course_category?.data?.meta?.term_name || null,
            icon: flexCard?.relationships?.field_course_category?.data?.icon_type || null
          }
          const taxonomies = await getTaxonomy(
            flexCard?.relationships?.field_category?.links?.related?.href,
            true,
          )
          const cardType = await getTaxonomy(
            flexCard?.relationships?.field_event_type?.links?.related?.href,
            true,
          )
          const credits = [{ taxonomyLabel: (flexCard?.attributes?.field_credits || "") + " " + (categoryData?.credits_label || "") }]

          const specialityId =
            flexCard?.relationships?.field_specialty?.data?.[0]?.meta
              ?.drupal_internal__target_id ?? null
          const fromDate = flexCard?.attributes?.field_course_commencement_date || null
          const toDate = flexCard?.attributes?.field_course_expiration_date || null
          const difficultyLevel =
          {
            label: flexCard?.relationships?.field_course_difficulty_level?.data?.meta?.term_name || null,
            icon: flexCard?.relationships?.field_course_difficulty_level?.data?.icon_type || null
          }
          const courseDuration =
          {
            label: flexCard?.attributes?.field_course_duration?.duration || null,
            icon: "TimeIcon"
          }
          return {
            id: flexCard?.attributes?.drupal_internal__nid ?? "",
            taxonomies: await getTaxonomy(
              flexCard?.relationships?.field_sandoz_academy_topics?.links?.related?.href,
              true,
            ),
            title: isPaywallProtected(flexCard?.attributes?.title).isPaywallProtectedFlag
              ? null
              : flexCard?.attributes?.title ?? null,
            desc: flexCard?.attributes?.body?.processed
              ? flexCard?.attributes?.body?.processed
              : null,
            time: isPaywallProtected(flexCard?.attributes?.field_time).isPaywallProtectedFlag
              ? null
              : flexCard?.attributes?.field_time ?? null,
            contentType: cardType?.[0]?.taxonomyLabel ?? null,
            avgRatingTitle: flexCard?.attributes?.field_avgRating?.title
              ? flexCard?.attributes?.field_avgRating?.title
              : null,
            difficultyLevel: difficultyLevel ? difficultyLevel : null,
            courseDuration: courseDuration ? courseDuration : null,
            fromLabel: categoryData?.sandoz_academy_from_date_label
              ? categoryData?.sandoz_academy_from_date_label
              : null,
            toLabel: categoryData?.sandoz_academy_to_date_label
              ? categoryData?.sandoz_academy_to_date_label
              : null,
            credits: credits ?? null,
            enableCredits: flexCard?.attributes?.field_enable_disable_credits,
            fromDate: isPaywallProtected(flexCard?.attributes?.field_course_commencement_date).isPaywallProtectedFlag
              ? null
              : fromDate,
            toDate: isPaywallProtected(flexCard?.attributes?.field_course_expiration_date).isPaywallProtectedFlag
              ? null
              : toDate,
            cardImage: cardImage,
            isRatingsAvailable: categoryData?.disable_star_rating_on_catalouge_page === 0 ?? false,
            avgRating: flexCard?.attributes?.avgRating ?? 4,
            courseSuggestion: courseSuggestions,
            subTitle: flexCard?.attributes?.field_sac_sub_title?.value,
            courseCategory: courseCategorys || null,
            detailsPath: flexCard?.attributes?.path?.alias || "",
          }
        }),
      )
      : []
  return {
    flexibleCards: filterNullValues(flexibleCards),
    totalCount,
    itemsPerPage,
    fieldPaywallRoles,
  }
}

const getFlexibleCardsData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const courseCatalogueFilterData = await getCouesrCatalogueCategoryFilterData(serverData)
  const searchFacetData = await getSearchFacetsData(courseCatalogueFilterData, SEARCH_FACETS?.COURSECATALOGUE)
  const courseCatalogueAdvanceFilterData = {
    advanceFilterData: {
      filtersOptions: searchFacetData,
      filtersLabels: {
        searchLabel: courseCatalogueFilterData?.searchLabel ?? "",
        searchPlaceholder: courseCatalogueFilterData?.searchPlaceholder ?? "",
        SearchCTAText: courseCatalogueFilterData?.searchCTAText ?? "",
        unselectFiltersCTA: courseCatalogueFilterData?.clearLabel ?? "",
        resultsFoundLabel: courseCatalogueFilterData?.resultsFoundText ?? "",
        noResultsFoundLabel: courseCatalogueFilterData?.noResultsFoundText ?? "",
        noResultsFoundDescription: courseCatalogueFilterData?.noResultsFoundDescription ?? "",
        applyFiltersCTA: courseCatalogueFilterData?.applyAllfilters ?? "",
        unSelectItemsLabel: courseCatalogueFilterData?.unSelectItemsLabel ?? "",
        enableToggle: courseCatalogueFilterData?.course_catalogue_search_default_config ?? "",
        filterIconName: courseCatalogueFilterData?.filter_toggle_label ?? "",
      },
    },
    filterTitle: courseCatalogueFilterData?.course_catalogue_title ?? "",
    itemsPerPage: courseCatalogueFilterData?.itemsPerPage ?? 9,
  }

  return {
    courseCatalogueAdvanceFilterData,
  }
}

export default getFlexibleCardsData
