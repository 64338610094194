import { Column, Row } from "@atoms"
import NEWS_FILTER_CONSTANTS from "@helpers/constants/newsFilter"
import NUMBERS from "@helpers/constants/numbers"
import NewsFilterCards from "@organisms/newsFilterCards"
import { INewsCardInfo } from "@organisms/newsFilterSearch/_newsFilterSearch.interface"
import { groupBy } from "@utils/dataModifier"
import React from "react"
import { INewsSearchResultGroupedInterface } from "./_newsSearchResultGrouped.interface"

const NewsSearchResultGrouped = ({ newsList }: INewsSearchResultGroupedInterface) => {
  const getNewsCard = (news: INewsCardInfo, index: number | string, type: string) => {
    const newsItem = {
      newsFilterCardsContent: {
        newsDate: news.publishedDate,
        heading: news.title,
        newsTaxonomy: news.newsTaxonomy,
        newsCategory: news.newsCategory,
        cardType: type,
        link: news.readMore,
        newsDescription: news.body,
      },
      newsFilterCardsImage: {
        alt: news.alt,
        src: news.imageUrl,
        styledimage: news?.styledimage,
      },
    }
    return <NewsFilterCards {...newsItem} key={index} />
  }

  const groupedData = groupBy<INewsCardInfo>(newsList, "monthAndYear")
  const groupedDataKeys = Object.keys(groupedData)
  const groupedNewsList: any[] = []
  groupedDataKeys.forEach((key) => {
    const obj = {} as any
    obj.category = key
    obj.results = groupedData[key]
    groupedNewsList.push(obj)
  })

  return (
    <>
      {groupedNewsList.map((group: any, key: number) => (
        <div key={key} className="news-month-group">
          <h3 className="news-month-group__title">{group.category}</h3>
          <Row>
            {group?.results?.map((news: INewsCardInfo, index: number) => {
              if (index === NUMBERS.ZERO) {
                return (
                  <Column mobile={12} tablet={12} desktop={12} key={index}>
                    {getNewsCard(
                      news,
                      `${key}-${index}`,
                      NEWS_FILTER_CONSTANTS.NEWS_CARD_TYPE.BANNER,
                    )}
                  </Column>
                )
              }
              return (
                <Column mobile={12} tablet={6} desktop={4} key={index}>
                  {getNewsCard(news, `${key}-${index}`, NEWS_FILTER_CONSTANTS.NEWS_CARD_TYPE.SMALL)}
                </Column>
              )
            })}
          </Row>
        </div>
      ))}
    </>
  )
}

export default NewsSearchResultGrouped
