import React from "react"
import { Iframe } from "@atoms"
import { IEmbedMapProps } from "./_embedMap.interface"
import EMBED_MAP_CONSTANTS from "helpers/constants/embedMap"

const EmbedMap = ({ mapUrl, width, height }: IEmbedMapProps) => {

  return (
   <div className="embed-map">
     <Iframe 
      iframeURL={mapUrl} 
      width={width||EMBED_MAP_CONSTANTS.WIDTH} 
      height={height||EMBED_MAP_CONSTANTS.HEIGHT} 
      title={""} />
    </div>
  )
}

export default EmbedMap
