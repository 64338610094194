import * as actionTypes from "../ActionTypes"

export const addUpdateUserActionFilter = (userActionFilters: any) => {
    const action: UserActionFilterAction = {
        type: actionTypes.ADD_UPDATE_USER_ACTION_FILTERS,
        userActionFilters,
    }
    return performRequest(action)
}
export const updateUserActionPagination = (currentPage: number) => {
    const action: UserActionFilterAction = {
        type: actionTypes.UPDATE_USER_ACTION_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updateUserActionSearchText = (searchText: string) => {
    const action: UserActionFilterAction = {
        type: actionTypes.UPDATE_USER_ACTION_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllUserActionFilters = (userActionFilters: any, currentPage: number, searchText: string, sort?: any) => {
    const action: UserActionFilterAction = {
        type: actionTypes.UPDATE_ALL_USER_ACTION_FILTERS,
        userActionFilters,
        currentPage,
        searchText,
        sort,
    }
    return performRequest(action)
}

export const updateUserActionSort = (sort: any) => {
    const action: UserActionFilterAction = {
        type: actionTypes.UPDATE_USER_ACTION_SORT,
        sort,
    }
    return performRequest(action)
}

const performRequest = (action: UserActionFilterAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}