import * as actionTypes from "../ActionTypes"

export const addUpdatePOListFilter = (poListFilters: any) => {
    const action: POListFilterAction = {
        type: actionTypes.ADD_UPDATE_PO_LIST_FILTERS,
        poListFilters,
    }
    return performRequest(action)
}
export const updatePOListPagination = (currentPage: number) => {
    const action: POListFilterAction = {
        type: actionTypes.UPDATE_PO_LIST_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updatePOListSearchText = (searchText: string) => {
    const action: POListFilterAction = {
        type: actionTypes.UPDATE_PO_LIST_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllPOListFilters = (poListFilters: any, currentPage: number, searchText: string, sort?: any) => {
    const action: POListFilterAction = {
        type: actionTypes.UPDATE_ALL_PO_LIST_FILTERS,
        poListFilters,
        currentPage,
        searchText,
        sort,
    }
    return performRequest(action)
}

export const updatePOListSort = (sort: any) => {
    const action: POListFilterAction = {
        type: actionTypes.UPDATE_PO_LIST_SORT,
        sort,
    }
    return performRequest(action)
}

const performRequest = (action: POListFilterAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}
