import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import ApplicationContext from "@utils/application-context/applicationContext"
import { Bookmark, Button } from "@atoms"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import { IBookmarkWithButtonProps } from "./_bookmarkWithButton.interface"

type BookmarkCallback = {
  id: number | undefined
  bookmarkCallback: (state: any) => void
}

type Props = BookmarkCallback & IBookmarkWithButtonProps

const BookmarkWithButton = (props: Props) => {
  const { profile, isLoggedIn }: any = useSelector((state: UserState) => state?.user)
  const { applicationConfiguration } = useContext(ApplicationContext)
  
  const bookmarks: Array<any> = (profile?.response?.bookmark as Array<any>) ?? []

  const selectedBookmarks = bookmarks.filter((b: any) => parseInt(b.node_id) === Number(props.id))
  const isSelected = selectedBookmarks.length > 0 // props.bookmarkStatus

  const [bookmarkState, setBookmarkState] = useState(isSelected)
  const startingText = isSelected ? props.removeText : props.addText

  const bookmarkHandler = () => {
    const { title } = document
    if (bookmarkState) {
      setBookmarkState(false)
      const trackBookmarkAddClick = {
        article_title: title,
        bookmark_action: "remove",
      }
      dataLayerPush(EVENTS.BOOKMARK_ITEM, trackBookmarkAddClick)
      // passing bookmark state to caller component
      props.bookmarkCallback({
        type: "bookmark",
        isChecked: true,
      })
    } else {
      setBookmarkState(true)
      const trackBookmarkRemoveClick = {
        article_title: title,
        bookmark_action: "add",
      }
      dataLayerPush(EVENTS.BOOKMARK_ITEM, trackBookmarkRemoveClick)

      // passing bookmark state to caller component
      props.bookmarkCallback({
        type: "bookmark",
        isChecked: false,
      })
    }
  }

  if (
    (applicationConfiguration &&
      Object.keys(applicationConfiguration)?.length &&
      !applicationConfiguration?.isBookmarkEnabled) ||
    (!isLoggedIn &&
      applicationConfiguration?.isBookmarkEnabled &&
      !applicationConfiguration?.siteConfig?.bookmark_anonymous)
  )
    return <></>
  return (
    <>
      {props.isButton ? (
        <Button
          tabindex={0}
          icon={<Bookmark bookmarkStatus={isSelected} />}
          isSecondary={true}
          onClick={bookmarkHandler}>
          {startingText}
        </Button>
      ) : (
        <div className="bookmark-icon" onClick={bookmarkHandler}>
          <Bookmark bookmarkStatus={isSelected} />
        </div>
      )}
    </>
  )
}

export default BookmarkWithButton
