import { ITabItemProps } from "./_tab-item.interface"

const TabItem = (props: ITabItemProps) => {
  const getTabItemClasses = () => {
    const classes = []
    classes.push("nav-item")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }
  const getActiveClasses = () => {
    const classes = []
    classes.push("nav-link")
    if (props.active) classes.push("active")
    if (props.greyColor) classes.push("grey-color")
    if (props.tabDesign === "wide") classes.push("wide")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  const getToggleTabClasses = () => {
    const classes= []
    classes.push("toggle-tab")
    if (props.active) classes.push("active")
    return classes.join(" ")
  }

  return (
    <li
      className={getTabItemClasses()}
      id={props?.referrerId}
      role="presentation"
      onClick={props.onClick}
      onKeyDown={props?.onKeyDown}>
      <span
        className={props?.isToggleTab ? getToggleTabClasses() : getActiveClasses()}
        id={`${props.itemID}-tab`}
        data-bs-toggle={props.tabDesign}
        role="tab"
        aria-selected={props.active ? "true" : "false"}
        tabIndex={props.active ? 0 : -1}
      >
        <h5 className="tabhead-item">{props.children}</h5>
      </span>
    </li>
  )
}

export default TabItem
