import { Anchor, RichText, Taxonomy } from "@atoms"
import NEWS_FILTER_CONSTANTS from "@helpers/constants/newsFilter"
import { getApiDomainAndLang } from "@utils/baseApi"
import moment from "moment"
import { INewsFilterCardsContentProps } from "./_newsFilterCardsContent.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"

const NewsFilterCardsContent = ({
  heading,
  newsDescription,
  newsDate,
  newsTaxonomy,
  newsCategory,
  link,
  cardType,
}: INewsFilterCardsContentProps) => {
  const isSmallCard = cardType === NEWS_FILTER_CONSTANTS.NEWS_CARD_TYPE.SMALL
  const getClasses = (nameOfClass: string) =>
    isSmallCard
      ? `${nameOfClass} ${nameOfClass}-${NEWS_FILTER_CONSTANTS.NEWS_CARD_TYPE.SMALL}`
      : nameOfClass
  const { lang } = getApiDomainAndLang()
  const { applicationConfiguration } = useContext(ApplicationContext)

  return (
    <div className="news-filter-content">
      <span className={getClasses("news-filter-content-date-section")}>
        {newsCategory && <span>{`${newsCategory} / `}</span>}
        {newsDate && <span>{moment(newsDate).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}</span>}
      </span>
      {newsTaxonomy && <Taxonomy taxonomies={[{ taxonomyLabel: newsTaxonomy }]} />}
      {heading && (
        <Anchor href={link} tabindex={0} noUnderline={true}>
          <h4 className={getClasses("news-filter-content-heading bold")}>{heading}</h4>
        </Anchor>
      )}
      {!isSmallCard && newsDescription && (
        <RichText content={newsDescription} className="news-filter-content-description" />
      )}
    </div>
  )
}

export default NewsFilterCardsContent
