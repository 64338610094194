import { getApi } from "@utils/baseApi"

const getAcademyModulesData = async (url: string, ...args: any) => {
  const rawData = await getApi(url.replace(/\?.*/, ""), false)
  const cardData = rawData?.map((data: any) => {
    return {
      moduleTitle: data?.field_module_heading ?? "",
      title: data?.title_1 ?? "",
      desc: data?.body ?? "",
      desktopColumns: 3,
      tabletColumns: 6,
      id: data?.nid ?? null,
      credits: data?.field_training_time ? [{ taxonomyLabel: data?.field_training_time }] : null,
      enableCredits: false,
      isRatingsAvailable: true,
      avgRating: 5, // to be updated in later sprints
      detailsPath: "",
      tag: data?.field_training_time ?? "",
      cardType: "modules",
      author: data?.field_key_opinion_leaders ?? "",
      moduleLinkTitle: data?.sandoz_academy_show_lessions_cta_text ?? "",
      ctaLabel: data?.sandoz_academy_go_to_course_cta_text ?? "",
      topic_label: data?.sandoz_academy_heading_label_cta_text ?? "",
      isCourseModule: true
    }
  })

  return {
    cardsArray: cardData,
  }
}

export default getAcademyModulesData
