import * as actionTypes from "../ActionTypes"

export const addUpdatePODetailFilter = (poDetailFilters: any) => {
    const action: PODetailFilterAction = {
        type: actionTypes.ADD_UPDATE_PO_DETAIL_FILTERS,
        poDetailFilters,
    }
    return performRequest(action)
}
export const updatePODetailPagination = (currentPage: number) => {
    const action: PODetailFilterAction = {
        type: actionTypes.UPDATE_PO_DETAIL_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updatePODetailSearchText = (searchText: string) => {
    const action: PODetailFilterAction = {
        type: actionTypes.UPDATE_PO_DETAIl_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllPODetailFilters = (poDetailFilters: any, currentPage: number, searchText: string, sort?: any) => {
    const action: PODetailFilterAction = {
        type: actionTypes.UPDATE_ALL_PO_DETAIL_FILTERS,
        poDetailFilters,
        currentPage,
        searchText,
        sort,
    }
    return performRequest(action)
}

export const updatePODetailSort = (sort: any) => {
    const action: PODetailFilterAction = {
        type: actionTypes.UPDATE_PO_DETAIL_SORT,
        sort,
    }
    return performRequest(action)
}

const performRequest = (action: PODetailFilterAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}