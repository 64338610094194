import StarsRating from "@molecules/starsRating"
import IModuleInformation from "./_modulesInformation.interface"
import { useState } from "react"
import { AnchorButton, RichText } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"

const ModulesInformation = ({
  moduleHeading,
  title,
  rating,
  avgRating,
  trainingTime,
  desc,
  keyOpinion,
  courseStatus,
  ctaLink,
  ctaLabel,
}: IModuleInformation) => {
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const isMobile = useMediaQuery("(max-width: 767px)")

  return (
    <div className="modulesInfo">
      <div className="modulesInfo-top">
        {trainingTime ? <p className="modulesInfo-top-time">{trainingTime}</p> : <></>}
      </div>
      {moduleHeading ? <h1 className="modulesInfo-heading bold">{moduleHeading}</h1> : <></>}
      <div className="moduleInfo-star">
        <StarsRating
          isDisabled={false}
          localStorageName={rating?.localStorageName}
          disableLayoutAfterRate={false}
          avgRating={avgRating}
          setShowLoginPopUp={() => setShowLoginPopUp(true)}
          className=""
          title=""
        />
      </div>
      {title ? <h5 className="modulesInfo-title bold">{title}</h5> : <></>}
      {desc ? <RichText className="modulesInfo-desc bold" content={desc} /> : <></>}
      {keyOpinion ? <RichText className="modulesInfo-leader bold" content={keyOpinion} /> : <></>}
      {courseStatus ? (
        <div className="modulesInfo-status">
          <p className="modulesInfo-status-courseStatus">{courseStatus}</p>
        </div>
      ) : (
        <></>
      )}
      {ctaLabel && !isMobile ? (
        <div className="modulesInfo-cta">
          <AnchorButton href={ctaLink ?? "/"} isExternal={false} tabindex={0} isSecondary>
            {ctaLabel}
          </AnchorButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ModulesInformation
