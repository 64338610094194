import getNestedObject from "@utils/nestedObjects"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import { Icon } from "@atoms"


export const getFilterLabelData = (siteConfig: any) => {
  if (siteConfig) {
    const categoryLabelData = {
      searchLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.search_text_label"),
      searchPlaceholder: getNestedObject(siteConfig, "cc_po_listing.search_label.search_text_placeholder"),
      searchCTALabel: getNestedObject(siteConfig, "cc_po_listing.search_label.search_cta"),
      dateRangeLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.date_range"),
      dateRangePlaceholder: getNestedObject(siteConfig, "cc_po_listing.search_label.date_range_min_placeholder"),
      dateRangeToLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.date_range_to_label"),
      dateRangeToPlaceholder: getNestedObject(siteConfig, "cc_po_listing.search_label.date_range_max_placeholder"),
      poStatusLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.po_status"),
      poStatusOptions: getNestedObject(siteConfig, "cc_po_listing.search_label.po_status_options"),
      clearLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.clear_label"),
      tableColumns: getNestedObject(siteConfig, "cc_po_listing.header_label"),
      resultsLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.search_results_text"),
      noResultsLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.no_search_results_text"),
      noResultsDesc: "",
      sortByLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.sortby_label"),
      applyFiltersCTALabel: getNestedObject(siteConfig, "cc_po_listing.search_label.apply_filter_cta"),
      showFiltersCTALabel: getNestedObject(siteConfig, "cc_po_listing.search_label.show_filter_cta"),
      unSelectItemsLabel: getNestedObject(siteConfig, "cc_po_listing.search_label.unselect_items_label"),
      paginationCount: getNestedObject(siteConfig, "cc_po_listing.items_per_page"),
      dropdownPlaceHolder: getNestedObject(siteConfig, "cc_po_listing.search_label.po_status_placeholder"),
      filter_toggle_label: getNestedObject(siteConfig, "cc_po_listing.search_label.filter_toggle_label"),
      openOrderCTALabel: getNestedObject(siteConfig, "cc_po_listing.open_orders_cta") ?? "",
      openOrderCTAText: getNestedObject(siteConfig, "cc_po_listing.open_orders_count_text") ?? "",
      openOrderCTALink: getNestedObject(siteConfig, "cc_po_listing.po_listing_open_orders_link") ?? "",
      searchTitle: getNestedObject(siteConfig, "cc_po_listing.search_label.search_title") ?? "",
      searchDesc: getNestedObject(siteConfig, "cc_po_listing.search_label.search_description") ?? "",
      openOrderTitle: getNestedObject(siteConfig, "cc_po_listing.open_orders_title") ?? ""
    }
    return categoryLabelData

}

  return {
    searchLabel: "",
    searchPlaceholder: "",
    searchCTALabel: "",
    divisionLabel: "",
    dateRangeLabel: "",
    dateRangePlaceholder: "",
    dateRangeToLabel: "",
    dateRangeToPlaceholder: "",
    poStatusLabel: "",
    poStatusOptions: "",
    clearLabel: "",
    tableColumns: "",
    resultsLabel: "",
    noResultsLabel: "",
    noResultsDesc: "",
    sortByLabel: "",
    applyFiltersCTALabel: "",
    showFiltersCTALabel: "",
    unSelectItemsLabel: "",
    paginationCount: 0,
    dropdownPlaceHolder: "",
    filter_toggle_label: "",
    openOrderCTALabel: "",
    openOrderCTAText: "",
    openOrderCTALink: "/",
    searchTitle: "",
    searchDesc: "",
    openOrderTitle: ""
  }
}

export const fetchTableData = (response: any) => {
  const tableData: searchFilterType[] = response?.data?.map((item:any) => {
     return {
        customerPo: {
        title: "",
        desc: item.po_number ?? "",
        type: "richtext",
        link: ""
        },
        poStatus: {
          title: "",
          desc:  item.po_status ?? "",
          type: "richtext",
          link: ""
        },
        dateOrdered:  {
          title: "",
          desc:  item.date_ordered ?? "",
          type: "text",
          link: ""
        },
        dateShipped:  {
          title: "",
          desc: item.date_shipped ?? "",
          type: "text",
          link: ""
        },
        trackingNumber:  {
          title: "",
          desc:  item?.tracking_no ?? "",
          type: "richtext",
          link: ""
        },
        packingSlip: {
          title: item.packing_slip ?? "",
          desc: "",
          type: "download",
          link: item.packing_slip ?? "",
          linkIcon: <Icon iconName="downloadPdfIcon" />,
        },
        invoice:  {
          title: item.invoice_number ?? "",
          desc: "",
          type: "download",
          link: item?.invoice_number ?? "",
          linkIcon: <Icon iconName="downloadPdfIcon" />,
        },
      }
  })

  return tableData
}


export const fetchOpenOrderTableData = (response: any) => {
  const tableData: searchFilterType[] = response?.data?.map((item:any) => {
     return {
        customerPo: {
        title: "",
        desc: item.po_number ?? "",
        type: "richtext",
        link: ""
        },
        poStatus: {
          title: "",
          desc:  item.po_status ?? "",
          type: "richtext",
          link: ""
        },
        dateOrdered:  {
          title: "",
          desc:  item.date_ordered ?? "",
          type: "text",
          link: ""
        },
        dateShipped:  {
          title: "",
          desc: item.date_shipped ?? "",
          type: "text",
          link: ""
        },
        trackingNumber:  {
          title: "",
          desc:  item?.tracking_no ?? "",
          type: "richtext",
          link: ""
        },
        packingSlip: {
          title: item.packing_slip ?? "",
          desc: "",
          type: "download",
          link: item.packing_slip ?? "",
          linkIcon: <Icon iconName="downloadPdfIcon" />,
        },
        invoice:  {
          title: item.invoice_number ?? "",
          desc: "",
          type: "download",
          link: item?.invoice_number ?? "",
          linkIcon: <Icon iconName="downloadPdfIcon" />,
        },
      }
  })

  return tableData
}