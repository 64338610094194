import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { Column, Row } from "@atoms"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Dispatch } from "redux"
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import { useMediaQuery } from "@helpers/hooks"
import AdvanceSearchFilterWithAccordionEnhanced from "@organisms/advanceSearchFilterWithAccordionEnhanced"
import {
  addProductFilter,
  setCurrentPage,
  setOrderVal,
  setSearchTextVal,
} from "store/actions/ProductActionCreators"
import { getProductBlockData } from "@helpers/dataFunctions"
import { Pagination, TitleDescription } from "@molecules"
import ProductCardListViewContent from "@molecules/productCardListViewContent"
import debounce from "lodash.debounce"
import Loader from "@atoms/loader"
import ViewAndSortOption from "@molecules/viewAndSortOptions"
import { IProductListProps } from "./_productlist.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import NUMBERS from "@helpers/constants/numbers"
import {
  updatePaywallContent,
  updateProductCatalogTitle,
  updateProductFilter,
} from "store/actions/CommonActionCreators"

interface DispatchProps {
  updatePaywallContent: (isPaywall: boolean) => void
}
interface StateProps {
  paywallContent: boolean
}
type Props = IProductListProps & DispatchProps & StateProps
const ProductListV2 = ({
  label,
  title,
  hideViewCTA,
  searchLabels,
  filterLabels,
  resultLabels,
  url,
  buttonLabel,
  showInCard,
  type,
  updatePaywallContent,
}: Props) => {
  const filterURL =
    type === "pim_product_listing"
      ? "/restapi/pim/solarapi/v1/csv_product_filter/default"
      : "/restapi/solarapi/v1/csv_product_filter/default"
  const { applicationConfiguration } = useContext(ApplicationContext)
  const itemsPerPage =
    parseInt(applicationConfiguration?.siteConfig?.items_per_page, 10) || NUMBERS.NINE
  const { GRID_CONFIGURATOR_TEXT, GRID_VIEW, LIST_VIEW } = mediaLibraryConstants
  const defaultView = label?.default_view === GRID_CONFIGURATOR_TEXT ? GRID_VIEW : LIST_VIEW
  const [searchText, setSearchText] = useState<string>("")
  const [plpCatTitle, setPlpCatTitle] = useState<string>(title)
  const [productData, setProductData] = useState([])
  const [searchFlag, setSearchFlag] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mobileFilterData, setMobileFilterData] = useState([])
  const [productFilterRes, setProductFilterRes] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const scollToRef = useRef<null | HTMLDivElement>(null)
  const [additionalFilters, setAdditionalFilters] = useState(new Map())
  const [isPaywall3Avail, setIsPaywall3Avail] = useState(false)
  const [pimCTADisplay, setPimCTADisplay] = useState({
    listing_page_leaflet_ctas_flag: 0,
    leaflet_cta_accordion_flag: 0,
    leaflet_cta_accordion_label_desktop: "",
    leaflet_cta_accordion_label_mobile: "",
  })
  const { protocol, host, pathname } = window.location
  const user: any = useSelector((state: any) => state?.user)
  const productFilter: any = useSelector((state: any) => state?.product.productFilter, shallowEqual)
  const searchTextVal: any = useSelector((state: any) => state?.product?.searchText, shallowEqual)
  const order: any = useSelector((state: any) => state?.product?.order, shallowEqual)
  const currentPage: any = useSelector((state: any) => state?.product?.currentPage, shallowEqual)
  const productCatalogTitle: any = useSelector(
    (state: any) => state?.common?.productCatalogTitle,
    shallowEqual,
  )

  const dispatch: Dispatch<any> = useDispatch()
  const updateProductListingFilter: any = useCallback(
    (value: any) => dispatch(addProductFilter(value)),
    [dispatch],
  )
  const updateProductFilterState: any = useCallback(
    (value: any) => dispatch(updateProductFilter(value)),
    [dispatch],
  )
  const updateSearchTextVal: any = useCallback(
    (value: any) => dispatch(setSearchTextVal(value)),
    [dispatch],
  )
  const updateOrder: any = useCallback((value: any) => dispatch(setOrderVal(value)), [dispatch])
  const updateCurrentPage: any = useCallback(
    (value: any) => dispatch(setCurrentPage(value)),
    [dispatch],
  )

  const updateProductCatalog: any = useCallback(
    (value: any) => dispatch(updateProductCatalogTitle(value)),
    [dispatch],
  )
  useEffect(() => {
    if (isPaywall3Avail && !user.isLoggedIn) {
      updatePaywallContent(true)
    }
  }, [isPaywall3Avail, user])
  const [productView, setproductView] = useState(defaultView)
  const isMobile = useMediaQuery("(max-width: 767px)")

  const viewType = isMobile ? "grid" : productView

  const executeScroll = () =>
    scollToRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })

  const productViewLabels = {
    list_view_text: label?.list_view_text,
    grid_view_text: label?.grid_view_text,
  }
  const router = useRouter()

  const { data: filterList, isLoading: isFilterDataLoading } = useSWRRequest(
    filterURL,
    async () => {
      try {
        const filterOptionData = await getProductBlockData(filterURL)
        if (filterOptionData) {
          filterOptionData.sort((a: any, b: any) => {
            return parseInt(a.order, 10) - parseInt(b.order, 10)
          })
          return filterOptionData?.map((filter: any) => {
            return {
              label: filter?.label ?? "",
              vid: filter?.vid ?? "",
              taxonomies:
                filter?.taxonomies?.map((op: any) => ({
                  id: op.tid,
                  label: op.label,
                  isSelected: false,
                })) ?? [],
            }
          })
        }
        return filterOptionData
      } catch (error) {
        console.log(error)
      }
    },
  )

  const getProdCatFilter = () => {
    const urlCatFilter = router?.query?.prod_cat_filter || ""
    // @ts-ignore
    const catArray = urlCatFilter ? urlCatFilter.split(",") : []
    const ids = [] as any
    catArray?.forEach((item: any) => {
      const categoryName = router?.query[item]
      // @ts-ignore
      ids.push(categoryName?.replace(/\[/g, "").replace(/]/g, "").split(","))
    })
    return {
      catIds: ids.flat(),
      catArray,
    }
  }

  const getUpdatedCatFilter = (catIds: any) => {
    return filterList?.map((filter: any) => {
      return {
        label: filter?.label ?? "",
        vid: filter?.vid ?? "",
        taxonomies:
          filter?.taxonomies?.map((op: any) => ({
            id: op.id,
            label: op.label,
            isSelected: catIds.includes(op.id),
          })) ?? [],
      }
    })
  }

  const getUpdatedCatFilterMobile = (updatedFilter: any, catArray: any) => {
    const obj = {} as any
    updatedFilter.forEach((filter: any) => {
      if (catArray.includes(filter.vid)) {
        const arr = [] as any
        filter.taxonomies.forEach((tax: any) => {
          if (tax.isSelected) {
            arr.push(tax.id)
          }
        })
        obj[filter.label] = arr
      }
    })
    const mapObj = new Map()
    const keys = Object.keys(obj)
    keys.forEach((key) => {
      mapObj.set(key, obj[key])
    })
    return mapObj
  }

  const findTaxonomyNameById = (tid: string) => {
    let foundTaxName = null
    filterList?.forEach((item: any) => {
      const taxonomy = item?.taxonomies?.find((t: any) => t.id === tid)
      if (taxonomy) {
        foundTaxName = taxonomy?.label
      }
    })
    return foundTaxName
  }

  useEffect(() => {
    if (router?.query && filterList && !isFilterDataLoading) {
      const { catIds, catArray } = getProdCatFilter()
      const searchVal = router?.query?.text_search ?? ""
      const orderVal = router?.query?.order ?? "ASC"
      const pageNo = router?.query?.page ? Number(router?.query?.page) : 0
      const updatedFilter = getUpdatedCatFilter(catIds)
      const mobileFilterSelection = getUpdatedCatFilterMobile(updatedFilter, catArray)
      if (productCatalogTitle !== "") {
        const urlCatFilter = router?.query?.prod_cat_filter || ""
        // @ts-ignore
        const catArray = urlCatFilter ? urlCatFilter.split(",") : []
        const catQueryId = getProdCatFilter()
        const catalogTitle = findTaxonomyNameById(catQueryId?.catIds?.[0])
        if (catalogTitle) {
          setPlpCatTitle(catalogTitle)
        }
        const selectedIndex = filterList?.findIndex((list: any) => list?.vid === catArray[0])
        const catIndex = selectedIndex === -1 ? 0 : selectedIndex
        updateProductFilterState(catIndex)
      }
      setAdditionalFilters(mobileFilterSelection)
      updateProductListingFilter(updatedFilter)
      updateSearchTextVal(searchVal)
      updateOrder(orderVal)
      updateCurrentPage(pageNo)
    }
  }, [router?.query, filterList])

  useEffect(() => {
    if (filterList && !isFilterDataLoading) {
      getMobileFilterData()
    }
  }, [additionalFilters])

  const getCategorySelection = (prodFilter: any) => {
    const categoryVids = [] as any
    prodFilter.forEach((item: any) => {
      item.taxonomies.forEach((tax: any) => {
        if (tax.isSelected) {
          const obj = {} as any
          obj.id = tax.id
          obj.vid = item.vid
          categoryVids.push(obj)
        }
      })
    })
    const productCatVids = Array.from(
      new Set(
        categoryVids.map((el: any) => {
          return el.vid
        }),
      ),
    )
    const categoryIdObj = {} as any
    productCatVids.forEach((cat: any) => {
      categoryIdObj[cat] = [] as any
      const category = categoryVids.filter((el: any) => el.vid == cat)
      category.forEach((item: any) => {
        categoryIdObj[cat].push(item.id)
      })
    })

    return productCatVids.length > 0
      ? `prod_cat_filter=${productCatVids.join(",")}&${JSON.stringify(categoryIdObj)
          .replace(/],/g, "]&")
          .replace(/["{}]/g, "")
          .replace(/:/g, "=")}`
      : "prod_cat_filter="
  }

  const formatAllCardsData = (data: any) => {
    const allCardsData = data?.map((rawData: any) => {
      let allSpecifications =
        type === "pim_product_listing"
          ? rawData?.showInCard ?? []
          : [
              ...(rawData?.prodInfoTaxonomies ?? []),
              ...(rawData?.stInfoTaxonomies ?? []),
              ...(rawData?.byInfoTaxonomies ?? []),
              ...(rawData?.wdInfoTaxonomies ?? []),
            ]
      const imgSrcFlag = isPaywallProtected(rawData?.prodImages)
      allSpecifications = allSpecifications?.map((spec: any) => {
        return {
          description: isPaywallProtected(spec?.description).isPaywallProtectedFlag
            ? ""
            : spec?.description ?? "",
          tid: spec?.tid,
          title: isPaywallProtected(spec?.title).isPaywallProtectedFlag ? "" : spec?.title ?? "",
          vid: spec.vid,
        }
      })
      if (type !== "pim_product_listing") {
        allSpecifications = allSpecifications?.filter((as: any) => showInCard.includes(as.vid))
      }
      const paywallRoles = getAllRoles(rawData)
      if (paywallRoles) {
        updatePaywallContent(true)
      }
      const transformTaxonomies = (pdTagsTaxonomies: any) => {
        return pdTagsTaxonomies?.map((item: any) => {
          return {
            taxonomyLabel: item?.description,
          }
        })
      }
      return {
        id: rawData?.drupal_internal__nid ?? null,
        taxonomies: isPaywallProtected(transformTaxonomies(rawData?.pdTagsTaxonomies))
          .isPaywallProtectedFlag
          ? ""
          : transformTaxonomies(rawData?.pdTagsTaxonomies) ?? [],
        heading: isPaywallProtected(rawData?.title).isPaywallProtectedFlag
          ? ""
          : rawData?.title ?? "",
        description: isPaywallProtected(rawData?.description).isPaywallProtectedFlag
          ? ""
          : rawData?.description ?? "",
        prodInfoTaxonomies: rawData?.prodInfoTaxonomies?.map((t: any) => t.tid) ?? [],
        packInfoTaxonomies: rawData?.packInfoTaxonomies?.map((t: any) => t.tid) ?? [],
        category: isPaywallProtected(rawData?.category).isPaywallProtectedFlag
          ? ""
          : rawData?.category ?? "",
        productListViewImage: {
          src: imgSrcFlag?.isPaywallProtectedFlag
            ? getPaywalledImgData(imgSrcFlag?.errorObj).imageUrl
            : rawData?.prodImages?.[0]?.src ?? "",
          alt: imgSrcFlag?.isPaywallProtectedFlag ? "" : rawData?.prodImages?.[0]?.alt ?? "",
          styledimage: imgSrcFlag?.isPaywallProtectedFlag
            ? null
            : rawData?.prodImages?.[0]?.image_style_uri ?? null,
        },
        productCardImage: {
          src: imgSrcFlag?.isPaywallProtectedFlag
            ? getPaywalledImgData(imgSrcFlag?.errorObj).imageUrl
            : rawData?.prodImages?.[0]?.src ?? "",
          alt: imgSrcFlag?.isPaywallProtectedFlag ? "" : rawData?.prodImages?.[0]?.alt ?? "",
          styledimage: imgSrcFlag?.isPaywallProtectedFlag
            ? null
            : rawData?.prodImages?.[0]?.image_style_uri ?? null,
        },
        isLinkExternal: false,
        buttonLabel,
        href: rawData?.path ?? "",
        hrefFamily: rawData?.productfamilypath ?? "",
        productLinkText: rawData?.productlinkCTAtext ?? "",
        familyLinkText: rawData?.productfamilyCTAtext ?? "",
        specifications: allSpecifications ?? [],
        bookmark: isPaywallProtected(rawData?.title).isPaywallProtectedFlag
          ? false
          : rawData?.attributes?.status,
          pimCTADetails: isPaywallProtected(rawData?.title).isPaywallProtectedFlag
          ? false
          : rawData?.san_ir_producttopdf,  
        basisCTALabel: rawData?.basistext_anchored_link?.label ?? "",
        basisTextPath: rawData?.basistext_anchored_link?.path ?? "",
        attachmentLabel: rawData?.attachment_section_link?.label ?? "",
        attachmentPath: rawData?.attachment_section_link?.path ?? "",
      }
    })
    setProductData(allCardsData)
  }

  const getProductData = async (
    prodFilter: any,
    searchTerm: string,
    orderBy: string,
    pageNo: number,
  ) => {
    setLoading(true)
    const categoryQuery = getCategorySelection(prodFilter)
    // Replace special characters with their encoding values
    const productUrl = `${url}?${categoryQuery}&order=${orderBy}&page=${pageNo}&text_search=${encodeURIComponent(
      searchTerm,
    )}`
    const filterOptionData = await getProductBlockData(productUrl)
    const paywallRoles = getAllRoles(filterOptionData)
    if (paywallRoles) {
      setIsPaywall3Avail(true)
    }
    formatAllCardsData(filterOptionData?.product_data)
    setTotalCount(filterOptionData?.total_product_count)
    setProductFilterRes(filterOptionData?.filter_data)
    setPimCTADisplay({
      listing_page_leaflet_ctas_flag: filterOptionData?.listing_page_leaflet_ctas_flag,
      leaflet_cta_accordion_flag: filterOptionData?.leaflet_cta_accordion_flag,
      leaflet_cta_accordion_label_desktop: filterOptionData?.leaflet_cta_accordion_label_desktop,
      leaflet_cta_accordion_label_mobile: filterOptionData?.leaflet_cta_accordion_label_mobile,
    })
    setLoading(false)
  }

  const debounceSearch = useCallback(
    debounce(
      (productFilter: any, searchTextVal: string, order: string, currentPage: number) =>
        getProductData(productFilter, searchTextVal, order, currentPage),
      500,
    ),
    [],
  )

  useEffect(() => {
    if (productCatalogTitle === "") {
      setPlpCatTitle(title)
    }
  }, [productCatalogTitle])

  useEffect(() => {
    if (searchTextVal || order) {
      setLoading(true)
      if (productFilter?.length > 0) {
        debounceSearch(productFilter, searchTextVal, order, currentPage)
      }
    }
  }, [productFilter, searchTextVal, order, currentPage])

  const handleTextSearch = (searchTerm: string) => {
    if (searchTerm && searchTerm?.length > 2) {
      if (searchTerm !== "") {
        updateProductCatalog("")
      }
      updateSearchTextVal(searchTerm)
      setSearchFlag(!!searchTerm)
    }
  }

  const applyAdditionalFilters = (searchTerm: string) => {
    const filterArray = Array.from(additionalFilters, ([name, value]) => ({ name, value })).reduce(
      (acc: any, it: any) => [...acc, ...it.value],
      [],
    )
    const updatedFilter = productFilter?.map((filter: any) => {
      return {
        label: filter?.label ?? "",
        vid: filter?.vid ?? "",
        taxonomies:
          filter?.taxonomies?.map((op: any) => ({
            id: op.id,
            label: op.label,
            isSelected: filterArray.includes(op.id),
          })) ?? [],
      }
    })
    updateSearchTextVal(searchTerm)
    updateProductCatalog("")
    updateProductListingFilter(updatedFilter)
    setSearchFlag(true)
  }

  const handleAdditionalFilters = (label: string, value: any) => {
    setAdditionalFilters(
      new Map(
        additionalFilters.set(
          label,
          value.map((v: any) => v.value || v.id),
        ),
      ),
    )
  }

  const handleCategorySelection = (filterList: any) => {
    updateProductCatalog("")
    updateProductListingFilter(filterList)
    setSearchFlag(true)
  }

  const handleClearCategorySelection = (label: string) => {
    const updatedFilter = productFilter?.map((filter: any) => {
      if (filter?.vid === label) {
        return {
          label: filter?.label ?? "",
          vid: filter?.vid ?? "",
          taxonomies:
            filter?.taxonomies?.map((op: any) => ({
              id: op.id,
              label: op.label,
              isSelected: false,
            })) ?? [],
        }
      }
      return filter
    })
    updateProductCatalog("")
    updateProductListingFilter(updatedFilter)
    setTimeout(executeScroll, 200)
    setSearchFlag(true)
  }

  const getMobileFilterData = () => {
    const taxonomyIds = getVisibleTaxonomy()
    const updatedData = productFilter?.map((filter: any) => {
      return {
        label: filter?.label ?? "",
        vid: filter?.vid ?? "",
        taxonomies: filter?.taxonomies.filter((tax: any) => taxonomyIds?.includes(tax.id)),
      }
    })

    const updatedFilter = updatedData?.map((filter: any) => {
      let ids = additionalFilters.get(filter?.label ?? "") ?? []
      ids = ids?.filter((id: string) => taxonomyIds?.includes(id))
      return {
        label: filter?.label ?? "",
        vid: filter?.vid ?? "",
        title:
          ids?.length > 0
            ? `(${ids?.length} of ${filter?.taxonomies?.length})`
            : `(${filter?.taxonomies?.length})`,
        isSelected: ids?.length > 0,
        taxonomies:
          filter?.taxonomies?.map((op: any) => ({
            id: op.id,
            label: op.label,
            value: op.id,
          })) ?? [],
      }
    })
    setMobileFilterData(updatedFilter)
  }

  const handleAllFilterClear = () => {
    const updatedFilter = productFilter?.map((filter: any) => {
      return {
        label: filter?.label ?? "",
        vid: filter?.vid ?? "",
        taxonomies:
          filter?.taxonomies?.map((op: any) => ({
            id: op.id,
            label: op.label,
            isSelected: false,
          })) ?? [],
      }
    })
    setAdditionalFilters(new Map())
    getMobileFilterData()
    updateSearchTextVal("")
    updateProductListingFilter(updatedFilter)
    setSearchFlag(false)
  }

  const updateAdditionalFilters = (additionalFilters: any) => {
    setAdditionalFilters(additionalFilters)
    getMobileFilterData()
  }

  const getVisibleTaxonomy = () => {
    const taxonomiesId = [] as any
    productFilterRes?.forEach((item: any) => {
      item?.taxonomies?.forEach((tax: any) => {
        taxonomiesId?.push(tax.tid)
      })
    })
    return taxonomiesId
  }

  const advanceFilter = {
    handleSearch: (searchTerm: string, e?: any) => {
      e?.preventDefault()
      handleTextSearch(searchTerm)
    },
    handleAdditionalFilters,
    searchFieldData: {
      buttonText: filterLabels?.searchCTAMobile,
      isCleared: true,
    },
    clearFilterText: filterLabels?.clearText,
    labelItemsSelected: "selected items",
    applyFiltertext: filterLabels?.applyText,
    filterData: filterList && !isFilterDataLoading ? productFilter : [],
    mobileFilterData: filterList && !isFilterDataLoading ? mobileFilterData : [],
    searchArgs: searchLabels,
    resultLabels,
    resultLength: productData?.length || 0,
    totalProductCount: totalCount,
    applyAdditionalFilters,
    currentSearchText: searchTextVal,
    handleSearchText: setSearchText,
    handleCategorySelection,
    handleClearCategorySelection,
    handleAllFilterClear,
    searchFlag,
    searchCTA: filterLabels?.searchCTA,
    filterText: filterLabels?.filterText,
    showMoreText: filterLabels?.showMoreText,
    showLessText: filterLabels?.showLessText,
    additionalFilters,
    updateAdditionalFilters,
    getMobileFilterData,
    textSearchLabel: filterLabels?.textSearchLabel,
    availableProductCountText: filterLabels?.availableProductCountText,
    searchedProductCount: filterLabels?.searchedProductCount,
    unselectAllItemLabel: filterLabels?.unselectAllItemLabel,
    searchCTAMobile: filterLabels?.searchCTAMobile,
    searchPlaceholderMobile: filterLabels?.searchPlaceholderMobile,
    catTextMobile: filterLabels?.catTextMobile,
    unselectItems: filterLabels?.unselectItems,
    productIsLoading: loading,
    productFilterData: loading ? [] : getVisibleTaxonomy(),
  }

  const saveIdHandler = () => {
    const changedView = productView === GRID_VIEW ? LIST_VIEW : GRID_VIEW
    setproductView(changedView)
    return changedView
  }

  const handleOrderValue = (value: string) => {
    updateOrder(value)
  }

  const handlePaginationClick = (currentOffset: number) => {
    setTimeout(executeScroll, 200)
    updateCurrentPage(currentOffset / itemsPerPage)
  }

  const getProductList = () => (
    <>
      {productData?.length ? (
        <div className={`switch-view-button-spaceContainer ${viewType}-view`}>
          {productData.map((item: any, index) => (
           <ProductCardListViewContent key={index} {...item} view={viewType} pimCTADisplay={pimCTADisplay}  />
          ))}
        </div>
      ) : (
        <TitleDescription
          title={resultLabels?.noResultsFoundText}
          description=""
          className="no-result"
        />
      )}
      {productData?.length > 0 && (
        <Row className="pagination-wrapper">
          <Pagination
            itemsCount={totalCount}
            itemsPerPage={itemsPerPage}
            onClick={handlePaginationClick}
            offSet={currentPage}
            cannonicalUrl={`${protocol}//${host}${pathname}?&page=pageNo`}
          />
        </Row>
      )}
    </>
  )

  return (
    <div ref={scollToRef}>
      <Row className="switch-view-button">
        <Column desktop={6} tablet={6} mobile={12}>
          <h3 className="plp-title-section">{plpCatTitle}</h3>
        </Column>
        <Column desktop={6} tablet={6} mobile={12} className="switch-view-button-alignment">
          <ViewAndSortOption
            label={productViewLabels}
            hideSortCTA
            view={productView}
            hideViewTypeCTA={hideViewCTA}
            onSaveIdHandler={saveIdHandler}
            isMobileDisplay={!!isMobile}
            setOrderSortValue={handleOrderValue}
          />
        </Column>
      </Row>
      <Row className="plp-advance-filterdata">
        <Column desktop={3} tablet={12} mobile={12}>
          {filterList && !isFilterDataLoading && (
            <AdvanceSearchFilterWithAccordionEnhanced {...advanceFilter} />
          )}
        </Column>
        {isMobile ? (
          <Column desktop={9} tablet={12} mobile={12} className="plp-order-mobile">
            <ViewAndSortOption
              label={productViewLabels}
              hideSortCTA={true}
              view="List"
              hideViewTypeCTA={true}
              isMobileDisplay={false}
              isOrderByVisible={true}
              setOrderSortValue={handleOrderValue}
            />
          </Column>
        ) : (
          <></>
        )}
        <Column desktop={9} tablet={12} mobile={12}>
          {!loading ? getProductList() : <Loader display={loading} />}
        </Column>
      </Row>
    </div>
  )
}
const mapDispatch = {
  updatePaywallContent: (isPaywall: boolean) => updatePaywallContent(isPaywall),
}
const mapState = (state: any) => ({
  paywalledContent: state.common.paywallContent,
})
export default connect(mapState, mapDispatch)(ProductListV2)
